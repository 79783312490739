import { apiRoutes, makeApiRequest } from "constants/apiRoutes";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useCompany } from "util";
import { useAuth } from "util";

export const useCreateRole = (name, companyId, id) => {
  const auth = useAuth();
  const { companyData } = useCompany();
  const { control, handleSubmit, reset, getValues } = useForm({
    defaultValues: {
      title: "",
      city: "",
      state: "",
      country: "United States of America",
      department: "",
      ctaUrl: "",
      ctaType: "BookIntro",
      isRemote: false,
      isHybrid: false,
      compType: "salary",
      compLow: "",
      compHigh: "",
    },
  });

  const values = useMemo(() => getValues(), [getValues]);

  useEffect(() => {
    reset(values);
  }, [values, reset]);

  const onSubmit = async (data) => {
    try {
      data.companyId = companyId;
      data.links = [];
      data.jobFeed = [];
      data.isPublished = false;
      data.postingOwner = name;
      data.userId = id;
      data.isTemplate = false;

      const response = await makeApiRequest(
        "post",
        apiRoutes.createPageByUserID(id),
        auth.user,
        companyData.companyId,
        data
      );
      if (response.status === 201) {
        window.location.href = `${window.location.origin}/pages/edit/${response.data._id}`;
      }
    } catch (error) {
      console.error(error);
      window.alert("There was an error creating this role");
    }
  };
  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    resetValues: () => reset(values),
  };
};

export const useCreateTemplateRole = (name, companyId, id) => {
  const { control, handleSubmit, reset, getValues } = useForm({
    defaultValues: {
      title: "*title*",
      city: "*city*",
      state: "*state*",
      country: "*country*",
      department: "*department*",
      ctaUrl: "https://ctaUrl.com",
      ctaType: "BookIntro",
      isRemote: false,
      isHybrid: false,
      compType: "salary",
      compLow: "0",
      compHigh: "",
    },
  });

  const auth = useAuth();
  const { companyData } = useCompany();

  const values = useMemo(() => getValues(), [getValues]);

  useEffect(() => {
    reset(values);
  }, [values, reset]);

  const onSubmit = async (data) => {
    try {
      data.companyId = companyId;
      data.links = [];
      data.jobFeed = [];
      data.isPublished = false;
      data.postingOwner = name;
      data.userId = id;
      data.isTemplate = true;

      const response = await makeApiRequest(
        "post",
        apiRoutes.createPageByUserID(id),
        auth.user,
        companyData.companyId,
        data
      );
      if (response.status === 201) {
        window.location.href = `${window.location.origin}/pages/edit/${response.data._id}`;
      }
    } catch (error) {
      console.error(error);
      window.alert("There was an error creating this template");
    }
  };
  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    resetValues: () => reset(values),
  };
};

export const useCreateTemplateFromPage = () => {
  const auth = useAuth();
  const { companyData } = useCompany();

  const createTemplateFromPage = async (data) => {
    try {
      delete data._id;

      data.postingOwner = auth.user.name;
      data.userId = auth.user.id;
      data.isPublished = false;
      data.isTemplate = true;

      const response = await makeApiRequest(
        "post",
        apiRoutes.createPageByUserID(data.userId),
        auth.user,
        companyData.companyId,
        data
      );
      if (response.status === 201) {
        window.location.href = `${window.location.origin}/pages/edit/${response.data._id}`;
      }
    } catch (error) {
      console.error(error);
      window.alert("There was an error creating this template");
    }
  };
  return {
    createTemplateFromPage,
  };
};
