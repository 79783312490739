import React from "react";

export const IconButton = ({ onClick, children }) => {
  return (
    <button
      className="stroke-slate-500 hover:stroke-[#3371ff] text-xs cursor-pointer rounded-xl 
      h-[42px] px-[9px] font-semibold text-[#3371ff] box-border flex items-center justify-center hover:bg-[#3371ff10]"
      onClick={onClick}
    >
      {children}
    </button>
  );
};
