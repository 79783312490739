import React, { useState } from "react";
import Modal from "components/Modal";
import { apiRoutes, makeApiRequest } from "../../../../constants";
import { useAuth } from "util";
import PrimaryActionButton from "components/Buttons/PrimaryActionButton";
import SecondaryActionButton from "components/Buttons/SecondaryActionButton";
import { isValidUrl } from "util";
import { useCompany } from "util";

function LinkInput({
  companyId,
  roleId,
  closeModalNoRefresh,
  closeModalWithRefresh,
  setLocalNewsroomLinks,
}) {
  const [newLink, setNewLink] = useState({
    title: "",
    source: "",
    url: "",
  });
  const [showInvalidUrlMessage, setShowInvalidUrlMessage] = useState(false);
  const auth = useAuth();
  const { companyData } = useCompany();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewLink((prevLink) => ({
      ...prevLink,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isValidUrl(newLink.url)) {
      setShowInvalidUrlMessage(true);
      return;
    }
    try {
      const response = await makeApiRequest(
        "post",
        apiRoutes.createPageLink(companyId, roleId),
        auth.user,
        companyData.companyId,
        newLink
      );

      if (response.status === 201) {
        // Fetch and update local newsroom links
        const updatedLinksResponse = await makeApiRequest(
          "get",
          apiRoutes.getPageLinks(companyId, roleId),
          auth.user,
          companyData.companyId
        );
        setLocalNewsroomLinks(updatedLinksResponse.data);
        closeModalWithRefresh();
      } else {
        console.error("Failed to add newsroom link");
      }
    } catch (error) {
      console.error("Error adding newsroom link", error);
    }
  };

  return (
    <Modal title="Add Link" closeModal={closeModalNoRefresh} size="sm">
      <form
        onSubmit={handleSubmit}
        className="flex flex-col mt-[20px] gap-[20px]"
      >
        {Object.keys(newLink).map((fieldName) => (
          <div>
            <label
              className="text-[13px] font-medium tracking-tight text-slate-700"
              key={fieldName}
            >
              {fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}
            </label>
            <input
              className="text-sm w-full mt-1 py-2 px-3 bg-slate-100 rounded-md"
              type="text"
              name={fieldName}
              value={newLink[fieldName]}
              onChange={handleChange}
              required
            />
            {fieldName === "url" && showInvalidUrlMessage ? (
              <p className="text-xs pt-1 pl-1 text-red-500">
                Invalid url: use http/https protocol
              </p>
            ) : null}
          </div>
        ))}
        <div className="flex mt-[15px] ml-auto gap-[10px]">
          <SecondaryActionButton text="Cancel" action={closeModalNoRefresh} />
          <PrimaryActionButton text="Add Link" buttonType="submit" />
        </div>
      </form>
    </Modal>
  );
}

export default LinkInput;
