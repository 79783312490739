// Modules
import React from "react";
import Alert from "components/Alert";

function CompanyComponentEditWarning({ sharedId }) {
  return (
    <div>
      {sharedId && (
        <Alert
          text="Making changes will break the sync with company information"
          type="info"
        />
      )}
    </div>
  );
}

export default CompanyComponentEditWarning;
