import React from "react";

const colorMappings = {
  red: { bgColor: "#ff8133", textColor: "#ff5733" },
  blue: { bgColor: "#3370ff21", textColor: "#3371ff" },
  green: { bgColor: "#b7efc5", textColor: "#1a7431" },
  purple: { bgColor: "#dc97ff", textColor: "#4a0a77" },
  orange: { bgColor: "#ffe4bd", textColor: "#fa9600" },
  gray: { bgColor: "#e1e1e1", textColor: "#515a5a" },
  // Add more color mappings as needed
};

function Tag({ text, color }) {
  const { bgColor, textColor } = colorMappings[color] || colorMappings.blue;

  const tagStyle = {
    backgroundColor: bgColor,
    color: textColor,
  };

  return (
    <div
      className="px-4 py-2 text-xs font-medium tracking-normal rounded-full text-center"
      style={tagStyle}
    >
      {text}
    </div>
  );
}

export default Tag;
