import React from "react";
import { Link } from "react-router-dom";

function AuthFooter(props) {
  return (
    <div className="px-3 mt-6 text-sm">
      {props.type === "signup" && (
        <>
          {props.signinText}
          <Link to={props.signinPath} className="ml-3 text-[#3371FF]">
            {props.signinAction}
          </Link>
        </>
      )}

      {props.type === "signin" && (
        <>
          <Link to={props.signupPath} className="text-[#3371FF]">
            {props.signupAction}
          </Link>

          {props.forgotPassAction && (
            <Link to={props.forgotPassPath} className="ml-4 text-[#3371FF]">
              {props.forgotPassAction}
            </Link>
          )}
        </>
      )}

      {props.type === "forgotpass" && (
        <>
          {props.signinText}
          <Link to={props.signinPath} className="ml-3 text-[#3371FF]">
            {props.signinAction}
          </Link>
        </>
      )}
    </div>
  );
}

export default AuthFooter;
