import React from "react";
import { ReactComponent as BriefcaseIcon } from "assets/icons/briefcase.svg";
import { ReactComponent as BadgeDollarIcon } from "assets/icons/badge-dollar-solid.svg";
import { ReactComponent as LocationIcon } from "assets/icons/location-pin.svg";
import CTAButton from "pages/LandingPage/Components/CTAButton";
import Tag from "components/Tag";
import Compensation from "../Utilities/Formatting/Compensation";
import Location from "../Utilities/Formatting/Location";
import useWindowDimensions from "hooks/useWindowDimensions";

export default function RoleHeader({ roleDetails, companyData }) {
  const isMobileScreen = useWindowDimensions();

  return (
    <div className="sticky top-0 py-3 px-6 z-50 bg-[#f3f7ff] dark:bg-slate-800 text-slate-900 dark:text-slate-200 block-shadow">
      <div className="flex items-center justify-between">
        <div className="min-w-0 flex-1">
          <div className="flex gap-[15px] items-center mb-3">
            <h2 className=" font-bold sm:text-4xl text-2xl tracking-tight">
              {roleDetails?.title}
            </h2>
            {companyData?.isAgency === 0 ? null : (
              <Tag text="Agency Represented" color="blue" />
            )}
          </div>
          <div className="mt-1 flex-wrap flex gap-4  text-slate-500 dark:text-slate-300">
            <div className="flex items-center text-sm text-slate-900 dark:text-slate-300">
              <LocationIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 fill-[#3370ff80] dark:fill-[#3370ff]"
                aria-hidden="true"
              />
              <Location
                city={roleDetails.city}
                state={roleDetails.state}
                country={roleDetails.country}
                remote={roleDetails.isRemote}
                hybrid={roleDetails.isHybrid}
              />
            </div>

            <div className="flex items-center text-sm text-slate-900 dark:text-slate-300">
              <BriefcaseIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 fill-[#3370ff80] dark:fill-[#3370ff]"
                aria-hidden="true"
              />
              <p>{roleDetails?.department}</p>
            </div>

            {roleDetails?.compType === "hidden" ? null : (
              <div className="flex items-center text-sm text-slate-900 dark:text-slate-300">
                <BadgeDollarIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 fill-[#3370ff80] dark:fill-[#3370ff]"
                  aria-hidden="true"
                />
                <Compensation
                  compType={roleDetails?.compType}
                  compLow={roleDetails?.compLow}
                  compHigh={roleDetails?.compHigh}
                />
              </div>
            )}
          </div>
        </div>

        {/* <OptOut /> */}
        <CTAButton
          url={roleDetails?.ctaUrl}
          ctaType={roleDetails?.ctaType}
          isMobileScreen={isMobileScreen}
        />
      </div>
    </div>
  );
}
