import React from "react";
import Tooltip from "components/Tooltip";
import { ReactComponent as PendingCheck } from "assets/icons/badge-check-line.svg";
import { ReactComponent as PublishedCheck } from "assets/icons/badge-check.svg";

function InfoCardTitle({ companyData, userData, infoCardDisplay }) {
  return (
    <div className="mb-1">
      <div className="flex items-center gap-2">
        <h2 className="font-semibold tracking-tight text-slate-900 dark:text-slate-200 text-lg">
          {infoCardDisplay === 0 ? companyData?.companyName : userData.name}
        </h2>
        {userData.isVerified === "verified" ? (
          <Tooltip text="Verified by Hiring Spree">
            <PublishedCheck className="fill-[#3371ff]" />
          </Tooltip>
        ) : (
          <Tooltip text="Unverified User">
            <PendingCheck className="stroke-slate-500" />
          </Tooltip>
        )}
      </div>
      <h3 className="text-sm font-medium text-slate-900 dark:text-slate-300">
        {infoCardDisplay === 0
          ? null
          : `${userData?.userTitle} at ${companyData?.companyName}`}
      </h3>
    </div>
  );
}

export default InfoCardTitle;
