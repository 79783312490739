import React from "react";
import { ReactComponent as XMark } from "assets/icons/xmark.svg";
import { ReactComponent as LeftArrow } from "assets/icons/arrow-left.svg";

const InputHeader = ({
  title,
  isEditing,
  existingContent,
  setSelectedComponent,
  close,
  unsavedChanges,
}) => {
  return (
    <div className="flex items-center gap-[10px]">
      <h2 className="font-semibold text-xl text-slate-700 tracking-tight">
        {isEditing
          ? `Edit ${title} Block: ${existingContent.title}`
          : `Create a New ${title} Block`}
      </h2>
      <div className="flex items-center ml-auto gap-[10px]">
        {isEditing ? null : (
          <button
            type="button"
            className="fill-[#3371ff]"
            onClick={() => setSelectedComponent(null)}
          >
            <LeftArrow className="w-[18px]" />
          </button>
        )}

        <button
          className="ml-auto flex items-center bg-transparent border-none cursor-pointer stroke-slate-700 hover:stroke-[#ff0000]"
          onClick={() => {
            if (unsavedChanges) {
              const confirmed = window.confirm(
                "You have unsaved changes. If you exit without saving, your changes will be lost."
              );
              if (!confirmed) {
                return;
              }
            }
            close();
          }}
        >
          <XMark className="w-[18px]" />
        </button>
      </div>
    </div>
  );
};

export default InputHeader;
