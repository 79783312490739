import React, { useState, useEffect } from "react";
import { NavLink, Link } from "../../util/router";
import { useAuth } from "../../util";
import logo from "../../assets/img/hs_logo_black.png";
import useWindowDimensions from "../../hooks/useWindowDimensions"; // Update path

function Navbar({ isLoggedIn }) {
  const auth = useAuth();
  const isMobileScreen = useWindowDimensions();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 250) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const sectionLinks = [{ to: "/", label: "Product" }];
  const publicLinks = [
    { to: "/contact", label: "Get in Touch" },
    { to: "/about", label: "Our Story" },
  ];

  return (
    <>
      <header
        className={`flex items-center sticky top-0 z-10 bg-[#fafbff] ${
          isMobileScreen ? "py-[20px] px-[20px]" : "py-[10px] px-[75px]"
        } ${isScrolled ? "border-solid border-b border-[#e0e7ff]" : ""}`} // Apply border bottom class conditionally
      >
        <NavLink to="/">
          <div className="flex gap-3 items-center font-semibold text-slate-900 text-[18px]">
            <img
              src={logo}
              className="w-[50px] h-[50px] bg-white block-shadow rounded-full"
            />
            <p>Hiring Spree</p>
          </div>
        </NavLink>
        {isMobileScreen ? null : (
          <>
            <nav className="ml-[30px] flex flex-wrap items-center gap-2">
              {sectionLinks.map((link, index) => (
                <Link
                  to={link.to}
                  key={index}
                  className="text-[14px] px-3 py-1.5 font-medium text-slate-900 hover:text-[#3371ff] hover:bg-[#e8edff] rounded-xl"
                >
                  {link.label}
                </Link>
              ))}
              {publicLinks.map((link, index) => (
                <Link
                  to={link.to}
                  key={index}
                  className="text-[14px] px-3 py-1.5 font-medium text-slate-900 hover:text-[#3371ff] hover:bg-[#e8edff] rounded-xl"
                >
                  {link.label}
                </Link>
              ))}
            </nav>
          </>
        )}
        <div className="ml-auto">
          {isMobileScreen ? (
            <>
              <Link
                to="/contact"
                className={`border-solid border ml-2 rounded-xl px-6 py-2.5 text-[14px] font-medium focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#3371ff] ${
                  isScrolled
                    ? "bg-gradient-to-b from-blue-400 via-blue-500 to-indigo-500 text-white"
                    : "border-[#e0e7ff] bg-[#f0f3ff] hover:bg-[#e8edff] text-[#3371ff]"
                }`}
              >
                Get in touch
              </Link>
            </>
          ) : (
            <>
              {!isLoggedIn && auth.user ? (
                <>
                  <Link
                    to="/pages"
                    className="text-[14px] px-4 py-2.5 font-medium text-[#3371ff] hover:bg-[#e8edff] rounded-xl"
                  >
                    My account
                  </Link>
                </>
              ) : null}
              {auth.user ? (
                <>
                  <Link
                    to="/auth/signout"
                    className="rounded-xl bg-slate-800 ml-2 rounded-xl px-6 py-2.5 text-[14px] font-medium text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                    onClick={(e) => {
                      e.preventDefault();
                      auth.signout();
                    }}
                  >
                    Log out
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to="/auth/signin"
                    className="text-[14px] px-4 py-2.5 font-medium text-[#3371ff] hover:bg-[#e8edff] rounded-xl"
                  >
                    Log in
                  </Link>
                  <Link
                    to="/auth/signup"
                    className={`border-solid border ml-2 rounded-xl px-6 py-2.5 text-[14px] font-medium focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#3371ff] ${
                      isScrolled
                        ? "bg-gradient-to-b from-blue-400 via-blue-500 to-indigo-500 text-white"
                        : "border-[#e0e7ff] bg-[#f0f3ff] hover:bg-[#e8edff] text-[#3371ff]"
                    }`}
                  >
                    Sign Up
                  </Link>
                </>
              )}
            </>
          )}
        </div>
      </header>
    </>
  );
}

export default Navbar;
