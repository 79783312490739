export const LINK_OPTIONS = [
  { value: "twitter", label: "Twitter" },
  { value: "linkedin", label: "LinkedIn" },
  { value: "dribbble", label: "Dribbble" },
  { value: "crunchbase", label: "Crunchbase" },
  { value: "github", label: "GitHub" },
  { value: "youtube", label: "Youtube" },
  { value: "instagram", label: "Instagram" },
  { value: "facebook", label: "Facebook" },
  { value: "discord", label: "Discord" },
  { value: "glassdoor", label: "Glassdoor" },
  { value: "tiktok", label: "TikTok" },
  { value: "whatsapp", label: "WhatsApp" },
  { value: "snapchat", label: "SnapChat" },
  { value: "medium", label: "Medium" },
  { value: "pinterest", label: "Pinterest" },
  { value: "telegram", label: "Telegram" },
  { value: "reddit", label: "Reddit" },
  { value: "vimeo", label: "Vimeo" },
  { value: "other", label: "Other" },
];

export const permissionsOptions = [
  { value: "Employee", label: "Employee" },
  { value: "Company Admin", label: "Company Admin" },
];

export const infoCardDisplayOptions = [
  { value: 0, label: "Company Logo" },
  { value: 1, label: "Company Logo + Profile Picture" },
];

export const companySize = [
  "1-10",
  "11-50",
  "51-100",
  "101-250",
  "251-500",
  "501-1,000",
  "1,001-5,000",
  "5,001-10,000",
  "10,001+",
];

export const fundingStatus = [
  "Public",
  "Private",
  "Non-profit",
  "Acquired",
  "Delisted",
];

export const fundingType = [
  "Pre-Seed",
  "Seed",
  "Series A",
  "Series B",
  "Series C",
  "Series D",
  "Series E",
  "Series F",
  "Series G",
  "Series H",
  "Series I",
  "Series J",
  "Venture - Series Unknown",
  "Angel",
  "Private Equity",
  "Debt Financing",
  "Convertible Note",
  "Grant",
  "Corporate Round",
  "Equity Crowdfunding",
  "Product Crowdfunding",
  "Secondary Market",
  "Post-IPO Equity",
  "Post-IPO Debt",
  "Post-IPO Secondary",
  "Non-equity Assistance",
  "Initial Coin Offering",
];

export const industries = [
  "LinkedIn Top Startups 2022",
  "Forbes Cloud 100 2022",
  "Collaboration",
  "Productivity",
  "Financial Services",
  "Telehealth",
  "Travel",
  "Hospitality",
  "Human Resources",
  "Food and Beverage",
  "Cannabis",
  "Apparel and Fashion",
];
