import React, { useEffect, useState } from "react";
import { requireAuth } from "util";
import { makeApiRequest, apiRoutes } from "constants";
import { useAuth } from "util";
import Meta from "../../../components/Meta";
import logo from "../../../assets/img/hs_logo_black.png";

function SlackOAuth() {
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState("");
  const auth = useAuth();

  useEffect(() => {
    const checkStatus = async () => {
      const params = new URLSearchParams(window.location.search);
      const code = params.get("code");
      const error = params.get("error");
      const errorDescription = params.get("error_description");

      if (error && errorDescription) {
        setStatus("error");
        setMessage("Authorization failed (Slack): " + errorDescription);
        return;
      }

      if (error) {
        setStatus("error");
        setMessage("Authorization failed.");
        return;
      }

      if (code && auth.user) {
        try {
          const response = await makeApiRequest(
            "get",
            apiRoutes.slackAuth(code),
            auth.user
          );
          const data = await response.data;

          if (data.success) {
            setStatus("success");
            setMessage("Authorization successful!");
          } else {
            setStatus("error");
            setMessage("Authorization failed (Server Error): " + data.message);
          }
        } catch (err) {
          setStatus("error");
          setMessage("Authorization failed (Client Error): " + err.message);
        }
      }
    };

    checkStatus();
  }, []);

  return (
    <section className="flex items-center h-screen bg-gradient-to-r from-blue-400 via-blue-500 to-indigo-500 px-[20px]">
      <div className="container mx-auto max-w-lg text-center bg-white p-[50px] rounded-[30px] block-shadow">
        <img
          src={logo}
          alt="logo"
          className="h-[70px] mx-auto mb-4 block-shadow rounded-full"
        />

        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Slack Authorization
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          {status === "success" && <>{message}</>}
          {status === "error" && <>{message}</>}
          {status === "no code" && <>{message}</>}
          {!status && <>Processing authorization...</>}
        </p>
      </div>
    </section>
  );
}

export default requireAuth(SlackOAuth);
