import React, { useEffect } from "react";
import { useAuth } from "util";
import CompanyMatch from "./CompanyMatch";
import UserInformation from "./UserInformation";
import { requireAuth } from "util";
import { useRouter } from "util";

function Onboarding() {
  const auth = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (auth.user.showOnboardingFlow === false) {
      router.navigate("/pages");
    }
  }, [auth.user]);

  return (
    <div className="flex w-screen animated-background h-screen bg-gradient-to-r from-blue-400 via-blue-500 to-indigo-500">
      <div className="flex items-center m-auto">
        {auth.user.permissions ? <UserInformation /> : <CompanyMatch />}
      </div>
    </div>
  );
}

export default requireAuth(Onboarding);
