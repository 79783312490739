import React from "react";
import Meta from "../../components/Meta";
import useWindowDimensions from "hooks/useWindowDimensions";
import HeroSection from "./Sections/HeroSection";
import DemoSection from "./Sections/DemoSection";
import PricingSection from "./Sections/PricingSection";
import ProductSection from "./Sections/ProductSection";

function IndexPage(props) {
  const isMobileScreen = useWindowDimensions();

  return (
    <>
      <Meta title="Your recruitment marketing co-pilot | Hiring Spree" />
      <div className="flex flex-col mx-auto px-0 md:px-[75px]">
        <HeroSection isMobileScreen={isMobileScreen} />
        <div id="features">
          <DemoSection />
        </div>
        <div id="product">
          <ProductSection />
        </div>
        <div id="pricing">
          <PricingSection isMobileScreen={isMobileScreen} />
        </div>
      </div>
    </>
  );
}

export default IndexPage;
