import React, { useState } from "react";
import { ReactComponent as Clipboard } from "assets/icons/clipboard.svg";
import { ReactComponent as Check } from "assets/icons/check.svg";

function CopyToClipboardIcon({ textToCopy }) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setIsCopied(true);

      // Reset the "Copied" state after a short delay (e.g., 2 seconds)
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    } catch (error) {
      console.error("Failed to copy to clipboard:", error);
    }
  };

  return (
    <button
      className="cursor-pointer flex align-middle"
      onClick={handleCopyClick}
    >
      {isCopied ? (
        <Check className="stroke-[#3371ff]" />
      ) : (
        <Clipboard className="stroke-slate-500 hover:stroke-[#3371ff]" />
      )}
    </button>
  );
}

export default CopyToClipboardIcon;
