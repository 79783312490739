import React from "react";
import Select from "react-select";

const StyledSelect = ({
  label,
  options,
  value,
  onChange,
  placeholder = "Select",
  menuPlacement = "auto",
  styles = {},
}) => {
  return (
    <div>
      <label className="text-[13px] font-medium tracking-tight text-slate-700">
        {label}
      </label>
      <Select
        label={placeholder}
        options={options}
        value={value}
        onChange={onChange}
        menuPlacement={menuPlacement}
        styles={{
          control: (base, state) => ({
            ...base,
            border: "none",
            backgroundColor: "#f1f5f9",
            marginTop: "4px",
            minHeight: "36px",
            height: "36px",
            fontSize: "14px",
          }),
          option: (base, state) => ({
            ...base,
            fontSize: "14px",
            padding: "4px 8px",
          }),
          ...styles,
        }}
        components={{
          IndicatorSeparator: () => null,
        }}
        isSearchable={false}
      />
    </div>
  );
};

export default StyledSelect;
