import React, { useState } from "react";
import { useAuth, requireAuth } from "util";
import { v4 as uuidv4 } from "uuid";
import SharedComponent from "./SharedComponent";
import AddComponentModal from "pages/Account/Home/components/AddComponentModal";
import TextBlockInput from "pages/Account/Home/BlockInputs/TextBlockInput";
import ImageBlockInput from "pages/Account/Home/BlockInputs/ImageBlockInputs";
import VideoBlockInput from "pages/Account/Home/BlockInputs/VideoBlockInputs";
import EmbedBlockInput from "pages/Account/Home/BlockInputs/EmbedBlockInputs";
import PersonnelBlockInput from "pages/Account/Home/BlockInputs/PersonnelBlockInputs";
import { apiRoutes, makeApiRequest } from "constants/apiRoutes";
import { useCompany } from "util";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { ReactComponent as FileUser } from "assets/icons/file-user.svg";
import MultiSelect from "components/MultiSelect";

function SharedComponents() {
  const auth = useAuth();
  const { companyData, fetchCompanyData } = useCompany();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedComponentType, setSelectedComponentType] = useState(null);
  const [editedComponent, setEditedComponent] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // State for the search query
  const [selectedBlockTypes, setSelectedBlockTypes] = useState([]); // State for selected block types

  const blockTypeOptions = [
    { value: "text", label: "Text" },
    { value: "image", label: "Image" },
    { value: "video", label: "Video" },
    { value: "embed", label: "Embed" },
    { value: "personnel", label: "Personnel" },
  ];

  const companyComponents = companyData.sharedComponents?.filter(
    (component) =>
      !component.isArchived &&
      component.title.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (selectedBlockTypes.length === 0 ||
        selectedBlockTypes.some((type) => type.value === component.blockType))
  );

  const updateCompanyData = async (data) => {
    const response = await makeApiRequest(
      "put",
      apiRoutes.companyDataById(companyData.companyId),
      auth.user,
      companyData.companyId,
      data
    );

    // If the operation is successful, refetch the company data to update the UI
    if (response.status < 300) {
      fetchCompanyData();
    }

    // The response will propogate up to the individual create/edit/delete modals, which handle error catching
    return response;
  };

  const handleCreateSharedComponent = async (blockData) => {
    const blockDataWithId = { ...blockData, id: uuidv4() };
    if (companyComponents) {
      companyData.sharedComponents.push(blockDataWithId);
    } else {
      companyData.sharedComponents = [blockDataWithId];
    }

    return await updateCompanyData(companyData);
  };

  const handleEditSharedComponent = async (blockData) => {
    const blockIndex = companyData.sharedComponents.findIndex(
      (component) => component.id === blockData.id
    );
    companyData.sharedComponents[blockIndex] = blockData;

    return await updateCompanyData(companyData);
  };

  // Deleting a shared component doesn't actually "delete" it, but rather archives it to
  // preserve content on existing pages that use it.
  const handleDeleteSharedComponent = async (id) => {
    const blockIndex = companyData.sharedComponents.findIndex(
      (component) => component.id === id
    );
    companyData.sharedComponents[blockIndex] = {
      ...companyData.sharedComponents[blockIndex],
      isArchived: true,
    };

    return await updateCompanyData(companyData);
  };

  const closeModal = () => {
    setEditedComponent(null);
    setSelectedComponentType(null);
    setIsModalOpen(false);
  };

  return (
    <div className="px-[75px] pt-[40px] min-h-[100vh]">
      <h1 className="font-bold tracking-tight text-slate-900 text-[32px]">
        Shared Components
      </h1>
      <p className="text-sm mt-2">
        Edit once, update everywhere. Effortlessly maintain a consistent look
        across your organizations pages by creating reusable elements.
      </p>
      <div className="mt-[60px]">
        <div className="flex justify-between ">
          <div className="flex flex-row gap-2 whitespace-nowrap text-sm tracking-tight font-medium">
            <MultiSelect
              options={blockTypeOptions}
              selectedOptions={selectedBlockTypes}
              onChange={setSelectedBlockTypes}
              placeholder="Filter by Component Type"
            />
            <input
              type="text"
              placeholder="Search by title..."
              className="text-sm font-medium px-4 block-shadow rounded-lg placeholder:text-slate-700 tracking-tight border border-solid border-slate-900"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          <div className="flex gap-[10px]">
            {auth.user.userRole !== "Employee" && (
              <button
                className="bg-white text-[#3371ff] border border-solid border-[#3371ff] block-shadow font-medium text-[15px] pl-3 pr-5 py-2.5 rounded-lg flex flex-row gap-2 items-center"
                type="button"
                onClick={() => setIsModalOpen(true)}
              >
                <Plus className="stroke-[#3371ff]" />
                <p>Create Component</p>
              </button>
            )}
          </div>
        </div>
      </div>

      {!companyComponents || companyComponents?.length === 0 ? (
        searchQuery === "" && selectedBlockTypes.length === 0 ? (
          <div className="flex flex-col m-auto w-[550px] mt-[50px] block-shadow bg-white rounded-md p-[30px] text-center">
            <p className="text-sm">
              Shared components can be easily reused in any page your team
              members create. Whenever you update a company component here, the
              those edits will automatically sync on each page where it's used.
            </p>
            <p className="text-sm mt-4">
              Click the{" "}
              <span
                className="font-semibold text-[#3371ff] cursor-pointer"
                onClick={() => setIsModalOpen(true)}
              >
                Create Component
              </span>{" "}
              button above to create your first component!
            </p>
          </div>
        ) : (
          <div className="mt-[30px]">
            <p className="pl-4 text-sm text-slate-900">
              No results match your text search...
            </p>
          </div>
        )
      ) : (
        <div className="mt-[30px] grid grid-cols-2 gap-[15px]">
          {companyComponents.map((component) => (
            <SharedComponent
              component={component}
              onEditComponent={() => {
                setEditedComponent(component);
                setSelectedComponentType(
                  [
                    "linkedin-post",
                    "twitter-post",
                    "twitter-timeline",
                  ].includes(component.blockType)
                    ? "embed"
                    : component.blockType
                );
                setIsModalOpen(true);
              }}
              onDeleteComponent={(id) => handleDeleteSharedComponent(id)}
            />
          ))}
        </div>
      )}

      {isModalOpen ? (
        selectedComponentType ? (
          <>
            {/* Render the appropriate component form based on selectedComponent */}
            {selectedComponentType === "text" && (
              <TextBlockInput
                close={closeModal}
                setSelectedComponent={setSelectedComponentType}
                isEditing={editedComponent !== null}
                existingContent={editedComponent ?? undefined}
                refetch={() => {}}
                handleCreateSharedComponent={handleCreateSharedComponent}
                handleEditSharedComponent={handleEditSharedComponent}
                isTeamsPage
              />
            )}
            {selectedComponentType === "image" && (
              <ImageBlockInput
                close={closeModal}
                setSelectedComponent={setSelectedComponentType}
                isEditing={editedComponent !== null}
                existingContent={editedComponent ?? undefined}
                refetch={() => {}}
                handleCreateSharedComponent={handleCreateSharedComponent}
                handleEditSharedComponent={handleEditSharedComponent}
                isTeamsPage
              />
            )}
            {selectedComponentType === "video" && (
              <VideoBlockInput
                close={closeModal}
                closeNoRefresh={closeModal}
                setSelectedComponent={setSelectedComponentType}
                isEditing={editedComponent !== null}
                existingContent={editedComponent ?? undefined}
                refetch={() => {}}
                handleCreateSharedComponent={handleCreateSharedComponent}
                handleEditSharedComponent={handleEditSharedComponent}
                isTeamsPage
              />
            )}
            {selectedComponentType === "embed" && (
              <EmbedBlockInput
                close={closeModal}
                setSelectedComponent={setSelectedComponentType}
                isEditing={editedComponent !== null}
                existingContent={editedComponent ?? undefined}
                refetch={() => {}}
                handleCreateSharedComponent={handleCreateSharedComponent}
                handleEditSharedComponent={handleEditSharedComponent}
                isTeamsPage
              />
            )}
            {selectedComponentType === "personnel" && (
              <PersonnelBlockInput
                close={closeModal}
                closeNoRefresh={closeModal}
                setSelectedComponent={setSelectedComponentType}
                isEditing={editedComponent !== null}
                existingContent={editedComponent ?? undefined}
                refetch={() => {}}
                handleCreateSharedComponent={handleCreateSharedComponent}
                handleEditSharedComponent={handleEditSharedComponent}
                isTeamsPage
              />
            )}
            {/* Add other component forms as needed */}
          </>
        ) : (
          <AddComponentModal
            close={closeModal}
            renderComponentForm={(componentType) =>
              setSelectedComponentType(componentType)
            }
            closeWithRefresh={closeModal}
            isTeamsPage
          />
        )
      ) : null}
    </div>
  );
}

export default requireAuth(SharedComponents);
