import React from "react";
import { NavLink } from "react-router-dom";
import Meta from "./../components/Meta";
import logo from "../assets/img/hs_logo_black.png";

function NotFoundPage(props) {
  return (
    <>
      <Meta title="Page not found" />
      <section className="flex items-center h-screen bg-gradient-to-r from-blue-400 via-blue-500 to-indigo-500 px-[20px]">
        <div className="container mx-auto max-w-lg text-center bg-white p-[50px] rounded-[30px] block-shadow">
          <img
            src={logo}
            alt="logo"
            className="h-[70px] mx-auto mb-4 block-shadow rounded-full"
          />

          <h1 className="mt-4 text-3xl font-bold tracking-tight text-slate-900 sm:text-5xl">
            Page not found
          </h1>
          <p className="mt-6 text-base leading-7 text-slate-600">
            Sorry, we couldn’t find the page you’re looking for.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <NavLink
              to="/"
              className="bg-[#3371ff] text-white border-none rounded-[10px] font-medium text-sm px-6 py-3"
            >
              Go back home
            </NavLink>
          </div>
        </div>
      </section>
    </>
  );
}

export default NotFoundPage;
