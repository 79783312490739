import { useState, useEffect } from "react";

const useWindowDimensions = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth < 480);
    };

    // Set initial screen size
    handleResize();

    // Attach event listener for resizing
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobileScreen;
};

export default useWindowDimensions;
