// TwitterContainer.js
import React, { useEffect } from "react";

function TwitterFeed({ ...item }) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    document.getElementsByClassName("twitter-embed")[0].appendChild(script);
  }, []);

  function extractHrefFromTwitterString(inputString) {
    const hrefRegex = /href="([^"]+)"/;
    const match = inputString?.match(hrefRegex);
    if (match && match[1]) {
      return match[1];
    } else {
      return null; // Return null if no href is found
    }
  }

  function extractTwitterHandleFromTwitterString(inputString) {
    const handleRegex = /href="https:\/\/twitter\.com\/([^?]+)/;
    const match = inputString?.match(handleRegex);
    if (match && match[1]) {
      return match[1];
    } else {
      return null; // Return null if no handle is found
    }
  }

  const twitterTimelineHref = extractHrefFromTwitterString(item.content);
  const twitterHandle = extractTwitterHandleFromTwitterString(item.content);

  return (
    <div className="twitterBox">
      <section className="twitterContainer">
        <div className="twitter-embed">
          <a
            className="twitter-timeline"
            data-theme="light"
            data-tweet-limit="5"
            data-chrome="noheader nofooter noborders"
            href={twitterTimelineHref}
          >
            Tweets by {twitterHandle}
          </a>
        </div>
      </section>
    </div>
  );
}

export default TwitterFeed;
