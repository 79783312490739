import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { requireAuth } from "util/auth";
import DragAndDrop from "./DND/DragAndDrop";
import TextBlockInput from "./BlockInputs/TextBlockInput";
import ImageBlockInput from "./BlockInputs/ImageBlockInputs";
import VideoBlockInput from "./BlockInputs/VideoBlockInputs";
import PersonnelBlockInput from "./BlockInputs/PersonnelBlockInputs";
import EmbedBlockInput from "./BlockInputs/EmbedBlockInputs";
import HeroBlockInput from "./BlockInputs/HeroBlockInputs";
import LinkInput from "./components/LinkInput";
import AddComponentModal from "./components/AddComponentModal";
import EditorHeader from "./components/EditorHeader";
import LinkContainer from "./components/LinkContainer";
import JobFeedEmpty from "./components/JobFeedEmpty";
import useGetPageEditorInfo from "hooks/useGetPageEditorInfo";
import { CreateEditRoleModal } from "./components/CreateEditRoleModal";
import { useEditRole } from "hooks/useEditRole";
import Meta from "components/Meta";
import { ReactComponent as GridPlusLine } from "assets/icons/grid-plus-line.svg";
import { useCompany } from "util";
import PageLoader from "components/PageLoader";

function LandingPageEditor() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const { companyData } = useCompany();
  const { roleId } = useParams();
  const {
    featuredRoleData,
    localNewsroomLinks,
    refetch,
    isLoading,
    setLocalNewsroomLinks,
  } = useGetPageEditorInfo(companyData.companyId, roleId);
  const companyId = featuredRoleData.companyId; // This may differ from auth.user.companyID if the role is a global template

  const toggleEditOpenModal = () => {
    setEditModalOpen(!editModalOpen);
  };

  const { control, handleSubmit, resetValues } = useEditRole(
    featuredRoleData,
    refetch,
    toggleEditOpenModal,
    roleId
  );

  const togglePublish = () => {
    refetch();
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = async (target) => {
    setSelectedComponent(null);
    setIsModalOpen(false);
    //this is broken because what is passed is the target, not a boolean :(
    if (target) {
      refetch();
    }
  };

  const closeModalNoRefresh = async (target) => {
    setSelectedComponent(null);
    setIsModalOpen(false);
  };

  const renderComponentForm = (componentType) => {
    setSelectedComponent(componentType);
  };

  const openNewsroomLinkModal = () => {
    setSelectedComponent("newsroomLink"); // Set the selected component to 'newsroomLink'
    setIsModalOpen(true); // Open the modal
  };

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <>
          <Meta title={`Editing: ${featuredRoleData.title}`} />
          <div className="grid grid-cols-[1.25fr_1fr] gap-5 mt-[20px] px-[75px]">
            {/* ===== EDITOR HEADER ===== */}
            <EditorHeader
              featuredRoleData={featuredRoleData}
              link={`hiringspree.io/${companyId}/${roleId}`}
              previewLink={`/pages/${companyId}/${roleId}/preview`}
              isLoaded={isLoading}
              togglePublish={togglePublish}
              openEditModal={toggleEditOpenModal}
            />

            {/* ===== DND FEED EDITOR ===== */}
            <div>
              {/* SHOW COMPANY CARD + ADD BLOCK */}
              <div className="flex gap-[10px] items-center px-[30px] py-[20px] bg-white block-shadow mb-[20px] rounded-md">
                <div className="flex gap-[10px] items-center">
                  <GridPlusLine className="stroke-[#3371ff]" />
                  <h2 className="font-semibold tracking-tight text-slate-900 text-xl">
                    Page Feed
                  </h2>
                </div>

                {/* ADD BLOCKS FROM JD BUTTON HERE? */}

                <button
                  className="ml-auto text-[#3371ff] font-normal bg-[#3371ff10] hover:bg-[#3371ff15] text-sm cursor-pointer rounded-lg px-[20px] h-[42px]"
                  onClick={openModal}
                >
                  Add Block
                </button>
              </div>
              {featuredRoleData.jobFeed?.length > 0 ? (
                <DragAndDrop
                  jobFeedData={featuredRoleData.jobFeed.sort(
                    (a, b) => a.order - b.order
                  )}
                  companyId={companyId}
                  roleId={featuredRoleData._id}
                  refetch={refetch}
                />
              ) : (
                <JobFeedEmpty openModal={openModal} />
              )}
            </div>

            {/* ===== LINKS ===== */}
            <div>
              <LinkContainer
                localNewsroomLinks={localNewsroomLinks}
                setLocalNewsroomLinks={setLocalNewsroomLinks}
                openNewsroomLinkModal={openNewsroomLinkModal}
                featuredRoleData={featuredRoleData}
              />
            </div>
            {editModalOpen && (
              <CreateEditRoleModal
                control={control}
                handleSubmit={handleSubmit}
                closeModal={() => {
                  toggleEditOpenModal();
                  resetValues();
                }}
                mode="Edit"
                currentCta={featuredRoleData.ctaType}
                isTemplateRole={featuredRoleData.isTemplateRole}
              />
            )}
          </div>

          {/* ===== MODAL ===== */}
          {isModalOpen && (
            <>
              {selectedComponent ? (
                <>
                  {/* Render the appropriate component form based on selectedComponent */}
                  {selectedComponent === "text" && (
                    <TextBlockInput
                      close={closeModal}
                      setSelectedComponent={setSelectedComponent}
                    />
                  )}
                  {selectedComponent === "image" && (
                    <ImageBlockInput
                      close={closeModal}
                      setSelectedComponent={setSelectedComponent}
                      refetch={refetch}
                    />
                  )}
                  {selectedComponent === "video" && (
                    <VideoBlockInput
                      close={closeModal}
                      closeNoRefresh={closeModalNoRefresh}
                      setSelectedComponent={setSelectedComponent}
                    />
                  )}
                  {selectedComponent === "embed" && (
                    <EmbedBlockInput
                      close={closeModal}
                      setSelectedComponent={setSelectedComponent}
                      refetch={refetch}
                    />
                  )}
                  {selectedComponent === "personnel" && (
                    <PersonnelBlockInput
                      close={closeModal}
                      closeNoRefresh={closeModalNoRefresh}
                      setSelectedComponent={setSelectedComponent}
                    />
                  )}
                  {selectedComponent === "hero" && (
                    <HeroBlockInput
                      close={closeModal}
                      closeNoRefresh={closeModalNoRefresh}
                      setSelectedComponent={setSelectedComponent}
                    />
                  )}
                  {selectedComponent === "newsroomLink" && (
                    <LinkInput
                      companyId={companyId}
                      roleId={roleId}
                      closeModalNoRefresh={closeModalNoRefresh}
                      closeModalWithRefresh={closeModal}
                      setLocalNewsroomLinks={setLocalNewsroomLinks}
                    />
                  )}
                  {/* Add other component forms as needed */}
                </>
              ) : (
                <AddComponentModal
                  close={closeModalNoRefresh}
                  renderComponentForm={(componentType) =>
                    renderComponentForm(componentType)
                  }
                  roleId={roleId}
                  closeWithRefresh={closeModal}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default requireAuth(LandingPageEditor);
