import React from "react";
import "./../styles/global.css";
import ErrorBoundary from "components/ErrorBoundaries/ErrorBoundary";
import Layout from "components/Layouts/Layout";
import AccountLayout from "components/Layouts/AccountLayout";
import IndexPage from "./Home/index";
import AboutPage from "./Home/about";
import ContactPage from "./Home/contact";
import AuthPage from "./auth";
import Team from "./Account/Team/Team";
import SharedComponents from "./Account/SharedComponents/SharedComponents";
import CompanySettings from "./Account/CompanySettings/CompanySettings";
import Analytics from "./Account/Analytics/Analytics";
import Home from "./Account/Home/Home";
import Help from "./Account/Help/Help";
import LandingPageEditor from "./Account/Home/LandingPageEditor";
import LandingPage from "./LandingPage/LandingPage";
import LegalPage from "./legal";
import { Routes, Route, Router } from "./../util/router";
import PurchasePage from "./purchase";
import FirebaseActionPage from "./firebase-action";
import NotFoundPage from "./404";
import "./../util/analytics";
import { AuthProvider } from "../util";
import { QueryClientProvider } from "../util";
import { CompanyProvider } from "../util";
import { HelmetProvider } from "react-helmet-async";
import Onboarding from "./Onboarding/Onboarding";
import SlackAuth from "./Account/ProfileSettings/SlackAuth";
import PreviewPage from "./Account/PreviewPage";
import Settings from "./Account/ProfileSettings/Settings";

function App(props) {
  return (
    <Router>
      <HelmetProvider>
        <QueryClientProvider>
          <AuthProvider>
            <CompanyProvider>
              <ErrorBoundary>
                {/* ===== Router is a wrapper around the BrowserRouter element */}

                <Routes>
                  {/* ========== HOME ========== */}
                  <Route exact path="/" element={<Layout />}>
                    <Route exact path="/" element={<IndexPage />} />

                    <Route exact path="/contact" element={<ContactPage />} />
                    <Route exact path="/about" element={<AboutPage />} />
                    {/* ========== LEGAL | TOS | PRIVACY ========== */}
                    <Route
                      exact
                      path="/legal/:section"
                      element={<LegalPage />}
                    />
                  </Route>

                  {/* ========== AUTH | SIGNUP | SIGNIN ========== */}
                  <Route exact path="/auth/:type" element={<AuthPage />} />
                  <Route exact path="/onboarding" element={<Onboarding />} />

                  {/* ========== ACCOUNT ========== */}
                  <Route exact path="/" element={<AccountLayout />}>
                    <Route path="pages/:view?">
                      <Route index element={<Home />} />
                      <Route
                        path="edit/:roleId"
                        element={<LandingPageEditor />}
                      />
                    </Route>
                    <Route path="analytics" element={<Analytics />} />
                    <Route path="team" element={<Team />} />
                    <Route path="components" element={<SharedComponents />} />
                    <Route path="company" element={<CompanySettings />} />
                    <Route path="settings" element={<Settings />} />
                    <Route path="support" element={<Help />} />
                  </Route>

                  {/* ========== INTEGRATIONS ========== */}
                  <Route
                    exact
                    path="/settings/slack/auth"
                    element={<SlackAuth />}
                  />

                  {/* ========== APP PAGES ========== */}
                  {/* PREVIEW */}
                  <Route
                    path="pages/:companyId/:roleId/preview"
                    element={<PreviewPage />}
                  />

                  {/* LIVE LINK */}
                  <Route
                    exact
                    path="/:companyId/:roleId"
                    element={<LandingPage />}
                  />

                  {/* ========== UTILITIES | PAYMENTS | 404 ========== */}
                  <Route
                    exact
                    path="/purchase/:plan"
                    element={<PurchasePage />}
                  />
                  <Route
                    exact
                    path="/firebase-action"
                    element={<FirebaseActionPage />}
                  />
                  <Route path="/not-found" element={<NotFoundPage />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </ErrorBoundary>
            </CompanyProvider>
          </AuthProvider>
        </QueryClientProvider>
      </HelmetProvider>
    </Router>
  );
}

export default App;
