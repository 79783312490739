import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { apiRoutes } from "constants";
import { makeApiRequest } from "constants";

// This hook is specifically used for the Landing Page
// Changing the companyId associated with an

const useGetPage = (roleId, previewMode, user, companyId) => {
  const [landingPageData, setLandingPageData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const pageRoute = previewMode
    ? apiRoutes.getPrivatePageByPageID(roleId)
    : apiRoutes.getPageByPageID(roleId);

  useEffect(() => {
    console.log("useEffect for fetching landing page data is running...");

    const fetchData = async () => {
      try {
        const landingPageResponse = await makeApiRequest(
          "get",
          pageRoute,
          previewMode ? user : undefined,
          companyId
        );

        console.log(landingPageResponse);

        setLandingPageData(landingPageResponse.data);
        setIsLoaded(true);
      } catch (error) {
        setError(error);
        if (error && error.response.status === 404) {
          navigate("/not-found");
        } else {
          console.error(`${error}`);
        }
      }
    };

    fetchData();

    // Clean-up function (if necessary)
    return () => {
      console.log("Cleaning up useEffect for fetching landing page data...");
      // Perform any clean-up actions here if needed
    };
  }, [roleId, navigate]);

  return { landingPageData, isLoaded, error };
};

export default useGetPage;
