import React from "react";
import Modal from "components/Modal";
import { apiRoutes, makeApiRequest } from "constants/apiRoutes";
import { useCompany } from "util";
import { ReactComponent as VideoIcon } from "assets/icons/video.svg";
import { ReactComponent as TextIcon } from "assets/icons/text.svg";
import { ReactComponent as BuildingUser } from "assets/icons/building-user.svg";
import { ReactComponent as ImageIcon } from "assets/icons/image-square.svg";
import { ReactComponent as EmbedIcon } from "assets/icons/code.svg";
import { useAuth } from "util";
import { Link } from "react-router-dom";

const blockTypes = [
  {
    blockType: "text",
    icon: <TextIcon className="stroke-[#3371ff]" />,
    label: "Text Block",
  },
  {
    blockType: "image",
    icon: <ImageIcon className="stroke-[#3371ff]" />,
    label: "Image Block",
  },
  {
    blockType: "video",
    icon: <VideoIcon className="stroke-[#3371ff]" />,
    label: "Video Block",
  },
  {
    blockType: "personnel",
    icon: <BuildingUser className="stroke-[#3371ff]" />,
    label: "Personnel Block",
  },
  {
    blockType: "embed",
    icon: <EmbedIcon className="stroke-[#3371ff]" />,
    label: "Embed Block",
  },
];

const addSharedComponentToFeed = async (
  companyId,
  roleId,
  blockData,
  user,
  close
) => {
  // Adding a shared component directly creates it without showing an "edit" modal
  const url = apiRoutes.createBlock(companyId, roleId);

  try {
    // We save shared components on the role's jobFeed without a blockType/title/content
    // and only a sharedId, which acts as a reference to the corresponding shared component
    // on the company entity
    const response = await makeApiRequest("post", url, user, companyId, {
      blockType: "",
      title: "",
      content: "",
      sharedId: blockData.id,
    });
    if (response.status < 300) {
      console.log("Shared block created successfully");
    } else {
      console.err("Failed to create shared block");
    }
  } catch (error) {
    console.error("Error sending request:", error);
  }

  close(true);
};

function AddComponentModal({
  close,
  renderComponentForm,
  roleId,
  closeWithRefresh,
  isTeamsPage,
}) {
  const { companyData } = useCompany();
  const auth = useAuth();

  return (
    <Modal
      title="Select a Component to Add"
      closeModal={close}
      size={!isTeamsPage && companyData.sharedComponents ? "md" : "sm"}
    >
      <div
        className={`${
          !isTeamsPage && companyData.sharedComponents
            ? "grid grid-cols-[1fr_2fr]"
            : ""
        } gap-[20px]`}
      >
        <div>
          {isTeamsPage ? null : (
            <h3 className="text-[13px] font-medium tracking-tight text-slate-700">
              Custom Components
            </h3>
          )}
          <div className="mt-2 flex flex-col gap-3">
            {blockTypes.map((block) => (
              <button
                className="border border-solid border-[#3371ff25] text-slate-700 hover:text-[#3371ff] hover:border-[#3371ff] bg-white px-4 py-3 w-full rounded-xl text-sm flex items-center justify-between"
                key={block.blockType}
                onClick={() => renderComponentForm(block.blockType)}
              >
                <span className="font-medium">{block.label}</span>
                {block.icon}
              </button>
            ))}
          </div>
        </div>
        {/* We don't need to show shared components on the teams page modal, since that's where an admin user creates them*/}
        {!isTeamsPage && companyData.sharedComponents ? (
          <div>
            <h3 className="mx-2 text-[13px] font-medium tracking-tight text-slate-700">
              Shared Components
            </h3>
            <div className="flex flex-col gap-3 overflow-scroll p-2">
              {companyData.sharedComponents
                ?.filter((block) => !block.isArchived)
                .map((block) => (
                  <button
                    className="border border-solid border-[#3371ff25] text-slate-700 hover:text-[#3371ff] hover:border-[#3371ff] bg-white px-4 py-3 w-full rounded-xl text-sm flex items-center justify-between"
                    key={block.blockType}
                    onClick={() => {
                      addSharedComponentToFeed(
                        companyData.companyId,
                        roleId,
                        block,
                        auth.user,
                        closeWithRefresh
                      );
                    }}
                  >
                    <span className="font-medium">{block.title}</span>
                    {
                      blockTypes.find(
                        (type) => type.blockType === block.blockType
                      )?.icon
                    }
                  </button>
                ))}
              {/* TODO - Hide this for Candidate Accounts since they won't have shared components */}
              {/* <Link
                to="/components"
                className="text-xs font-medium text-[#3371ff] self-end mr-1"
              >
                Shared Components Page
              </Link> */}
            </div>
          </div>
        ) : null}
      </div>
    </Modal>
  );
}

export default AddComponentModal;
