import React from "react";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { ReactComponent as Files } from "assets/icons/files.svg";

function PagesEmptyState({ createNew, navigateToGlobalTemplates }) {
  return (
    <div className="flex flex-col bg-white block-shadow p-[30px] rounded-[20px] items-center m-auto mt-[25px]">
      <h2 className="font-semibold tracking-tight text-slate-900 text-[18px]">
        You haven't created any pages
      </h2>
      <p className="text-slate-700 text-sm">
        Create a new page or start from one of our templates
      </p>
      <div className="flex gap-4 mt-8">
        <button
          className="text-slate-900 hover:text-[#3371ff] stroke-slate-900 hover:stroke-[#3371ff] border border-solid border-slate-900 hover:border-[#3371ff] bg-white block-shadow font-medium text-[15px] pl-3 pr-4 py-2.5 rounded-lg flex flex-row gap-2 items-center"
          type="button"
          onClick={createNew}
        >
          <Plus />
          <p>Create new page</p>
        </button>
        <button
          className="text-slate-900 hover:text-[#3371ff] stroke-slate-900 hover:stroke-[#3371ff] border border-solid border-slate-900 hover:border-[#3371ff] bg-white block-shadow font-medium text-[15px] pl-3 pr-4 py-2.5 rounded-lg flex flex-row gap-2 items-center"
          type="button"
          onClick={navigateToGlobalTemplates}
        >
          <Files />
          <p>Go to templates</p>
        </button>
      </div>
    </div>
  );
}

export default PagesEmptyState;
