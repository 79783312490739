import React from "react";
import LinkWithAnalyticsNoText from "pages/LandingPage/Components/Links/LinkWithAnalyticsNoText";
import { ReactComponent as LinkedIn } from "assets/icons/social/linkedin_icon.svg";
import { ReactComponent as Website } from "assets/icons/globe.svg";

function ProfilePicture({
  companyData,
  userData,
  isMobileModal,
  infoCardDisplay,
}) {
  const companyImageSrc =
    companyData?.companyLogoUrl ||
    `https://hs-icons.s3.us-east-2.amazonaws.com/${companyData?.companyId}square.png`;
  const userImageSrc = userData !== null ? userData?.userImage : null;

  function onError(e) {
    e.target.src =
      "https://hs-icons.s3.us-east-2.amazonaws.com/hs_logo_blue.png";
  }

  return (
    <div className="flex justify-between mb-4">
      <div className="flex items-end">
        <img
          className="h-[70px] w-[70px] rounded-full bg-white dark:border-[2px] border-solid border-[#3370ff80] dark:border-slate-300"
          src={infoCardDisplay === 0 ? companyImageSrc : userImageSrc}
          onError={(e) => onError(e)}
          alt="logo"
        />
        {infoCardDisplay === 0 ? null : (
          <div className=" h-[35px] w-[35px]">
            <LinkWithAnalyticsNoText
              text="Company Homepage - Icon"
              url={companyData?.homepageUrl}
              title="User Card - Company Hompage"
            >
              <img
                className="rounded-full ml-[-20px] bg-white border-[2px] dark:border-[3px] border-solid border-[#3370ff0f] dark:border-slate-800"
                src={companyImageSrc}
                onError={(e) => onError(e)}
                alt="logo"
              />
            </LinkWithAnalyticsNoText>
          </div>
        )}
      </div>
      {isMobileModal ? null : infoCardDisplay === 0 ? (
        <LinkWithAnalyticsNoText
          text="homepageLink"
          url={companyData?.homepageUrl}
          title="Company Card - Homepage"
        >
          <div className="h-[22px] w-[22px] stroke-[#3370ff80] dark:stroke-[#3370ff] hover:stroke-[#2566fda0] dark:hover:stroke-[#4a80fe]">
            <Website />
          </div>
        </LinkWithAnalyticsNoText>
      ) : (
        <LinkWithAnalyticsNoText
          text="Personal LinkedIn"
          url={userData?.userLinkedIn}
          title="User Card - LinkedIn"
        >
          <div className="h-[22px] w-[22px] fill-[#3370ff80] dark:fill-[#3370ff] hover:fill-[#2566fda0] dark:hover:fill-[#4a80fe]">
            <LinkedIn />
          </div>
        </LinkWithAnalyticsNoText>
      )}
    </div>
  );
}

export default ProfilePicture;
