import React, { useState, useCallback } from "react";
import useSessionId from "hooks/useSessionId";
import sendActivityData from "pages/LandingPage/Utilities/sendActivityData";

const THROTTLE_TIMEOUT = 1000; // 1 second

const LinkWithAnalyticsNoText = ({ text, url, title, children }) => {
  const [isClickAllowed, setIsClickAllowed] = useState(true);
  const { sessionId } = useSessionId();

  const handleLinkClick = useCallback(
    async (e) => {
      if (isClickAllowed) {
        setIsClickAllowed(false);

        const analyticsData = {
          activity: "link_click",
          timestamp: new Date().toISOString(),
          link_title: text,
          card_title: title,
          url,
        };
        // Check if "data-disable-analytics" is set to 1
        if (
          parseInt(e.currentTarget.getAttribute("data-disable-analytics")) === 1
        ) {
          console.log("Analytics data disabled for this link");
        } else {
          try {
            await sendActivityData(analyticsData, sessionId);
            console.log(analyticsData);
          } catch (error) {
            console.error("Error sending analytics data:", error);
          }
        }
        // Allow clicking again after a delay
        setTimeout(() => {
          setIsClickAllowed(true);
        }, THROTTLE_TIMEOUT);
      }
    },
    // eslint-disable-next-line
    [isClickAllowed, sessionId]
  );

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleLinkClick}
    >
      {children}
    </a>
  );
};

export default LinkWithAnalyticsNoText;
