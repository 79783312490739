import { client } from "constants/api";
import { apiRoutes } from "constants/apiRoutes";
import {useEffect} from "react";

export function getDataLink(dataLinkId, authHeaders) {
  const fetchData = async () => {
    try {
      const response = await client.get(apiRoutes.getDataLink(dataLinkId), authHeaders);

      if (response.status !== 200) {
        throw new Error("Failed to fetch data link information");
      }

      // Update counts based on eventType
      return response.data;
    } catch (error) {
      console.error("Error data link information:", error);
      return null;
    }
  };
  return fetchData();
}
