import React from "react";
import { ReactComponent as Bug } from "assets/icons/bug.svg";

function BugNub({ isOpen, toggle }) {
  return (
    <button
      className={`fixed bottom-[50px] right-0 p-4 block-shadow rounded-tl-lg rounded-bl-lg ${
        isOpen ? "bg-blue-500" : "bg-white"
      }`}
      onClick={toggle}
    >
      <Bug className={isOpen ? "fill-white" : "fill-blue-500"} />
    </button>
  );
}

export default BugNub;
