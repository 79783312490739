import React from "react";
import { decimalToPercentage } from "util/sessions";
import { ReactComponent as MailOpenCheck } from "assets/icons/mail-open-check.svg";
import { ReactComponent as UserCheck } from "assets/icons/user-check-alt-1.svg";

const SessionCounts = ({ totalSessions, uniqueVisitors }) => {
  return (
    <div>
      <h2 className="text-lg font-semibold mb-2 text-slate-900">
        Session Data
      </h2>
      <div className="flex flex-col gap-[15px]">
        <div className="hs-block bg-white py-4 pl-3 pr-5 text-sm tracking-tight font-medium">
          <div className="pl-[5px] flex items-center gap-[15px]">
            <div className="p-2 bg-blue-100 rounded-md stroke-[#3371ff]">
              <MailOpenCheck />
            </div>
            <div>
              <p>Unique Visitors</p>
              <p className="text-slate-400">
                Based on {totalSessions} total sessions
              </p>
            </div>
            <div className="ml-auto">
              <p className="text-[#3371ff] text-lg">{uniqueVisitors}</p>
            </div>
          </div>
        </div>
        <div className="hs-block bg-white py-4 pl-3 pr-5 text-sm tracking-tight font-medium">
          <div className="pl-[5px] flex items-center gap-[15px]">
            <div className="p-2 bg-blue-100 rounded-md stroke-[#3371ff]">
              <UserCheck />
            </div>
            <div>
              <p>Returning Visitors</p>
              <p className="text-slate-400">From a single device</p>
            </div>
            <div className="ml-auto">
              <p className="text-[#3371ff] text-lg">
                {totalSessions - uniqueVisitors}{" "}
                <span className="text-[15px] text-slate-500">
                  (
                  {decimalToPercentage(
                    (totalSessions - uniqueVisitors) / totalSessions || 0
                  )}
                  )
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionCounts;
