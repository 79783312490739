import React from "react";
import { ReactComponent as ArrowUpRight } from "assets/icons/arrow-up-right.svg";
import useSessionId from "hooks/useSessionId";
import sendActivityData from "../Utilities/sendActivityData";

const CTAButton = ({ url, ctaType, isMobileScreen }) => {
  const { sessionId } = useSessionId();
  const mobileClasses =
    "fixed bottom-[50px] right-0 h-[50px] pl-6 pr-4 text-xs rounded-l-full block-shadow";
  const regularClasses =
    "py-2 lg:py-3 pl-5 lg:pl-6 pr-2 lg:pr-3 text-xs lg:text-sm rounded-full";

  const handleNavigate = async (e) => {
    const analyticsData = {
      activity: "call_to_action",
      timestamp: new Date().toISOString(),
    };

    // Check if "data-disable-analytics" is set to 1
    if (
      parseInt(e.currentTarget.getAttribute("data-disable-analytics")) === 1
    ) {
      console.log("Analytics data disabled for this link");
    } else {
      try {
        await sendActivityData(analyticsData, sessionId);
      } catch (error) {
        console.error("Error sending analytics data:", error);
      }
    }
    if (process.env.NODE_ENV === "development") {
      console.log("Navigate to Cal Page");
    }
  };

  let buttonText;

  switch (ctaType) {
    case "Apply":
      buttonText = "Apply Here";
      break;
    case "BookIntro":
      buttonText = "Book Intro Call";
      break;
    // Add more cases for additional ctaTypes here: Referral, Event, etc.
    default:
      // Default case is an outbound CTA
      buttonText = "Book Intro Call";
  }

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleNavigate}
      className={`inline-flex items-center ${
        isMobileScreen ? mobileClasses : regularClasses
      } font-medium text-[#fff] bg-[#3371ff]`}
    >
      <div className="flex items-center gap-3">
        <p>{buttonText}</p>
        <div className={`bg-white dark:bg-slate-800 rounded-full p-1 lg:p-2`}>
          <ArrowUpRight className="fill-slate-900 dark:fill-white w-[18px] h-[18px] lg:w-[20px] lg:h-[20px]" />
        </div>
      </div>
    </a>
  );
};

export default CTAButton;
