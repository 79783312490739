import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../util";
import PrimaryActionButton from "components/Buttons/PrimaryActionButton";

function SettingsPassword(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm();

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);

    auth
      .updatePassword(data.pass)
      .then(() => {
        // Clear form
        reset();
        // Set success status
        props.onStatus({
          type: "success",
          message: "Your password has been updated",
        });
      })
      .catch((error) => {
        if (error.code === "auth/requires-recent-login") {
          // Update state to show re-authentication modal
          props.onStatus({
            type: "requires-recent-login",
            // Resubmit after reauth flow
            callback: () => onSubmit({ pass: data.pass }),
          });
          props.closeModal();
        } else {
          // Set error status
          props.onStatus({
            type: "error",
            message: error.message,
          });
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <label className="text-[13px] font-medium tracking-tight text-slate-700">
          Password
        </label>
        <input
          className="text-sm w-full mt-1 py-2 px-3 bg-slate-100 rounded-md"
          {...register("pass", {
            required: "Please enter a password",
          })}
          type="password"
          placeholder="Password"
        />
        {errors.pass && (
          <p className="mt-1 text-sm text-left text-red-600">
            {errors.pass.message}
          </p>
        )}
      </div>
      <div className="mt-3">
        <label className="text-[13px] font-medium tracking-tight text-slate-700">
          Confirm New Password
        </label>
        <input
          className="text-sm w-full mt-1 py-2 px-3 bg-slate-100 rounded-md"
          {...register("confirmPass", {
            required: "Please enter your new password again",
            validate: (value) => {
              if (value === getValues().pass) {
                return true;
              } else {
                return "This doesn't match your password";
              }
            },
          })}
          type="password"
          placeholder="Confirm Password"
        />
        {errors.confirmPass && (
          <p className="mt-1 text-sm text-left text-red-600">
            {errors.confirmPass.message}
          </p>
        )}
      </div>
      <div className="flex justify-end mt-[20px]">
        <PrimaryActionButton
          text={pending ? "..." : "Save"}
          type="submit"
          disabled={pending}
        />
      </div>
    </form>
  );
}

export default SettingsPassword;
