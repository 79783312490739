import React, {
  useEffect,
  useState,
  useContext,
  createContext,
  useCallback,
} from "react";
import { useAuth } from "./auth";
import { apiRoutes, makeApiRequest } from "constants/apiRoutes";

// Create a `useCompany` hook and `CompanyProvider` that enables
// any component to have access to the authenticated user's company's data.
const companyContext = createContext();
export const useCompany = () => useContext(companyContext);
// This should wrap the app in `src/pages/_app.js`, and be wrapped by AuthProvider
export function CompanyProvider({ children }) {
  const auth = useAuth();
  const [companyData, setCompanyData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [userRole, setUserRole] = useState(null);

  const setSelectedCompany = useCallback(
    (companyId) => {
      const userRoleForCompany = auth.user?.permissions?.find(
        (perm) => perm.companyId === companyId
      )?.userRole;

      setSelectedCompanyId(companyId || auth.user.companyId);
      // The "|| auth.user.companyId" remains for backwards compatability
      // with firebase users that don't have the permissions field.
      setUserRole(userRoleForCompany || auth.user?.userRole);
      localStorage.setItem(`${auth.user.id}_selectedCompanyId`, companyId);
    },
    [auth.user]
  );

  const fetchCompanyData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await makeApiRequest(
        "get",
        apiRoutes.companyDataById(selectedCompanyId),
        auth.user,
        selectedCompanyId
      );

      if (response.error) {
        setCompanyData(null);
      } else {
        // The _id is generated by MongoDB, we should never need it (causes errors in put requests)
        const { _id, ...companyDataNoId } = response.data;
        setCompanyData(companyDataNoId);
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [selectedCompanyId, setSelectedCompany, auth.user]);

  useEffect(() => {
    if (auth.user) {
      if (!selectedCompanyId) {
        // The "|| auth.user.companyId" remains for backwards compatability
        // with firebase users that don't have the permissions field.
        setSelectedCompany(
          localStorage.getItem(`${auth.user.id}_selectedCompanyId`) ||
            auth.user.permissions?.[0]?.companyId ||
            auth.user.companyId
        );
      }

      if (selectedCompanyId && !companyData) {
        fetchCompanyData();
      }
    }
  }, [selectedCompanyId, userRole, companyData, auth.user]);

  const handleUpdateSocialLinks = (updatedLinks) => {
    setCompanyData((prevData) => ({
      ...prevData,
      socialLinks: updatedLinks,
    }));
  };

  return (
    <companyContext.Provider
      value={{
        companyData,
        userRole,
        handleUpdateSocialLinks,
        isCompanyDataLoading: isLoading,
        fetchCompanyData,
        setSelectedCompany,
      }}
    >
      {children}
    </companyContext.Provider>
  );
}
