import React from "react";

function Submit({ text }) {
  return (
    <button
      type="submit"
      className="bg-[#3371ff] text-white border-none rounded-[10px] font-base text-sm px-6 py-3 cursor-pointer"
    >
      {text}
    </button>
  );
}

export default Submit;
