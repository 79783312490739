import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Modal from "components/Modal";
import PrimaryActionButton from "components/Buttons/PrimaryActionButton";
import SecondaryActionButton from "components/Buttons/SecondaryActionButton";
import { apiRoutes, makeApiRequest } from "../../../../constants";
import { isValidUrl, useAuth, useCompany } from "../../../../util";

function EditLinkForm({
  linkData,
  closeEditModal,
  closeEditModalNoRefresh,
  setLocalNewsroomLinks,
}) {
  const { roleId } = useParams();
  const [showInvalidUrlMessage, setShowInvalidUrlMessage] = useState(false);
  const [editedLink, setEditedLink] = useState({
    id: linkData.id,
    title: linkData.title,
    source: linkData.source,
    url: linkData.url,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditedLink((prevLink) => ({
      ...prevLink,
      [name]: value,
    }));
  };
  const auth = useAuth();
  const { companyData } = useCompany();
  const companyId = companyData.companyId;
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isValidUrl(editedLink.url)) {
      setShowInvalidUrlMessage(true);
      return;
    }

    try {
      const response = await makeApiRequest(
        "put",
        apiRoutes.updatePageLink(companyId, roleId, linkData.id),
        auth.user,
        companyData.companyId,
        editedLink
      );

      if (response.status === 204) {
        // Update the local state in the parent component
        setLocalNewsroomLinks((prevLinks) =>
          prevLinks.map((link) => (link.id === linkData.id ? editedLink : link))
        );
        closeEditModal();
      } else {
        console.error("Failed to update link");
      }
    } catch (error) {
      console.error("Error updating link", error);
    }
  };

  return (
    <Modal title="Edit Link" closeModal={closeEditModalNoRefresh} size="sm">
      <form
        onSubmit={handleSubmit}
        className="flex flex-col mt-[20px] gap-[20px]"
      >
        {Object.keys(editedLink).map((fieldName) => {
          if (fieldName !== "id") {
            return (
              <div>
                <label
                  className="text-[13px] font-medium tracking-tight text-slate-700"
                  key={fieldName}
                >
                  {fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}
                </label>
                <input
                  className="text-sm w-full mt-1 py-2 px-3 bg-slate-100 rounded-md"
                  type="text"
                  name={fieldName}
                  value={editedLink[fieldName]}
                  onChange={handleChange}
                />
                {fieldName === "url" && showInvalidUrlMessage ? (
                  <p className="text-xs pt-1 pl-1 text-red-500">
                    Invalid url: use http/https protocol
                  </p>
                ) : null}
              </div>
            );
          }
          return null;
        })}
        <div className="flex mt-[15px] ml-auto gap-[10px]">
          <SecondaryActionButton
            text="Cancel"
            action={closeEditModalNoRefresh}
          />
          <PrimaryActionButton text="Edit Link" buttonType="submit" />
        </div>
      </form>
    </Modal>
  );
}

export default EditLinkForm;
