import React from "react";

function PersonWithTitle({ person }) {
  return (
    <>
      <a href={person.linkedinUrl} className="flex items-center gap-x-4">
        <img
          className="h-16 w-16 rounded-full block-shadow"
          src={person.imageUrl}
          alt=""
        />
        <div>
          <h3 className="text-base font-semibold leading-7 tracking-tight text-slate-900 dark:text-slate-300">
            {person.name}
          </h3>
          <p className="text-sm font-medium leading-6 text-[#3371ff]">
            {person.title}
          </p>
        </div>
      </a>
    </>
  );
}

export default PersonWithTitle;
