export const Input = ({
  label,
  type,
  value,
  setValue,
  name,
  required = false,
  placeholder,
  id,
  ...props
}) => {
  return (
    <div>
      <label className="text-[13px] font-medium tracking-tight text-slate-700">
        {label}
      </label>
      <input
        id={id}
        className="text-sm w-full mt-1 py-2 px-3 bg-slate-100 rounded-md"
        type={type}
        value={value}
        onChange={setValue}
        name={name}
        required={required}
        placeholder={placeholder}
        {...props}
      />
    </div>
  );
};
