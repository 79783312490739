import React from "react";

import TextBlock from "./DisplayBlocks/TextBlock";
import ImageBlock from "./DisplayBlocks/ImageBlock";
import Personelle from "./DisplayBlocks/PersonnelBlock";
import TwitterFeed from "pages/LandingPage/Components/DisplayBlocks/TwitterFeedBlock";
import TwitterPost from "./DisplayBlocks/TwitterPostBlock";
import LinkedInPost from "pages/LandingPage/Components/DisplayBlocks/LinkedInPostBlock";
import VideoBlock from "pages/LandingPage/Components/DisplayBlocks/VideoBlock";
import HeroBlock from "./DisplayBlocks/HeroBlock";

const LandingPageBlocks = ({ contentFeed, companyData }) => {
  return (
    <>
      {contentFeed?.map((block) => {
        const item = block.sharedId
          ? {
              ...companyData.sharedComponents?.find(
                (sharedComponent) => sharedComponent.id === block.sharedId
              ),
              id: block.id,
              sharedId: block.sharedId,
              order: block.order,
            }
          : block;

        switch (item.blockType) {
          case "video":
            return (
              <div id={item.id} key={`block_${item.id}`}>
                <VideoBlock key={item.id} {...item} />
              </div>
            );
          case "text":
            return (
              <div id={item.id} key={`block_${item.id}`}>
                <TextBlock key={item.id} {...item} />
              </div>
            );
          case "image":
            return (
              <div id={item.id} key={`block_${item.id}`}>
                <ImageBlock key={item.id} {...item} />
              </div>
            );
          case "personnel":
            return (
              <div id={item.id} key={`block_${item.id}`}>
                <Personelle key={item.id} {...item} />
              </div>
            );
          case "linkedin-post":
            return (
              <div id={item.id} key={`block_${item.id}`}>
                <LinkedInPost key={item.id} {...item} />
              </div>
            );
          case "twitter-post":
            return (
              <div id={item.id} key={`block_${item.id}`}>
                <TwitterPost key={item.id} {...item} />
              </div>
            );
          case "twitter-timeline":
            return (
              <div id={item.id} key={`block_${item.id}`}>
                <TwitterFeed key={item.id} {...item} />
              </div>
            );
          case "hero":
            return (
              <div id={item.id} key={`block_${item.id}`}>
                <HeroBlock key={item.id} {...item} />
              </div>
            );
          // ... other cases
          default:
            return null; // Handle unknown block types or add a generic fallback
        }
      })}
    </>
  );
};

export default LandingPageBlocks;
