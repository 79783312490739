import { LINK_OPTIONS } from "constants/constants";
import React, { useState } from "react";
import { apiRoutes, makeApiRequest } from "../../../../constants";
import { useAuth } from "util";
import { useCompany } from "util";
import StyledSelect from "components/StyledSelect";

function SocialLinkInput({ companyId, onClose }) {
  const [newSocialLink, setNewSocialLink] = useState({
    platform: "",
    url: "",
    label: "",
  });
  const auth = useAuth();
  const { companyData, fetchCompanyData } = useCompany();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewSocialLink((prevLink) => ({
      ...prevLink,
      [name]: value,
    }));
  };

  const handleSelect = (event) => {
    setNewSocialLink((prev) => ({
      ...prev,
      platform: event.value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await makeApiRequest(
        "post",
        apiRoutes.createCompanySocialLink(companyId),
        auth.user,
        companyData.companyId,
        newSocialLink
      );
      if (response.status < 300) {
        fetchCompanyData();
        onClose();
      } else {
        console.error("Failed to add social link");
      }
    } catch (error) {
      console.error("Error adding social link", error);
    }
  };

  return (
    <div className="add-social-link-form">
      <form
        onSubmit={handleSubmit}
        className="flex flex-col mt-[20px] gap-[20px]"
      >
        <StyledSelect
          label="Platform:"
          placeholder="Select a platform"
          options={LINK_OPTIONS}
          value={LINK_OPTIONS.find(
            (option) => option.value === newSocialLink.platform
          )}
          onChange={handleSelect}
        />
        {Object.keys(newSocialLink).map((fieldName) => {
          if (fieldName !== "platform") {
            return (
              <label
                className="text-[13px] font-medium tracking-tight text-slate-700"
                key={fieldName}
              >
                {fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}:
                <input
                  className="text-[14px] w-full mt-1 py-2 px-3 bg-slate-100 rounded-md"
                  type="text"
                  name={fieldName}
                  placeholder={`Enter ${fieldName}`}
                  value={newSocialLink[fieldName]}
                  onChange={handleChange}
                />
              </label>
            );
          }
          return null;
        })}
        <div className="form-actions">
          <button
            type="submit"
            className="bg-[#3371ff] text-white border-none rounded-md font-semibold w-full h-[40px] cursor-pointer modal-submit"
          >
            Add Link
          </button>
        </div>
      </form>
    </div>
  );
}

export default SocialLinkInput;
