import React from "react";

function InfoCardBody({ companyData }) {
  return (
    <div className="text-[13px] mb-4 text-slate-900 dark:text-slate-300">
      <p className="text-[13px] text-slate-900 dark:text-slate-400 mb-4">
        {companyData?.blurb}
      </p>
      <p>
        <span className="font-semibold">Founded:</span>{" "}
        {companyData?.foundedDate}
      </p>
      <p>
        <span className="font-semibold">Employees:</span>{" "}
        {companyData?.companySize}
      </p>
      <p>
        <span className="font-semibold">HQ:</span> {companyData?.hqLocation}
      </p>
      <p>
        <span className="font-semibold">Funding:</span>{" "}
        {companyData?.fundingStatus}
        {companyData?.fundingStatus !== "Public" &&
        companyData?.fundingType !== ""
          ? ` (${companyData?.fundingType})`
          : null}
        {companyData?.fundingStatus === "Public" ? (
          <span>
            {" "}
            (
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="color-[#8eaeff] font-bold no-underline hover:underline"
              href={`https://www.google.com/finance/quote/${companyData?.fundingType.ticker}:${companyData?.fundingType.exchange}`}
            >
              {companyData?.fundingType.exchange}:
              {companyData?.fundingType.ticker}
            </a>
            )
          </span>
        ) : null}
      </p>
    </div>
  );
}

export default InfoCardBody;
