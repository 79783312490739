import React, { useState, useEffect } from "react";
import LinkWithActions from "./LinkWithActions";
import EditLinkForm from "./EditLinkForm";

function EditLandingPageLinks({ localNewsroomLinks, setLocalNewsroomLinks }) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedLinkIndex, setSelectedLinkIndex] = useState(null);

  // Use the useEffect hook to update local state when the prop changes
  useEffect(() => {
    setLocalNewsroomLinks(localNewsroomLinks);
  }, [localNewsroomLinks]);

  const openEditModal = (index) => {
    setSelectedLinkIndex(index);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setSelectedLinkIndex(null);
    setIsEditModalOpen(false);
  };

  const closeEditModalNoRefresh = () => {
    setIsEditModalOpen(false);
  };

  var links = localNewsroomLinks?.map((item, i) => {
    return (
      <li className="py-3 border-solid border-slate-100 border-b">
        <LinkWithActions
          newsroomLink={item}
          openEditModal={openEditModal}
          index={i}
          setLocalNewsroomLinks={setLocalNewsroomLinks}
        />
      </li>
    );
  });

  return (
    <>
      {isEditModalOpen && (
        <>
          {/* Render the form to edit the selected link */}
          <EditLinkForm
            linkData={localNewsroomLinks[selectedLinkIndex]}
            closeEditModal={closeEditModal}
            closeEditModalNoRefresh={closeEditModalNoRefresh}
            setLocalNewsroomLinks={setLocalNewsroomLinks}
          />
        </>
      )}
      <ul className="flex flex-col">{links}</ul>
    </>
  );
}

export default EditLandingPageLinks;
