import React from "react";
import { convertToCSV } from "util/sessions";
import { ReactComponent as Download } from "assets/icons/download.svg";

const ExportToCSVButton = ({ sessionData }) => {
  const handleExport = () => {
    const csvContent = convertToCSV(sessionData);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "hiringspree_sessions.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <button
      onClick={handleExport}
      className="bg-white text-[#3371ff] border border-solid border-[#3371ff] block-shadow font-medium text-[15px] pl-3 pr-5 py-2.5 rounded-lg flex flex-row gap-2 items-center"
      type="button"
    >
      <Download className="stroke-[#3371ff]" />
      <p>Export to CSV</p>
    </button>
  );
};

export default ExportToCSVButton;
