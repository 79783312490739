import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import InputHeader from "./InputHeader";
import { ReactComponent as EmbedIcon } from "assets/icons/code.svg";
import { Input } from "components/Input";
import { TextArea } from "components/TextArea";
import { apiRoutes } from "constants/apiRoutes";
import { client } from "constants/api";
import { useAuth } from "util";
import InputModal from "./InputModal";
import CompanyComponentEditWarning from "./CompanyComponentEditWarning";
import PrimaryActionButton from "components/Buttons/PrimaryActionButton";
import Select from "react-select";
import { useCompany } from "util";

const contentTypes = [
  { value: "twitter-post", label: "Twitter Post" },
  { value: "twitter-timeline", label: "Twitter Timeline" },
  { value: "linkedin-post", label: "LinkedIn Post" },
  // Add more content types as needed
];

function EmbedBlockInput({
  close,
  setSelectedComponent,
  isEditing,
  existingContent = {},
  refetch,
  isTeamsPage,
  handleCreateSharedComponent,
  handleEditSharedComponent,
}) {
  const [title, setTitle] = useState(isEditing ? existingContent.title : "");
  const [contentType, setContentType] = useState(
    isEditing ? existingContent.blockType : contentTypes[0].value
  );
  const [embedCode, setEmbedCode] = useState(
    isEditing ? existingContent.content : ""
  );
  const auth = useAuth();
  const { companyData } = useCompany();

  const companyId = companyData.companyId;
  const { roleId } = useParams();

  const handleContentTypeChange = (selectedOption) => {
    setContentType(selectedOption.value);
  };

  const handleEmbedCodeChange = (e) => {
    setEmbedCode(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const blockData = {
      blockType: contentType,
      title,
      content: embedCode,
      ...(isEditing && {
        id: existingContent.id,
        order: existingContent.order,
      }),
    };

    const url = isEditing
      ? apiRoutes.editBlock(companyId, roleId, existingContent.id)
      : apiRoutes.createBlock(companyId, roleId);

    try {
      let response;
      if (isTeamsPage) {
        response = isEditing
          ? await handleEditSharedComponent(blockData)
          : await handleCreateSharedComponent(blockData);
      } else {
        response = await client[isEditing ? "put" : "post"](url, blockData, {
          headers: {
            Authorization: auth.user
              ? `${auth.user.id}.${auth.user.accessToken}`
              : "",
          },
        });
      }

      if (response.status < 300) {
        if (isEditing) {
          console.log("Block updated successfully");
          refetch();
        } else {
          console.log("Block created successfully");
          refetch();
        }
        setTitle("");
        setContentType(contentTypes[0].value);
        setEmbedCode("");
        close();
      } else {
        console.error(
          isEditing ? "Failed to update block" : "Failed to create block"
        );
      }
    } catch (error) {
      console.error("Error sending request:", error);
    }
  };

  useEffect(() => {
    if (isEditing) {
      setTitle(existingContent.title);
      setContentType(existingContent.blockType);
      setEmbedCode(existingContent.content);
    }
  }, [isEditing, existingContent]);

  const unsavedChanges =
    (isEditing
      ? title !== existingContent.title || embedCode !== existingContent.content
      : title !== "" || embedCode !== "") && contentType !== "";

  return (
    <InputModal size="md">
      <InputHeader
        icon={<EmbedIcon className="stroke-[#3371ff]" />}
        title="Embed"
        isEditing={isEditing}
        existingContent={existingContent}
        setSelectedComponent={setSelectedComponent}
        close={close}
        unsavedChanges={unsavedChanges}
      />
      <form
        onSubmit={handleSubmit}
        className="flex flex-col mt-[20px] gap-[20px]"
      >
        <Input
          setValue={(e) => setTitle(e.target.value)}
          value={title}
          required
          label="Title:"
          id="title"
        />
        <div className="flex flex-col gap-[10px]">
          <label
            htmlFor="contentType"
            className="text-[13px] font-medium tracking-tight text-slate-700"
          >
            Content Type
          </label>
          <Select
            id="contentType"
            value={contentTypes.find((option) => option.value === contentType)}
            onChange={handleContentTypeChange}
            options={contentTypes}
            required
          />
        </div>
        <TextArea
          value={embedCode}
          onChange={handleEmbedCodeChange}
          label="Embed Code:"
          id="embedCode"
        />
        <CompanyComponentEditWarning sharedId={existingContent.sharedId} />
        <div className="ml-auto flex gap-[15px]">
          <PrimaryActionButton
            text={isEditing ? "Update Block" : "Create Block"}
            buttonType="submit"
            disabled={!unsavedChanges}
          />
        </div>
      </form>
    </InputModal>
  );
}

export default EmbedBlockInput;
