import React from "react";
import { Link } from "react-router-dom";
import LegalTerms from "./LegalTerms";
import LegalPrivacy from "./LegalPrivacy";
import LegalCookies from "./LegalCookies";

function LegalSection(props) {
  const validSections = {
    "terms-of-use": true,
    "privacy-policy": true,
    "cookie-policy": false,
  };

  const section = validSections[props.section] ? props.section : "terms-of-use";

  const data = {
    domain: "hiringspree.io",
    companyName: "Hiring Spree LLC",
  };

  return (
    <div className="container mx-auto mt-10">
      <div className="flex justify-center mb-10">
        <div className="block-shadow p-[30px] bg-white rounded-[30px] space-x-5">
          <Link
            to="/legal/terms-of-use"
            className={
              "" + (section === "terms-of-use" ? " text-blue-600" : "")
            }
          >
            Terms of Use
          </Link>
          <Link
            to="/legal/privacy-policy"
            className={
              "" + (section === "privacy-policy" ? " text-blue-600" : "")
            }
          >
            Privacy Policy
          </Link>
          {/* <Link
            to="/legal/cookie-policy"
            className={
              "" + (section === "cookie-policy" ? " text-blue-600" : "")
            }
          >
            Cookie Policy
          </Link> */}
        </div>
      </div>
      <div className="block-shadow p-[30px] bg-white rounded-[30px] mb-[50px]">
        {section === "terms-of-use" && <LegalTerms {...data} />}

        {section === "privacy-policy" && <LegalPrivacy {...data} />}

        {section === "cookie-policy" && <LegalCookies {...data} />}
      </div>
    </div>
  );
}

export default LegalSection;
