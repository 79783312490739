import React from "react";
import SocialLinks from "../SocialLinks";
import LinkWithAnalyticsNoText from "pages/LandingPage/Components/Links/LinkWithAnalyticsNoText";
import { ReactComponent as PublishedCheck } from "assets/icons/badge-check.svg";
import { ReactComponent as Website } from "assets/icons/globe.svg";
import InfoCardLayout from "./InfoCardLayout";

function MobileInfoCard({
  companyData,
  setShowCompanyInfoModal,
  setShowLinksModal,
}) {
  function onError(e) {
    e.target.src =
      "https://hs-icons.s3.us-east-2.amazonaws.com/hs_logo_blue.png";
  }

  return (
    <InfoCardLayout padding="p-[30px]">
      <div className="flex justify-end">
        <LinkWithAnalyticsNoText
          text="Mobile Company Card - Homepage"
          url={companyData?.homepageUrl}
          title="Mobile Company Card - Homepage"
        >
          <div className="h-[22px] w-[22px] stroke-[#3370ff80] dark:stroke-[#3370ff] hover:stroke-[#2566fda0] dark:hover:stroke-[#4a80fe]">
            <Website />
          </div>
        </LinkWithAnalyticsNoText>
      </div>
      <div className="flex justify-center mb-2">
        <img
          className="h-[60px] block-shadow rounded-full bg-white"
          src={`https://hs-icons.s3.us-east-2.amazonaws.com/${companyData?.companyId}square.png`}
          onError={(e) => onError(e)}
          alt="logo"
        />
      </div>
      <div className="mb-4">
        <div className="flex items-center justify-center gap-2">
          <h2 className="font-semibold tracking-tight text-slate-900 dark:text-slate-200 text-lg">
            {companyData?.companyName}
          </h2>
          <PublishedCheck className="fill-[#3371ff] w-[20px]" />
        </div>
      </div>

      <div className="flex gap-5 justify-center mb-4">
        <button
          className="text-[#3371ff] font-normal bg-[#3371ff10] hover:bg-[#3371ff15] text-sm cursor-pointer rounded-lg px-[20px] py-1 h-auto"
          onClick={() => setShowCompanyInfoModal(true)}
        >
          Company Info
        </button>
        <button
          className="text-[#3371ff] font-normal bg-[#3371ff10] hover:bg-[#3371ff15] text-sm cursor-pointer rounded-lg px-[20px] py-1 h-auto"
          onClick={() => setShowLinksModal(true)}
        >
          Company Links
        </button>
      </div>

      <SocialLinks socialLinks={companyData?.socialLinks} />
    </InfoCardLayout>
  );
}

export default MobileInfoCard;
