import React from "react";
import { ReactComponent as MobilePhone } from "assets/icons/mobile-alt-1.svg";
import { ReactComponent as Laptop } from "assets/icons/laptop-alt.svg";
import { decimalToPercentage } from "util/sessions";

const UserDistribution = ({ desktopCount, mobileCount }) => {
  // Determine which count is larger
  const totalCount = desktopCount + mobileCount;
  const isDesktopLarger = desktopCount >= mobileCount;
  const largerCount = isDesktopLarger ? desktopCount : mobileCount;
  const smallerCount = isDesktopLarger ? mobileCount : desktopCount;
  const largerLabel = isDesktopLarger ? "Desktop Users" : "Mobile Users";
  const smallerLabel = isDesktopLarger ? "Mobile Users" : "Desktop Users";

  return (
    <div>
      <h2 className="text-lg font-semibold mb-2 text-slate-900">
        Device Distribution
      </h2>
      <div className="flex flex-col gap-[15px]">
        <div className="hs-block bg-white py-4 pl-3 pr-5 text-sm tracking-tight font-medium">
          <div className="pl-[5px] flex items-center gap-[15px]">
            <div className="p-2 bg-blue-100 rounded-md stroke-[#3371ff]">
              {largerLabel === "Desktop Users" ? <Laptop /> : <MobilePhone />}
            </div>
            <div>
              <p>{largerLabel}</p>
              <p className="text-slate-400">{largerCount} sessions</p>
            </div>
            <div className="ml-auto">
              <p className="text-[#3371ff] text-lg">
                {decimalToPercentage(largerCount / totalCount || 0)}
              </p>
            </div>
          </div>
        </div>

        <div className="hs-block bg-white py-4 pl-3 pr-5 text-sm tracking-tight font-medium">
          <div className="pl-[5px] flex items-center gap-[15px]">
            <div className="p-2 bg-blue-100 rounded-md stroke-[#3371ff]">
              {smallerLabel === "Desktop Users" ? <Laptop /> : <MobilePhone />}
            </div>
            <div>
              <p>{smallerLabel}</p>
              <p className="text-slate-400">{smallerCount} sessions</p>
            </div>
            <div className="ml-auto">
              <p className="text-[#3371ff] text-lg">
                {decimalToPercentage(smallerCount / totalCount || 0)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDistribution;
