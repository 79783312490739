import React, { useState } from "react";

function HeroBlock({ content }) {
  const [src, setSrc] = useState(content[0].videoUrl);

  const handleVideoChange = (index) => {
    setSrc(content[index].videoUrl);
  };

  return (
    <div className="hero-block w-full">
      <iframe src={src} title="Video Player" allowFullScreen />
      {content.length > 1 ? (
        <div className="w-full overflow-x-scroll flex items-center gap-[10px] mt-[10px]">
          {content.map((video, index) => (
            <button
              className="flex-nowrap inline-flex cursor-pointer bg-[#3371ff] hover:bg-[#3370ffef] text-white text-sm font-medium px-[20px] rounded-md items-center justify-center h-[30px]"
              type="button"
              key={index}
              onClick={() => handleVideoChange(index)}
            >
              {video.videoTitle}
            </button>
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default HeroBlock;
