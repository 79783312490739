import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { apiRoutes, makeApiRequest } from "constants/apiRoutes";
import { useAuth } from "util";
import { useCompany } from "util";

export const useEditCompany = (id, toggleEditForm) => {
  const [isSaving, setIsSaving] = useState(false);

  const { companyData, fetchCompanyData } = useCompany();
  const auth = useAuth();

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      blurb: "",
      companySize: "",
      foundedDate: "",
      fundingStatus: "",
      fundingType: "",
      homepageUrl: "",
      hqLocation: "",
      industry: [],
    },
  });

  useEffect(() => {
    if (companyData) {
      reset({
        blurb: companyData.blurb,
        companySize: companyData.companySize,
        foundedDate: companyData.foundedDate,
        fundingStatus: companyData.fundingStatus,
        fundingType: companyData.fundingType,
        homepageUrl: companyData.homepageUrl,
        hqLocation: companyData.hqLocation,
        industry: companyData.industry,
      });
    }
  }, [companyData, reset]);

  const onSubmit = async (data) => {
    try {
      setIsSaving(true);
      const updatedData = { ...companyData, ...data };
      const response = await makeApiRequest(
        "put",
        apiRoutes.companyDataById(id),
        auth.user,
        companyData.companyId,
        updatedData
      );
      if (response.status === 200) {
        console.log("Data saved successfully!");
        fetchCompanyData();
      } else {
        console.error(
          "Failed to save data:",
          response.status,
          response.statusText
        );
      }
      setIsSaving(false);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsSaving(false);
      toggleEditForm(); // Set isSaving to false after the save process is complete
    }
  };

  return {
    handleSubmit: handleSubmit(onSubmit),
    control,
    isSaving,
  };
};
