import React, { useState } from "react";
import CreateCompany from "pages/Onboarding/CreateCompany";
import CompanySearch from "./CompanySearch";

function CompanyMatch() {
  const [companyExists, setCompanyExists] = useState(true);

  const toggleCreateCompany = () => {
    setCompanyExists(!companyExists);
  };

  return (
    <>
      {companyExists ? (
        <CompanySearch toggleCreateCompany={toggleCreateCompany} />
      ) : (
        <CreateCompany toggleCreateCompany={toggleCreateCompany} />
      )}
    </>
  );
}

export default CompanyMatch;
