import { apiRoutes, makeApiRequest } from "../../../constants";

let intervalSet = false;
let startTimeInSeconds = new Date().getTime() / 1000;
let accumulatedTime = 0;

export async function trackTimeOnPage(sessionId, previewMode) {
  const trackTimeOnPageTimeFrame = 5000; // 5 seconds

  const handleVisibilityChange = () => {
    if (!document.hidden) {
      startTimeInSeconds = new Date().getTime() / 1000;
    } else {
      addToAccumulatedTime();
    }
  };

  document.addEventListener("visibilitychange", handleVisibilityChange);

  const addToAccumulatedTime = () => {
    const currentTimeInSeconds = new Date().getTime() / 1000;
    accumulatedTime += currentTimeInSeconds - startTimeInSeconds;
    startTimeInSeconds = currentTimeInSeconds;
  };

  const sendAnalyticsData = async () => {
    if (document.hidden) {
      return;
    }

    addToAccumulatedTime();

    const analyticsData = {
      time_on_page: trackTimeOnPageTimeFrame / 1000,
      last_updated: new Date().toISOString(),
      sessionId: sessionId,
    };

    if (process.env.NODE_ENV === "development" || previewMode) {
      console.log(analyticsData);
    } else {
      try {
        let response = await makeApiRequest(
          "put",
          apiRoutes.addSessionActivity(sessionId, "time_on_page"),
          undefined,
          undefined,
          analyticsData
        );
        console.log(response);
      } catch (error) {
        console.error("Error sending analytics data:", error);
        // Optionally handle or log errors here
      }
    }
  };

  if (!intervalSet) {
    setInterval(sendAnalyticsData, trackTimeOnPageTimeFrame);
    intervalSet = true;
  }
}
