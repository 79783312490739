import React from "react";
import Tooltip from "components/Tooltip";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as MobilePhone } from "assets/icons/mobile-alt-1.svg";
import { ReactComponent as Laptop } from "assets/icons/laptop-alt.svg";

const SessionBlock = ({ session }) => {
  return (
    <>
      <div className="hs-block bg-white py-4 pl-3 pr-5 text-sm tracking-tight font-medium grid grid-cols-[2fr_1fr_1fr_1fr_1fr]">
        {/* col 1 */}
        <div className="pl-[5px] flex items-center gap-[15px]">
          <Tooltip
            text={`${
              session.deviceType === "mobile" ? "Mobile User" : "Desktop User"
            }`}
          >
            <div className="p-2 bg-slate-100 hover:bg-blue-100 rounded-md stroke-slate-500 hover:stroke-[#3371ff]">
              {session.deviceType === "mobile" ? <MobilePhone /> : <Laptop />}
            </div>
          </Tooltip>
          <div>
            <p>{session.name ? session.name : "Anonymous User"}</p>
            <p className="text-slate-400">ID: {session.visitorId}</p>
          </div>
        </div>

        {/* col 2 */}
        <div className="flex items-center">
          <p className="text-slate-900">
            Activities: {session.activity.length}
          </p>
        </div>

        {/* col 3 */}
        <div className="flex items-center">
          <p className="text-slate-900">Page: {session.role_title}</p>
        </div>

        {/* col 4 */}
        <div className="flex items-center">
          <p className="text-slate-900">
            Time on Page: {session.time_on_page} seconds
          </p>
        </div>

        {/* col 5 */}
        <div className="flex items-center gap-[15px] justify-end">
          <Tooltip text="Session Details">
            <a href={`/sessions/${session.sessionId}/details`}>
              <EyeIcon className="stroke-slate-500 hover:stroke-[#3371ff]" />
            </a>
          </Tooltip>

          {/* {isConfirmationModalOpen && (
            <ConfirmationModal
              title="You sure about that?"
              message="Are you sure you want to delete this session?"
              onConfirm={() => handleConfirmDelete(session.sessionId)}
              onCancel={handleCancelDelete}
            />
          )} */}
        </div>
      </div>
    </>
  );
};

export default SessionBlock;
