import React from "react";
import Tag from "components/Tag";

function TeamDetailsView({ companyData }) {
  return (
    <div className="flex flex-col gap-[20px]">
      <div>
        <p className="font-semibold text-sm text-slate-700 tracking-tight">
          Description
        </p>
        <p className="text-sm mt-2">{companyData?.blurb || "Not Submitted"}</p>
      </div>

      <div>
        <p className="font-semibold text-sm text-slate-700 tracking-tight">
          Company Size
        </p>

        <p className="text-sm mt-2">
          {companyData?.companySize || "Not Submitted"}
        </p>
      </div>

      <div>
        <p className="font-semibold text-sm text-slate-700 tracking-tight">
          Year Founded
        </p>

        <p className="text-sm mt-2">
          {companyData?.foundedDate || "Not Submitted"}
        </p>
      </div>

      <div>
        <p className="font-semibold text-sm text-slate-700 tracking-tight">
          Funding Status
        </p>

        <p className="text-sm mt-2">
          {companyData?.fundingStatus || "Not Submitted"}
        </p>
      </div>

      <div>
        <p className="font-semibold text-sm text-slate-700 tracking-tight">
          Funding Type
        </p>

        <p className="text-sm mt-2">
          {companyData?.fundingType || "Not Submitted"}
        </p>
      </div>

      <div>
        <p className="font-semibold text-sm text-slate-700 tracking-tight">
          Homepage URL
        </p>

        <p className="text-sm mt-2">
          {companyData?.homepageUrl || "Not Submitted"}
        </p>
      </div>

      <div>
        <p className="font-semibold text-sm text-slate-700 tracking-tight">
          HQ Location
        </p>

        <p className="text-sm mt-2">
          {companyData?.hqLocation || "Not Submitted"}
        </p>
      </div>

      <div>
        <p className="font-semibold text-sm text-slate-700 tracking-tight">
          Industries
        </p>

        <div className="flex gap-2 mt-2">
          {companyData?.industry?.map((txt) => <Tag text={txt} />) || (
            <p>Not Submitted</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default TeamDetailsView;
