import React from "react";

function InputModal({ children, size = "" }) {
  // Define size options and their corresponding pixel values
  const sizeOptions = {
    sm: "w-[450px]",
    md: "w-[600px]",
    lg: "w-[800px]",
  };

  // Determine the width based on the size prop or use the default size
  const width = sizeOptions[size] || sizeOptions.md;

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-[#0e56ffb4] flex items-center justify-center z-[1000] overflow-y-auto">
      <div className="absolute top-0 left-1/2 transform -translate-x-1/2 mt-[100px]">
        <div className={`${width} p-[30px] bg-white rounded-[30px] mb-[100px]`}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default InputModal;
