import React, { useState } from "react";
import { makeApiRequest } from "constants";
import { apiRoutes } from "constants";
import { useForm } from "react-hook-form";
import PrimaryActionButton from "components/Buttons/PrimaryActionButton";

function ContactSection(props) {
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    console.log(data);
    // Show pending indicator
    setPending(true);

    // Send a request to the client.contact API endpoint
    await makeApiRequest("post", apiRoutes.contact, null, null, data)
      .then(() => {
        // Clear form
        reset();
        // Show success alert message
        setFormAlert({
          type: "success",
          message: "Your message has been sent!",
        });
      })
      .catch((error) => {
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  return (
    <div className="container mx-auto max-w-2xl">
      {formAlert && (
        <div
          className={
            "mb-4" +
            (formAlert.type === "error" ? " text-red-600" : "") +
            (formAlert.type === "success" ? " text-green-600" : "")
          }
        >
          {formAlert.message}
        </div>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-3">
          <input
            className="py-1 px-3 w-full leading-8 bg-white rounded-xl border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
            {...register("name", {
              required: "Please enter your name",
            })}
            type="text"
            placeholder="What's your name?"
          />
          {errors.company && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.company.message}
            </p>
          )}
        </div>
        <div className="mt-3">
          <input
            className="py-1 px-3 w-full leading-8 bg-white rounded-xl border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
            {...register("email", {
              required: "Please enter your email",
            })}
            type="email"
            placeholder="Email"
          />

          {errors.email && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.email.message}
            </p>
          )}
        </div>
        <div className="mt-3">
          <input
            className="py-1 px-3 w-full leading-8 bg-white rounded-xl border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
            {...register("company", {
              required: "Please enter your company name",
            })}
            type="text"
            placeholder="Company"
          />
          {errors.company && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.company.message}
            </p>
          )}
        </div>
        <div className="mt-3">
          <textarea
            className="py-1 px-3 w-full leading-8 bg-white rounded-xl border border-gray-300 outline-none focus:border-blue-500 focus:ring-1"
            {...register("message", {
              required: "Please enter a message",
            })}
            placeholder="Message"
          />

          {errors.message && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.message.message}
            </p>
          )}
        </div>
        <div className="mt-2">
          <PrimaryActionButton
            text={pending ? "..." : "Send message"}
            buttonType="submit"
            disabled={pending}
          />
        </div>
      </form>
    </div>
  );
}

export default ContactSection;
