import React from "react";

import { ReactComponent as ArrowDown } from "assets/icons/arrow-narrow-down.svg";
import { ReactComponent as PendingCheck } from "assets/icons/badge-check-line.svg";
import { ReactComponent as ArrowDownArrowUp } from "assets/icons/arrow-down-arrow-up.svg";
import { ReactComponent as ArrowUp } from "assets/icons/arrow-narrow-up.svg";
import StyledSelect from "components/StyledSelect";

const templateTypeOptions = [
  { value: "Company Templates", label: "Company Templates" },
  { value: "Global Templates", label: "Global Templates" },
];

function SortRolesHeader({
  sortField,
  setSortField,
  sortAscending,
  setSortAscending,
  searchText,
  setSearchText,
  isTemplateHeader = false,
}) {
  return (
    <>
      <div className="mt-[20px] py-5 pl-3 pr-5 rounded-lg block-shadow text-sm tracking-tight font-medium items-center grid grid-cols-[2fr_1fr_1fr_1fr_1fr] bg-white">
        {/* col 1 */}

        <div className="pl-[5px]">
          {!isTemplateHeader ? (
            <button
              className={`${
                sortField === "isPublished" ? "text-[#3371ff]" : ""
              } flex items-center gap-[5px]`}
              onClick={() => {
                if (sortField === "isPublished") {
                  setSortAscending(!sortAscending);
                } else {
                  setSortField("isPublished");
                  setSortAscending(true);
                }
              }}
            >
              <PendingCheck
                className={`${
                  sortField === "isPublished"
                    ? "stroke-[#3371ff]"
                    : "stroke-slate-900"
                }`}
              />

              <p>Published</p>
              {sortField === "isPublished" ? (
                sortAscending ? (
                  <ArrowUp className="stroke-[#3371ff] h-4 w-4" />
                ) : (
                  <ArrowDown className="stroke-[#3371ff] h-4 w-4" />
                )
              ) : (
                <ArrowDownArrowUp className="stroke-slate-900 h-4 w-4" />
              )}
            </button>
          ) : (
            <button
              className={`${
                sortField === "title" ? "text-[#3371ff]" : ""
              } flex items-center gap-[5px]`}
              onClick={() => {
                if (sortField === "title") {
                  setSortAscending(!sortAscending);
                } else {
                  setSortField("title");
                  setSortAscending(true);
                }
              }}
            >
              <p>Template Name</p>
              {sortField === "title" ? (
                sortAscending ? (
                  <ArrowUp className="stroke-[#3371ff] h-4 w-4" />
                ) : (
                  <ArrowDown className="stroke-[#3371ff] h-4 w-4" />
                )
              ) : (
                <ArrowDownArrowUp className="stroke-slate-900 h-4 w-4" />
              )}
            </button>
          )}
        </div>

        {/* col 2 */}
        <div>
          <button
            className={`${
              sortField === "department" ? "text-[#3371ff]" : ""
            } flex items-center gap-[5px]`}
            onClick={() => {
              if (sortField === "department") {
                setSortAscending(!sortAscending);
              } else {
                setSortField("department");
                setSortAscending(true);
              }
            }}
          >
            <p>Department</p>
            {sortField === "department" ? (
              sortAscending ? (
                <ArrowUp className="stroke-[#3371ff] h-4 w-4" />
              ) : (
                <ArrowDown className="stroke-[#3371ff] h-4 w-4" />
              )
            ) : (
              <ArrowDownArrowUp className="stroke-slate-900 h-4 w-4" />
            )}
          </button>
        </div>

        {/* col 3 */}
        <div>
          <button
            className={`${
              sortField === "location" ? "text-[#3371ff]" : ""
            } flex items-center gap-[5px]`}
            onClick={() => {
              if (sortField === "location") {
                setSortAscending(!sortAscending);
              } else {
                setSortField("location");
                setSortAscending(true);
              }
            }}
          >
            <p>Location</p>
            {sortField === "location" ? (
              sortAscending ? (
                <ArrowUp className="stroke-[#3371ff] h-4 w-4" />
              ) : (
                <ArrowDown className="stroke-[#3371ff] h-4 w-4" />
              )
            ) : (
              <ArrowDownArrowUp className="stroke-slate-900 h-4 w-4" />
            )}
          </button>
        </div>

        {/* col 4 - sort by "Created By" if template, "CtaType" otherwise*/}
        <div>
          {isTemplateHeader ? (
            <button
              className={`${
                sortField === "postingOwner" ? "text-[#3371ff]" : ""
              } flex items-center gap-[5px]`}
              onClick={() => {
                if (sortField === "postingOwner") {
                  setSortAscending(!sortAscending);
                } else {
                  setSortField("postingOwner");
                  setSortAscending(true);
                }
              }}
            >
              <p>Created By</p>
              {sortField === "postingOwner" ? (
                sortAscending ? (
                  <ArrowUp className="stroke-[#3371ff] h-4 w-4" />
                ) : (
                  <ArrowDown className="stroke-[#3371ff] h-4 w-4" />
                )
              ) : (
                <ArrowDownArrowUp className="stroke-slate-900 h-4 w-4" />
              )}
            </button>
          ) : (
            <button
              className={`${
                sortField === "ctaType" ? "text-[#3371ff]" : ""
              } flex items-center gap-[5px]`}
              onClick={() => {
                if (sortField === "ctaType") {
                  setSortAscending(!sortAscending);
                } else {
                  setSortField("ctaType");
                  setSortAscending(true);
                }
              }}
            >
              <p>Call to action</p>
              {sortField === "ctaType" ? (
                sortAscending ? (
                  <ArrowUp className="stroke-[#3371ff] h-4 w-4" />
                ) : (
                  <ArrowDown className="stroke-[#3371ff] h-4 w-4" />
                )
              ) : (
                <ArrowDownArrowUp className="stroke-slate-900 h-4 w-4" />
              )}
            </button>
          )}
        </div>

        {/* searchbar*/}
        <div className="flex items-center gap-[15px] justify-end">
          <input
            className="text-sm w-full py-2 px-4 block-shadow rounded-lg placeholder:text-slate-700 tracking-tight border border-solid border-slate-900"
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            label="searchText"
            id="searchText"
            placeholder="Search by title"
          />
        </div>
      </div>
    </>
  );
}

export default SortRolesHeader;
