import { apiRoutes, makeApiRequest } from "constants/apiRoutes";

//
// Activity is added to the session object
// Sessions are created by the sendSessionData function
// This function sends PUT requests to add activity to
// the current session object.
//

const sendActivityData = async (analyticsData, sessionId) => {
  if (process.env.NODE_ENV === "development") {
    console.log(`${sessionId}: ${analyticsData}`);
  } else {
    try {
      const response = await makeApiRequest(
        "put",
        apiRoutes.addSessionActivity(sessionId, analyticsData.activity),
        undefined,
        undefined,
        analyticsData
      );
      console.log(response);
    } catch (error) {
      console.error("Error sending page view data:", error);
    }
  }
};

export default sendActivityData;
