import { apiRoutes, makeApiRequest } from "constants/apiRoutes";
import { useEffect, useState } from "react";

//
// This hook is used to get Session Data for the Analytics page.
//

const useGetSessionData = (companyId, user, roleId, startDate, endDate) => {
  console.log(roleId);
  const [isLoading, setIsLoading] = useState(false);
  const [sessionData, setSessionData] = useState([]);

  const extractValues = (data) => {
    return data.map((item) => item.value);
  };

  const buildQueryString = (params) => {
    const queryString = Object.entries(params)
      .filter(([key, value]) => value !== null && value !== undefined)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return queryString ? `?${queryString}` : "";
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      let extractedRoleId = extractValues(roleId);
      const userId = user.id;
      const queryString = buildQueryString({
        companyId,
        userId,
        roleId: extractedRoleId,
        startDate,
        endDate,
      });

      const url = `${apiRoutes.getSessionData}${queryString}`;
      const response = await makeApiRequest("get", url, user, companyId);

      if (response.status !== 200) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }

      setSessionData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [companyId, roleId, startDate, endDate]);

  return {
    sessionData,
    isLoading,
  };
};

export default useGetSessionData;
