// TEMPORARY DEPRECATION
//
//
// We're not using this component for the time being.
// We will offer "signin with Google" eventually, but
// right now it's not worth the time. Let's get the flow
// down and offer that as a fast follow.
//
//
// – Mick

import React, { useState } from "react";
import { useAuth } from "../../util";

function AuthSocial(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(null);

  const providerDisplayNames = {
    google: "Google",
    //outlook: "Outlook",
  };

  const onSigninWithProvider = (provider) => {
    setPending(provider);
    auth
      .signinWithProvider(provider)
      .then((user) => {
        // Remember this provider was last used
        // so we can indicate for next login.
        localStorage.setItem("lastUsedAuthProvider", provider);
        props.onAuth(user);
      })
      .catch((error) => {
        setPending(null);
        props.onError(error.message);
      });
  };

  return (
    <div className="mt-2">
      {props.providers.map((provider) => (
        <div className="mb-2" key={provider}>
          <button
            className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            onClick={() => {
              onSigninWithProvider(provider);
            }}
            disabled={pending === provider}
          >
            {pending === provider && <>...</>}

            {pending !== provider && (
              <>
                {props.buttonAction} with {providerDisplayNames[provider]}
              </>
            )}
          </button>
        </div>
      ))}
    </div>
  );
}

export default AuthSocial;
