import React from "react";
import { requireAuth } from "util";
import { useAuth } from "util";
import Spinner from "components/Spinner";
import LandingPage from "pages/LandingPage/LandingPage";

function PreviewPage() {
  const auth = useAuth();

  if (!auth.user) {
    return (
      <div className="p-10 text-center">
        <Spinner size="25px" />
      </div>
    );
  }
  return (
    <>
      <LandingPage previewMode={true} user={auth.user} />
    </>
  );
}

export default requireAuth(PreviewPage);
