// HOOKS + REACT
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useSessionData from "hooks/useSessionData";
import useWindowDimensions from "hooks/useWindowDimensions";
import useGetPage from "hooks/useGetPage";
import useVisitorId from "hooks/useVisitorId";
import useSessionId from "hooks/useSessionId";
import { trackTimeOnPage } from "./Utilities/trackTimeOnPage";
import BuiltUsingHiringSpree from "components/BuiltUsingHiringSpree";

// COMPONENTS
import RoleHeader from "pages/LandingPage/Components/RoleHeader";
import InfoCard from "./Components/InfoCards/InfoCard";
import LandingPageBlocks from "./Components/LandingPageBlocks";
import LinksBlock from "pages/LandingPage/Components/LinksBlock.js";
import MobileInfoCard from "./Components/InfoCards/MobileInfoCard";
import MobileModal from "components/MobileModal";
import Meta from "components/Meta";
import Spinner from "components/Spinner";
import PreviewBanner from "pages/Account/Home/PreviewBanner";

// UTILS
import { sendSessionData } from "./Utilities/sendSessionData";

function LandingPage({ previewMode = false, user = undefined }) {
  const [showCompanyInfoModal, setShowCompanyInfoModal] = useState(false);
  const [showLinksModal, setShowLinksModal] = useState(false);
  const { companyId, roleId } = useParams();
  const isMobileScreen = useWindowDimensions();
  const sessionData = useSessionData();
  const { landingPageData, isLoaded } = useGetPage(
    roleId,
    previewMode,
    user,
    companyId
  );
  const visitorId = useVisitorId();
  const { sessionId, isNewSession } = useSessionId();
  console.log("Landing Page is Rendered: " + isLoaded);

  useEffect(() => {
    const initializeTracking = async () => {
      if (!isLoaded) {
        console.log("Component not loaded yet");
        return;
      }

      console.log("Component loaded, sending session data...");
      if (isNewSession) {
        await sendSessionData(
          roleId,
          companyId,
          sessionData,
          previewMode,
          visitorId,
          sessionId,
          landingPageData.pageData.title,
          landingPageData.pageData.userId
        );
      }

      // Initialize the time tracking only once
      await trackTimeOnPage(sessionId, previewMode);
    };

    initializeTracking();
  }, [isLoaded]);

  if (!isLoaded) {
    return (
      <div className="p-10 text-center">
        <Spinner size="25px" />
      </div>
    );
  }

  return (
    <div className="w-full dark:bg-slate-900">
      <Meta
        title={`${landingPageData.pageData.title} @ ${landingPageData.companyData.companyName}`}
        description={`${landingPageData.companyData.companyName} is hiring for a ${landingPageData.pageData.title}`}
        type="article"
      />

      <RoleHeader
        roleDetails={landingPageData.pageData}
        companyData={landingPageData.companyData}
      />

      <div
        id="feed"
        className={`m-auto flex gap-0 sm:gap-8 pt-8 ${
          isMobileScreen ? "h-full px-[20px]" : "h-[calc(100vh-96px)] px-8"
        } overflow-scroll`}
      >
        {/* First column */}
        <div className="sticky sm:top-0 bottom-0">
          <div className="hidden sm:block">
            <InfoCard
              companyData={landingPageData.companyData}
              userData={landingPageData.userData}
              isMobileModal={showCompanyInfoModal}
            />
          </div>
        </div>

        {/* Second column */}
        <div className={`flex-grow sm:mt-0 ${isMobileScreen ? null : "mt-5"}`}>
          <div className="flex gap-0 lg:gap-8">
            {/* Second column of grid2 */}
            <div
              className={`flex flex-col ${
                isMobileScreen ? "gap-y-7" : "gap-y-10"
              } pb-[calc(15vh)] flex-grow`}
            >
              {isMobileScreen ? (
                <div className="w-auto">
                  <MobileInfoCard
                    companyData={landingPageData.companyData}
                    links={landingPageData.pageData.links}
                    setShowCompanyInfoModal={setShowCompanyInfoModal}
                    setShowLinksModal={setShowLinksModal}
                  />
                </div>
              ) : null}
              <LandingPageBlocks
                contentFeed={landingPageData.pageData.jobFeed?.sort(
                  (a, b) => a.order - b.order
                )}
                companyData={landingPageData.companyData}
              />

              <BuiltUsingHiringSpree />
            </div>
            <div className="hidden lg:block">
              <LinksBlock
                data={landingPageData.pageData.links}
                homepageUrl={landingPageData.companyData.homepageUrl}
              />
            </div>
          </div>
        </div>
      </div>
      {showCompanyInfoModal ? (
        <MobileModal
          closeModal={() => setShowCompanyInfoModal(false)}
          size="md"
          bgColor="[#f3f7ff]"
          bgColorDark="slate-800"
        >
          <InfoCard
            companyData={landingPageData.companyData}
            userData={landingPageData.userData}
            isMobileModal={showCompanyInfoModal}
          />
        </MobileModal>
      ) : null}
      {showLinksModal ? (
        <MobileModal
          closeModal={() => setShowLinksModal(false)}
          size="md"
          bgColor="[#f3f7ff]"
          bgColorDark="slate-800"
        >
          <LinksBlock
            data={landingPageData.pageData.links}
            homepageUrl={landingPageData.companyData.homepageUrl}
            isMobileModal
          />
        </MobileModal>
      ) : null}
      {previewMode ? (
        <>
          <PreviewBanner
            roleId={roleId}
            authenticatedUser={user.id}
            createdByUser={landingPageData.pageData.userId}
            postingOwner={landingPageData.pageData.postingOwner}
            isTemplateRole={landingPageData.pageData.isTemplate}
          />
        </>
      ) : null}
    </div>
  );
}

export default LandingPage;
