import React, { useState } from "react";
import { makeApiRequest, apiRoutes } from "constants";
import AddToSlack from "./AddToSlack";
import VerifiedFlag from "./VerifiedStatus";
import { getFriendlyPlanId } from "util";
import { toTitleCase } from "util";
import Billing from "./Billing";
import { ReactComponent as UserCheck } from "assets/icons/user-check.svg";
import { ReactComponent as Lock } from "assets/icons/lock.svg";
import SettingsGeneral from "./SettingsGeneral";
import SettingsPassword from "./SettingsPassword";
import ReauthModal from "components/Auth/ReauthModal";
import Modal from "components/Modal";

function User({ user, company }) {
  const [formAlert, setFormAlert] = useState(null);
  const [currentSection, setCurrentSection] = useState("general");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [reauthState, setReauthState] = useState({
    show: false,
  });

  const handleSaveImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("image", file);
      const response = await makeApiRequest(
        "post",
        apiRoutes.profilePicture(user.id),
        user,
        user.companyId,
        formData
      );
      if (response.status === 201) {
        console.log("Profile picture changed");
      }
    } catch (error) {
      console.error(error, "error");
    }
  };

  function onError(e) {
    e.target.src =
      "https://hs-icons.s3.us-east-2.amazonaws.com/hs_logo_blue.png";
  }

  const handleStatus = ({ type, message, callback }) => {
    if (type === "requires-recent-login") {
      setFormAlert(null);
      setReauthState({
        show: true,
        callback: callback,
      });
    } else {
      setFormAlert({
        type: type,
        message: message,
      });
    }
  };

  const openModal = (section) => {
    setCurrentSection(section);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {reauthState.show && (
        <ReauthModal
          callback={reauthState.callback}
          provider={user.providers[0]}
          onDone={() => setReauthState({ show: false })}
        />
      )}
      {/* {formAlert && (
        <div
          className={
            "mb-4" +
            (formAlert.type === "error" ? " text-red-600" : "") +
            (formAlert.type === "success" ? " text-green-600" : "")
          }
        >
          {formAlert.message}
        </div>
      )} */}
      {isModalOpen && (
        <>
          {currentSection === "general" && (
            <Modal title="Edit Profile" closeModal={closeModal} size="sm">
              <SettingsGeneral
                onStatus={handleStatus}
                closeModal={closeModal}
              />
            </Modal>
          )}
          {currentSection === "password" && (
            <Modal title="Change Password" closeModal={closeModal} size="sm">
              <SettingsPassword
                onStatus={handleStatus}
                closeModal={closeModal}
              />
            </Modal>
          )}
        </>
      )}
      <div className="py-[40px] px-[75px]">
        <h1 className="font-bold tracking-tight text-slate-900 text-[32px]">
          Profile Settings
        </h1>
        <p className="text-sm mt-2">
          Manage your profile, and update billing information here.
        </p>
        <div className="mt-[60px] flex gap-2 justify-between">
          <div className="inline-flex gap-2">
            <button
              onClick={() => openModal("general")}
              className="text-slate-900 border-slate-900 border border-solid bg-white block-shadow font-medium text-[15px] pl-3 pr-4 py-2.5 rounded-lg flex flex-row gap-2 items-center"
            >
              <UserCheck className="stroke-slate-900" />
              <p>Edit Profile</p>
            </button>
            <button
              onClick={() => openModal("password")}
              className="text-slate-900 border-slate-900 border border-solid bg-white block-shadow font-medium text-[15px] pl-3 pr-4 py-2.5 rounded-lg flex flex-row gap-2 items-center"
            >
              <Lock className="stroke-slate-900" />
              <p>Change Password</p>
            </button>
            <Billing />
          </div>
          {user.featureFlag === "beta" && <AddToSlack />}
        </div>
        <div className="mt-[20px] bg-white rounded-lg block-shadow rounded-lg p-[30px]">
          <dl className="divide-y divide-gray-500">
            <div className="px-4 pb-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Full name
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {user.name}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Profile Picture
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <div className="flex items-center gap-4">
                  <img
                    className="h-[70px] w-[70px] rounded-full bg-white block-shadow"
                    src={user.userImage}
                    onError={(e) => onError(e)}
                    alt="logo"
                  />
                  <div className="flex flex-col mr-2">
                    <label
                      htmlFor="logo-upload"
                      className="text-sm cursor-pointer text-[#3371ff]"
                    >
                      Change Picture
                    </label>
                    <input
                      id="logo-upload"
                      type="file"
                      className="hidden"
                      onChange={(e) => handleSaveImage(e.target.files[0])}
                    />
                  </div>
                </div>
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Team
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <div>
                  <div className="inline-flex gap-4 items-center">
                    <img
                      className="h-[70px] w-[70px] rounded-full bg-white block-shadow"
                      src={company.companyLogoUrl}
                      onError={(e) => onError(e)}
                      alt="logo"
                    />
                    <p>{company.companyName}</p>
                  </div>
                </div>
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Permissions Level
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {user.userRole}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Job Title
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {user.userTitle}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Email address
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {user.emailVerified ? "✅" : "⏳"} {user.email}
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Verification Status
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <VerifiedFlag status={user.isVerified} />
              </dd>
            </div>

            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Subscription Type
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {user.stripeSubscriptionStatus === "active" ? "✅" : "⏳"}{" "}
                {toTitleCase(getFriendlyPlanId(user.stripePriceId))} Plan
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
}

export default User;
