import React, { useEffect, useState } from "react";
import { requireAuth } from "util/auth";
import { useAuth } from "util/auth";
import { makeApiRequest, apiRoutes } from "constants";
import Meta from "components/Meta";
import { useCompany } from "util";
import { ReactComponent as Link } from "assets/icons/link-alt-1.1.svg";
import useGetSessionData from "hooks/useGetSessionData";
import SessionBlock from "./SessionBlock";
import {
  countDeviceTypes,
  countUniqueVisitors,
  calculateTotalTimeOnPage,
  calculateAverageTimeOnPage,
  secondsToHoursMinutesAndSeconds,
  countActivities,
} from "util/sessions";
import ExportToCSVButton from "./CSVExport";
import UserDistribution from "./UserDistribution";
import SessionCounts from "./SessionCounts";
import ActiveTime from "./ActiveTime";
import TimeSeriesChart from "./TimeSeriesChart";
import DateRangePicker from "components/DateRangePicker";
import MultiSelect from "components/MultiSelect";

function Analytics() {
  const auth = useAuth();
  const [userPages, setUserPages] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const { companyData } = useCompany();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { sessionData, isLoading } = useGetSessionData(
    companyData.companyId,
    auth.user,
    selectedRoles,
    startDate,
    endDate
  );

  const { desktopCount, mobileCount } = countDeviceTypes(sessionData);
  const uniqueVisitors = countUniqueVisitors(sessionData);
  const totalTimeOnPage = calculateTotalTimeOnPage(sessionData);
  const averageTimeOnPage = calculateAverageTimeOnPage(sessionData);
  const activities = countActivities(sessionData);

  const handleDateChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await makeApiRequest(
        "get",
        apiRoutes.getPagesByUserID(auth.user.id),
        auth.user,
        companyData.companyId
      );

      const processResponseData = (data) => {
        return data.map((item) => ({
          value: item._id,
          label: item.title,
        }));
      };

      const formattedData = processResponseData(response.data);
      setUserPages(formattedData);
    };
    fetchData();
  }, []);

  return (
    <>
      <Meta title="Analytics" />
      <div className="top-0 sticky pt-[40px] z-40 bg-[#f6f9ff] px-[75px] pb-[50px]">
        <h1 className="font-bold tracking-tight text-slate-900 text-[32px]">
          Analytics
        </h1>
        <p className="mt-2 text-sm">Session Data</p>

        <div className="mt-[60px]">
          <div className="flex justify-between ">
            <div className="flex flex-row gap-2 whitespace-nowrap text-sm tracking-tight font-medium">
              <MultiSelect
                options={userPages}
                selectedOptions={selectedRoles}
                onChange={setSelectedRoles}
                placeholder="Filter by Page"
              />
              <DateRangePicker onDateChange={handleDateChange} />
            </div>
            <div className="flex gap-[10px]">
              <ExportToCSVButton sessionData={sessionData} />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-[20px] mt-[40px]">
            <SessionCounts
              totalSessions={sessionData.length}
              uniqueVisitors={uniqueVisitors}
            />
            <UserDistribution
              desktopCount={desktopCount}
              mobileCount={mobileCount}
            />
            <ActiveTime
              totalActiveTime={secondsToHoursMinutesAndSeconds(totalTimeOnPage)}
              averageActiveTime={secondsToHoursMinutesAndSeconds(
                averageTimeOnPage
              )}
            />
          </div>
          <div className="grid grid-cols-[2fr_1fr] gap-[20px] mt-[40px]">
            <TimeSeriesChart sessionData={sessionData} />
            <div>
              <h2 className="text-lg font-semibold mb-2 text-slate-900">
                Link Activity
              </h2>
              <div className="flex flex-col gap-[20px]">
                {activities.length > 0 ? (
                  activities.map(({ url, link_title, count }) => (
                    <div
                      key={url}
                      className="hs-block bg-white py-4 pl-3 pr-5 text-sm tracking-tight font-medium"
                    >
                      <div className="pl-[5px] flex items-center gap-[15px]">
                        <div className="p-2 bg-blue-100 rounded-md fill-[#3371ff]">
                          <Link />
                        </div>
                        <div>
                          <p>{link_title}</p>
                          <a href={url} className="text-[#3371ff]">
                            Link
                          </a>
                        </div>
                        <div className="ml-auto">
                          <p className="text-[#3371ff] text-lg">
                            {count} click{count > 1 ? "s" : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="hs-block bg-white py-4 pl-3 pr-5 text-sm tracking-tight font-medium">
                    <div className="pl-[5px] flex items-center gap-[15px]">
                      <div className="p-2 bg-blue-100 rounded-md fill-[#3371ff]">
                        <Link />
                      </div>
                      <div>
                        <p>No activity data available</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-[40px]">
            <h2 className="text-lg font-semibold text-slate-900 mb-2">
              All Session Data ({sessionData.length} sessions)
            </h2>
            <div className="flex flex-col gap-[20px]">
              {sessionData.map((session) => (
                <SessionBlock session={session} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default requireAuth(Analytics);
