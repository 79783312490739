import React from "react";
import { ReactComponent as RectanglesMixed } from "assets/icons/rectangles-mixed.svg";

function JobFeedEmpty({ openModal }) {
  return (
    <div className="hs-block bg-white p-[30px]">
      <div className="flex items-center gap-[10px]">
        <RectanglesMixed className="stroke-[#3371ff]" />
        <h2 className="font-semibold tracking-tight text-slate-900 text-lg">
          Creating your feed
        </h2>
      </div>
      <div>
        <p className="text-sm mt-4">
          Use the{" "}
          <span
            className="font-semibold text-[#3371ff] cursor-pointer"
            onClick={openModal}
          >
            Add Block
          </span>{" "}
          button to add content to your feed. Once you've added blocks to your
          page, you can drag and drop blocks to change their order.
        </p>

        <p className="mt-4 text-sm font-semibold">
          Let's add your first block!
        </p>
      </div>
    </div>
  );
}

export default JobFeedEmpty;
