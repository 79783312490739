import React from "react";

function LegalPrivacy(props) {
  return (
    <div className="prose prose-a:text-blue-600 max-w-none">
      <p>
        <strong>Privacy Policy</strong>
      </p>
      <p>
        <strong>Last Updated: May 13, 2024</strong>
      </p>
      <p>
        Thank you for choosing to be part of our community. At hiringspree.io
        and our subsidiary and affiliated sites, we are committed to protecting
        your personal data and respecting your privacy rights. This Privacy
        Policy (“Policy”) explains what personal data is collected and how it is
        used by Hiring Spree LLC and its subsidiaries and affiliates
        (collectively, “Company“, “we”, “us”, or “our”) when you access or use
        the hiringspree.io site as well as any other website, mobile site or
        mobile application related or linked to hiringspree.io (collectively,
        the “Platform”). This Policy and other documents referenced in it apply
        to all personal data collected through our Platform and any related
        services, sales, marketing or events.
      </p>
      <p>
        Personal data is any information relating to an identified or
        identifiable natural person. It includes things like email addresses,
        phone numbers, mailing addresses, payment card information, account
        numbers, and government-issued identification numbers.&nbsp; These are
        just examples and there are many other types of information that would
        be considered personal data.
      </p>
      <p>
        Because we operate globally, we apply local rules regarding the use and
        sale of personal data, as well as the choice to receive communications
        from us and our affiliate companies.&nbsp;
      </p>
      <p>
        We hope you take some time to read through this Policy carefully,
        because it is important. If you have any questions or concerns about our
        collection or use of your information, please contact us at
        privacy@hiringspree.io.
      </p>
      <p>
        <strong>A. WHAT PERSONAL DATA DO WE COLLECT?</strong>
      </p>
      <p>
        <strong>
          <em>1. Personal data you disclose to us</em>
        </strong>
      </p>
      <p>
        We collect personal data that you voluntarily provide to us when
        registering to use the Platform, expressing an interest in obtaining
        information about us or our products and services, when participating in
        activities on the Platform or otherwise contacting us.
      </p>
      <p>
        The personal data that we collect depends on the context of your
        interactions with us and the Platform, the choices you make and the
        products and features you use. The personal data we collect may include
        the following:
      </p>
      <p>
        <strong>Name and Contact Data.</strong>
        We collect your first and last name, email address, mailing address,
        phone number, and other similar contact data.
      </p>
      <p>
        <strong>Credentials.</strong>
        We collect usernames, passwords, password hints, and similar security
        information used for authentication and account access.
      </p>
      <p>
        <strong>Employment, Resume, CV and other Job Related Data.</strong>We
        collect the employment, resume, CV and other job related data that you
        submit to us or provide us access to in connection with the Platform.
      </p>
      <p>
        <strong>Payment Data.</strong>
        We collect data necessary to process your payment if you make purchases,
        such as your payment instrument number (such as a credit card number),
        and the security code associated with your payment instrument. All
        payment data is stored by our third-party payment processor and you
        should review its privacy policies and contact the payment processor
        directly to respond to your questions.
      </p>
      <p>
        <strong>Social Media Login Data.</strong>
        We provide you with the option to register using social media account
        details, like your LinkedIn, Twitter or other social media account. If
        you choose to register in this way, we will collect the Information
        described in the section “HOW DO WE HANDLE YOUR SOCIAL LOGINS.”
      </p>
      <p>
        All personal data that you provide to us must be true, complete and
        accurate, and you must notify us of any changes to such personal data.
      </p>
      <p>
        <strong>
          <em>2. Data automatically collected</em>
        </strong>
      </p>
      <p>
        We automatically collect certain information when you visit, use or
        navigate the Platform. This information does not reveal your specific
        identity (like your name or contact information) but may include device
        and usage information, such as your IP address, browser and device
        characteristics, operating system, language preferences, referring URLs,
        device name, country, location, information about how and when you use
        the Platform and other technical information. This information is
        primarily used to maintain the security and operation of the Platform,
        and for our internal analytics and reporting purposes.
      </p>
      <p>
        Like many businesses, we also collect information through cookies and
        similar technologies.&nbsp; This information may include what URL you
        visited before visiting our Platform, how long you spend on a particular
        page, and how you navigate our Platform. Please refer to our Cookie
        Policy for more information on how we use cookies.
      </p>
      <p>
        <strong>
          <em>3. Data collected from other sources</em>
        </strong>
      </p>
      <p>
        We and our affiliates generally share personal data with each other so
        that we can provide our services to you. We also may obtain information
        about you from other sources, such as public databases, joint marketing
        partners, social media platforms (such as LinkedIn), as well as from
        other third parties.&nbsp;
      </p>
      <p>
        Examples of the information we receive from other sources include:
        social media profile information (your name, gender, birthday, email,
        current city, state and country, user identification numbers for your
        contacts, profile picture URL and any other information that you choose
        to make public); marketing leads and search results and links, including
        paid listings (such as sponsored links).&nbsp;&nbsp;
      </p>
      <p>
        <strong>B. DO WE COLLECT DATA FROM MINORS?</strong>
      </p>
      <p>
        Our Platform is not directed to minors and we do not intend to collect
        personal data from children under the age of 18. Therefore, we recommend
        that parents or legal guardians be involved in the online activities of
        their children in order to prevent the collection of their personal data
        without parental consent. If you believe that we inadvertently have
        collected personal data about your child, please contact us at
        <span>privacy@hiringspree.io</span>.
      </p>
      <p>
        <strong>C. HOW DO WE USE YOUR PERSONAL DATA?</strong>
      </p>
      <p>
        We use personal data collected through our Platform for a variety of
        business purposes described below. We process your personal data for
        these purposes in reliance on and furtherance of our legitimate business
        interests, in order to enter into or perform a contract with you, with
        your consent, and/or for compliance with our legal obligations. We use
        the information we collect or receive:
      </p>
      <ul>
        <li>
          <strong>To facilitate account creation and logon process.</strong>
          We use information you provide to create an account with us.&nbsp; If
          you choose to link your account with us to a third party account (such
          as your Google or LinkedIn account), we use the information you
          allowed us to collect from those third parties to facilitate account
          creation and logon process. See the section below “HOW DO WE HANDLE
          YOUR SOCIAL LOGINS ” for further information.
        </li>
        <li>
          <strong>To send administrative information to you.</strong>
          We may use your personal data to send you information about products
          or services you have purchased and/or information about changes to our
          terms, conditions, and policies.
        </li>
        <li>
          <strong>To fulfill and manage your orders for services.</strong>
          We may use your information to fulfill and manage your orders for
          services, subscriptions, and payments, and to address any issues with
          services provided through the Platform.
        </li>
        <li>
          <strong>To post testimonials.</strong>
          We post testimonials on the Platform that may contain personal data.
          Prior to posting a testimonial, we will obtain your consent to use
          your name and testimonial. If you wish to update, or delete your
          testimonial, please contact us at
          <span>privacy@hiringspree.io</span>
          and be sure to include your name, testimonial location, and contact
          information.
        </li>
        <li>
          <strong>To send you marketing and promotional communications.</strong>
          We and/or our third-party marketing partners may use your personal
          data for our marketing purposes, depending on your marketing
          preferences. These communications may include information about new
          products, services, or features. You can opt-out of our marketing
          emails at any time (see the section “WHAT ARE YOUR PRIVACY RIGHTS”
          below).
        </li>
        <li>
          <strong>To deliver targeted advertising to you.</strong>
          We may use your information to develop and display content and
          advertising (and work with third parties who do so) tailored to your
          interests and/or location and to measure its effectiveness.
        </li>
        <li>
          <strong>To request feedback.</strong>
          We may use your information to request feedback and to contact you
          about your use of our Platform so that we can improve our products and
          services and develop additional products and services to better serve
          our users.
        </li>
        <li>
          <strong>To develop our products and services.</strong>&nbsp; We may
          use your information to further develop our existing products and
          services or to develop new products and services.
        </li>
        <li>
          <strong>To protect the Platform, us, and others.</strong>
          We may use your information as part of our efforts to keep the
          Platform safe and secure (for example, for fraud monitoring and
          prevention) and to protect others from harm or abuse.
        </li>
        <li>
          <strong>To enforce our terms, conditions and policies.</strong>&nbsp;
          We may use your information to ensure you and others are complying
          with our terms, conditions and policies, including providing your
          information to law enforcement if we believe it is appropriate to do
          so and using your information in connection with legal proceedings
          brought by us or against us.
        </li>
        <li>
          <strong>
            To respond to legal requests and comply with applicable law.
          </strong>
          If we receive a subpoena, discovery request, or other legal request,
          we may need to review and evaluate your information and potentially
          provide your information in response to the request.&nbsp; We may also
          need to use your information as needed to comply with applicable laws
          and regulations.
        </li>
        <li>
          <strong>For other business purposes.</strong>
          We may use your information for other business purposes, such as
          without limitation data analysis, identifying usage trends,
          determining the effectiveness of our promotional campaigns, and to
          evaluate and improve the Platform, products, services, and your
          experience.
        </li>
      </ul>
      <p>
        <strong>D. WILL YOUR PERSONAL DATA BE SHARED?</strong>
      </p>
      <p>
        Yes, your personal will be shared between Hiring Spree LLC and its
        affiliates and subsidiaries.&nbsp; In addition, we may share your
        personal data with the following categories of third-party service
        providers and marketing partners for one or more of the purposes
        described above.
      </p>
      <ul>
        <li>Advertising, direct marketing, and lead generation providers</li>
        <li>Chat service providers</li>
        <li>Content optimization service providers</li>
        <li>Invoice and billing service providers</li>
        <li>Social media companies to which you enable access</li>
        <li>Social media site advertising</li>
        <li>
          Software service providers that parse and match resumes with job
          postings
        </li>
        <li>Targeted advertising platforms</li>
        <li>Website and application analytics providers</li>
        <li>Website hosting providers</li>
        <li>Website testing and optimization service providers</li>
      </ul>
      <p>
        In addition, where we believe it necessary or appropriate to do so, we
        may share your information with: law enforcement; government agencies or
        regulators; courts; parties to legal proceedings in which a subpoena or
        legal request has been propounded to which such information would be
        responsive. Finally, we may share or transfer your information in
        connection with, or during negotiations of, any merger, sale of company
        assets, financing, or acquisition of all or a portion of our business to
        another company.
      </p>
      <p>
        <strong>E. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</strong>
      </p>
      <p>
        We may use cookies and similar tracking technologies (like web beacons
        and pixels) to collect or store information. When you visit our
        Platform, we may collect information from you automatically through
        cookies or similar technologies. Specific information about how we use
        such technologies is set out in our Cookie Policy. For more information,
        visit allaboutcookies.org.
      </p>
      <p>
        <strong>F. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</strong>
      </p>
      <p>
        The Platform, one of our websites or mobile applications may offer you
        the ability to register and login using your third party social media
        account details (like your LinkedIn or Twitter logins). If you choose to
        do this, we will receive certain profile information about you from the
        social media company. The profile information we receive may vary
        depending on the social media provider, but will often include your
        name, email address, friends list, profile picture as well as other
        information you choose to make public.
      </p>
      <p>
        We will use the information we receive for the purposes that are
        described in this Policy. For more information about your rights with
        respect to this information, please see the section “WHAT ARE YOUR
        PRIVACY RIGHTS?” below.
      </p>
      <p>
        Please note that we do not control, and are not responsible for, other
        uses of your personal data by the social media company. We recommend
        that you review their privacy policy to understand how they collect, use
        and share your personal data, and how you can set your privacy
        preferences on their sites and applications.
      </p>
      <p>
        <strong>G. HOW LONG DO WE KEEP YOUR INFORMATION?</strong>
      </p>
      <p>
        We will only keep your personal data for as long as it is necessary for
        the purposes set out in this Policy, unless a longer retention period is
        required or permitted by law (such as tax, accounting or other legal
        requirements).&nbsp;&nbsp;
      </p>
      <p>
        <strong>H. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong>
      </p>
      <p>
        We have implemented appropriate technical and organizational security
        measures designed to protect the security of any personal data we
        collect, receive, use, or store. However, please also remember that we
        cannot guarantee that the internet itself is 100% secure. Although we
        will do our best to protect your personal data, transmission of personal
        data to and from the Platform is at your own risk. You should only
        access the Platform within a secure environment.&nbsp; If you believe
        your account has been compromised or you suspect any type of data
        breach, please contact us at privacy@hiringspree.io.
      </p>
      <p>
        <strong>I. WHAT ARE YOUR PRIVACY RIGHTS?</strong>
      </p>
      <p>
        The rights described in this section apply to all users. As noted above,
        if you reside in a particular state or country, you may have additional
        rights as required by applicable law.&nbsp;
      </p>
      <p>
        <strong>
          <em>Account Information</em>
        </strong>
      </p>
      <p>
        If you would at any time like to review or change the information in
        your account to make sure it is accurate and up-to-date, please log into
        your user account and update the information as needed.
      </p>
      <p>
        If you terminate your subscription or account with us, then we will
        deactivate your account. However, information will be retained in our
        files to prevent fraud, troubleshoot problems, assist with any
        investigations, enhance the Platform, enforce our Terms of Use, or
        comply with applicable legal requirements.
      </p>
      <p>
        <strong>
          <em>Opt Out of Email Marketing:</em>
        </strong>
      </p>
      <p>
        You can unsubscribe from our marketing email list at any time by
        clicking on the unsubscribe link in the emails that we send. Even if you
        opt out of marketing emails, we may still send you service-related
        emails that are necessary for the administration and use of your
        account.
      </p>
      <p>
        <strong>J. DO WE MAKE UPDATES TO THIS POLICY?</strong>
      </p>
      <p>
        We may update this Policy from time to time. The updated version will be
        indicated by the “Last Updated” date and the updated version will be
        effective as soon as it is accessible. We encourage you to review the
        posted Policy frequently to stay informed about how your information is
        being used.
      </p>
      <p>
        <strong>K. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</strong>
      </p>
      <p>
        If you have questions or comments about this Policy, you may contact us
        by email at
        <a target="_blank" href="mailto:privacy@hiringspree.io">
          <span>privacy@hiringspree.io</span>
        </a>
        .
      </p>
      <div>
        <table>
          <tbody>
            <tr>
              <td>Category</td>
              <td>Types of Information Collected</td>
              <td>Purpose for Collection</td>
            </tr>
            <tr>
              <td>
                <strong>A. Identifiers.</strong>
              </td>
              <td>
                Name, User Agent, Internet Protocol (IP) address, email address.
              </td>
              <td>
                - Account registration&nbsp;- Fulfilling customer orders for our
                services- Marketing our services- Customer service
              </td>
            </tr>
            <tr>
              <td>
                <strong>
                  B. Personal information categories listed in the California
                  Customer Records statute (Cal. Civ. Code § 1798.80(e)).
                </strong>
              </td>
              <td>
                Name, education, employment, employment history, credit card
                number, debit card number.(Some personal information included in
                this category may overlap with other categories.)
              </td>
              <td>
                - Account registration&nbsp;- Fulfilling customer orders for our
                services- Marketing our services- Customer service
              </td>
            </tr>
            <tr>
              <td>
                <strong>C. Internet or other similar network activity.</strong>
              </td>
              <td>User Agent, Internet Protocol (IP) address&nbsp;</td>
              <td>
                - Account registration&nbsp;- Fulfilling customer orders for our
                services- Web site optimization- Marketing our services
              </td>
            </tr>
            <tr>
              <td>
                <strong>
                  D. Professional or employment-related information.
                </strong>
              </td>
              <td>Current or past job history.</td>
              <td>
                - Fulfilling customer orders for our services- Marketing our
                services- Customer service- Analytics and improvement of our
                services
              </td>
            </tr>
            <tr>
              <td>
                <strong>
                  E. Inferences drawn from other personal information.
                </strong>
              </td>
              <td>
                Profile about a consumer reflecting the consumer’s preferences,
                characteristics, psychological trends, predispositions,
                behavior, attitudes, intelligence, abilities, and aptitudes.
              </td>
              <td>
                - Marketing our services- Analytics and improvement of our
                services
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>
        <span>
          <strong>Categories of Information Disclosed</strong>
        </span>
      </p>
      <p>
        In the 12 months preceding the Last Updated date of this Notice, we have
        disclosed the following categories of personal information for a
        business purpose as described above:
      </p>
      <p>Category A: Identifiers.</p>
      <p>
        Category B: California Customer Records personal information categories.
      </p>
      <p>Category C: Internet or other similar network activity.</p>
      <p>Category D: Professional or employment-related information.</p>
      <p>Category E: Inferences drawn from other personal information.</p>
      <p>
        <span>
          <strong>Categories of Information “Sold”</strong>
        </span>
      </p>
      <p>
        We do not sell your personal data for a monetary fee; however, some U.S.
        privacy laws define a “sale” very broadly such that it may include our
        sharing of your personal data with certain third parties if we receive
        anything of value in return.&nbsp; In that regard, we share your
        personal data with certain third-party marketing partners and in
        exchange we receive the ability to market or offer our products and
        services through their websites and applications.&nbsp; This may be
        considered a “sale” under some laws.
      </p>
      <p>
        The personal data that may be shared or sold in this context is: your
        name; email address; other contact information; and information obtained
        through cookies about, for example, your location, the website that
        directed you to our Platform, how you navigate our sites, and what
        purchases you make.&nbsp;&nbsp;
      </p>
      <p>
        <span>
          <strong>Do-Not-Track Features</strong>
        </span>
      </p>
      <p>
        Many web browsers and some mobile operating systems and mobile
        applications include a “do-not-track” feature or setting you can
        activate to signal your privacy preference not to have data about your
        online browsing activities monitored and collected.&nbsp; No uniform
        technology standard for recognizing and implementing do-not-track
        signals has been finalized and, as such, we do not currently respond to
        do not track browser signals or any other mechanism that automatically
        communicates your choice not to be tracked online.&nbsp;&nbsp;
      </p>
      <p>
        <span>
          <strong>Additional Privacy Rights</strong>
        </span>
      </p>
      <p>
        <strong>Right to Information.</strong>&nbsp; Subject to certain limits,
        you may ask us to provide the following information for the 12-month
        period preceding your request:&nbsp;
      </p>
      <ul>
        <li>The categories of personal information we collected about you;</li>
        <li>
          The categories of sources from which the personal information was
          collected;
        </li>
        <li>
          The business or commercial purpose for collecting the personal
          information;
        </li>
        <li>
          The categories of third parties with whom we shared the personal
          information;
        </li>
        <li>
          If we disclosed personal information for a business purpose, a list of
          the disclosures including the personal information categories that
          each category of recipient received; and&nbsp;
        </li>
        <li>
          The specific pieces of personal information we collected about you.
        </li>
      </ul>
      <p>
        We do not provide these information rights for personal information that
        we obtain through a business-to-business (B2B) relationship.
      </p>
      <p>
        <strong>Right to Delete.</strong>&nbsp; You also have the right to ask
        us to delete any personal information that we have collected about you,
        subject to certain limitations. We may deny your deletion request if the
        information is necessary for us or our service providers to, among other
        things, provide a service you requested, take actions reasonably
        anticipated in the context of our business relationship with you,
        perform a contract we have with you, detect and protect against security
        incidents or illegal activity, comply with a legal obligation, or
        exercise a right provided for by law.&nbsp;
      </p>
      <p>
        <strong>Right to Opt Out of Sale.</strong>&nbsp;You have the right to
        opt out of the sale of your personal information.&nbsp; You may exercise
        this right by submitting a request as described in the “Submission of
        Requests” section below.&nbsp;
      </p>
      <p>
        <strong>Right to Nondiscrimination</strong>.&nbsp; We will not
        discriminate against you if you exercise your privacy rights under any
        applicable privacy law, including by:
      </p>
      <ul>
        <li>Denying you goods or services.</li>
        <li>
          Charging you different prices or rates for goods or services,
          including through granting discounts or other benefits, or imposing
          penalties.
        </li>
        <li>
          Providing you a different level or quality of goods or services.
        </li>
        <li>
          Suggesting that you may receive a different price or rate for goods or
          services or a different level or quality of goods or services.
        </li>
      </ul>
      <p>
        We are permitted under applicable law to offer you certain financial
        incentives that can result in different prices, rates, or quality
        levels, which are related to your personal information’s value.
        Participation in a financial incentive program requires your prior
        opt-in consent, which you may revoke at any time.
      </p>
      <p>
        <strong>Submission of Requests.</strong>
        You may submit a request regarding your personal information by:
      </p>
      <p>
        Emailing us at
        <span>privacy@hiringspree.io</span>– please provide your name, telephone
        number, and type of request (that is, a request for categories of
        information, a request for specific pieces of information, a request to
        delete, or a request to opt out the sale of your information).
      </p>
      <p>
        <strong>What We May Need from You.&nbsp;</strong>We may need to request
        specific information from you to help us confirm your identity and
        ensure your right to access your personal information (or to exercise
        any of your other rights). This is a security measure to ensure that
        personal information is not disclosed to someone who has no right to
        receive it. We may also contact you to ask you for further information
        in relation to your request to speed up our response.
      </p>
      <p>
        <em>Verifying Your Identity</em>: To protect your privacy and security,
        we will take reasonable steps to verify your identity before providing
        your personal information and before deleting your information. Only you
        or someone legally authorized to act on your behalf may make a
        verifiable request related to your personal information. For example, if
        you make a request, we will ask you to confirm your name, email address,
        and/or other information in our records to verify your identity, so that
        we can help protect your information.&nbsp;&nbsp;
      </p>
      <p>
        <em>Requests from Authorized Agents</em>:&nbsp; You may designate an
        authorized agent to make a request for you. If you designate an
        authorized agent to make a request on your behalf, we may require you to
        verify your identity and provide the authorized agent’s identity and
        contact information to us.
      </p>
      <p>
        <strong>Responses to Requests.</strong>&nbsp; We will confirm receipt of
        your request within 10 business days of receipt and generally will
        respond to your request within 45 days of receipt.&nbsp; If we need more
        time to respond, we will inform you of the reason and we may take up to
        an additional 45 days to respond.&nbsp;&nbsp;
      </p>
      <p>
        <strong>No Fee Usually Required.&nbsp;</strong>You will not have to pay
        a fee to access your personal information (or to exercise any of the
        other rights). However, we may charge a reasonable fee if your request
        is clearly unfounded, repetitive or excessive. Alternatively, we may
        refuse to comply with your request in these circumstances.&nbsp;&nbsp;
      </p>
      <p>© 2024 Hiring Spree LLC</p>
    </div>
  );
}

export default LegalPrivacy;
