import React from "react";

function Help() {
  return (
    <div className="px-[75px] pt-[40px] min-h-[100vh]">
      <h1 className="font-bold tracking-tight text-slate-900 text-[32px]">
        Help
      </h1>
      <div className="flex gap-4">
        {/* Table of Contents */}
        <div className="w-1/4">
          <div className="sticky top-0 pt-[40px]">
            <div className="bg-white block-shadow p-[30px] rounded-lg">
              <h2 className="font-semibold tracking-tight text-slate-900 text-lg">
                Table of Contents
              </h2>
              <ul className="mt-2 flex flex-col gap-1">
                <li>
                  <a
                    href="#section1"
                    className="font-medium text-slate-700 hover:text-[#3371ff]"
                  >
                    Creating a New Page
                  </a>
                </li>
                <li>
                  <p className="font-medium text-[#3371ff] mt-4">
                    More Coming Soon!
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Help Content */}
        <div className="w-3/4 py-[40px] flex flex-col gap-4">
          {/* Section 1 */}
          <div
            id="section1"
            className="block-shadow bg-white p-[30px] rounded-lg"
          >
            <h2 className="font-semibold tracking-tight text-slate-900 text-lg">
              Creating a New Page
            </h2>
            <div className="mt-2">
              <iframe
                src="https://scribehow.com/embed/Creating_a_New_Page_From_Scratch__dOJhZTL9Qw-o7lRO5Ty14Q"
                width="100%"
                height="640"
                allowfullscreen
                frameborder="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Help;
