import React from "react";
import Spinner from "./Spinner";

function PageLoader(props) {
  return (
    <div>
      {!props.children && (
        <div className="p-10 text-center">
          <Spinner size="25px" />
        </div>
      )}

      {props.children && <>{props.children}</>}
    </div>
  );
}

export default PageLoader;
