import React, { useEffect } from "react";

function Alert({ text, type, timeout = 0, onDismiss = null }) {
  const getStatusStyles = () => {
    switch (type) {
      case "success":
        return {
          textColor: "text-green-700",
          bgColor: "bg-green-100",
          borderColor: "border-green-300",
          icon: "✓",
          iconColor: "text-green-700",
        };
      case "error":
        return {
          textColor: "text-red-700",
          bgColor: "bg-red-100",
          borderColor: "border-red-300",
          icon: "!",
          iconColor: "text-red-700",
        };
      case "warning":
        return {
          textColor: "text-yellow-700",
          bgColor: "bg-yellow-100",
          borderColor: "border-yellow-300",
          icon: "!",
          iconColor: "text-yellow-700",
        };
      case "info":
        return {
          textColor: "text-blue-700",
          bgColor: "bg-blue-100",
          borderColor: "border-blue-300",
          icon: "ℹ",
          iconColor: "text-blue-700",
        };
      default:
        return {
          textColor: "text-slate-800",
          bgColor: "bg-slate-100",
          borderColor: "border-slate-300",
          icon: "ℹ",
          iconColor: "text-slate-800",
        };
    }
  };

  const { textColor, bgColor, borderColor, icon, iconColor } =
    getStatusStyles();

  useEffect(() => {
    let timer;
    if (timeout && timeout > 0) {
      timer = setTimeout(() => {
        dismissAlert();
      }, timeout);
    }

    // Clean up the timer on component unmount or if timeout changes
    return () => clearTimeout(timer);
  }, [timeout]);

  const dismissAlert = () => {
    if (onDismiss) {
      onDismiss(); // Invoke the onDismiss callback
    }
  };

  return (
    <div
      className={`flex justify-between items-center px-3 py-2 border border-solid rounded-xl ${bgColor} ${borderColor}`}
    >
      <p className={`text-[12px] font-semibold ${textColor}`}>{text}</p>
      <span className={`ml-1 ${iconColor}`}>{icon}</span>
    </div>
  );
}

export default Alert;
