import React from "react";
import SocialLinkWithActions from "./SocialLinkWithActions";
import { apiRoutes, makeApiRequest } from "../../../../constants";
import { useAuth } from "util";
import { ReactComponent as LinkIcon } from "assets/icons/link-alt-1.1.svg";
import { useCompany } from "util";

function SocialLinkContainer({ socialLinks, toggleAddForm }) {
  const auth = useAuth();
  const { companyData, userRole, fetchCompanyData } = useCompany();

  const handleDeleteSocialLink = async (linkId) => {
    try {
      const response = await makeApiRequest(
        "delete",
        apiRoutes.deleteSocialLinkById(companyData.companyId, linkId),
        auth.user,
        companyData.companyId
      );

      if (response.status < 300) {
        console.log("Successfully deleted social link");
        fetchCompanyData();
      } else {
        console.error("Failed to delete social link");
      }
    } catch (error) {
      console.error("Error deleting social link", error);
    }
  };

  const renderedLinks = socialLinks?.map((item) => (
    <li className="pt-6 border-solid">
      <SocialLinkWithActions
        key={item.id}
        socialLink={item}
        onDelete={handleDeleteSocialLink}
      />
    </li>
  ));

  return (
    <div className="bg-white block-shadow rounded-xl p-[30px]">
      <div className="flex gap-[10px] items-center">
        <div className="flex gap-[10px] items-center">
          <LinkIcon className="fill-[#3371ff]" />
          <h2 className="font-semibold text-xl text-slate-700 tracking-tight">
            Social Links
          </h2>
        </div>

        {/* ADD BLOCKS FROM JD BUTTON HERE? */}

        {userRole === "Employee" ? null : (
          <button
            className="ml-auto text-[#3371ff] font-normal bg-[#3371ff10] hover:bg-[#3371ff15] text-sm cursor-pointer rounded-lg px-[20px] h-[42px]"
            onClick={toggleAddForm}
          >
            Add Link
          </button>
        )}
      </div>
      <ul className="flex flex-col">{renderedLinks}</ul>
    </div>
  );
}

export default SocialLinkContainer;
