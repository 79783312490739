import React, { useState } from "react";
import { apiRoutes } from "constants";
import { useAuth } from "util";
import Submit from "../Buttons/Submit";
import { useCompany } from "util";
import { makeApiRequest } from "constants";

const BugReportForm = ({
  close,
  setSnackbarVisible,
  setSnackbarMessage,
  setSnackbarStatus,
}) => {
  const auth = useAuth();
  const { companyData, userRole } = useCompany();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    image: null,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: name === "image" ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("title", formData.title);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("image", formData.image);
    formDataToSend.append("name", auth.user.name);
    formDataToSend.append("email", auth.user.email);
    formDataToSend.append("companyId", companyData.companyId);
    formDataToSend.append("userRole", userRole);

    try {
      const response = await makeApiRequest(
        "post",
        apiRoutes.reportBugs(auth.user.id),
        auth.user,
        companyData.companyId,
        formDataToSend
      );

      if (response.status === 200) {
        setSnackbarStatus("success");
        setSnackbarMessage("Bug report submitted successfully!");
        setSnackbarVisible(true);
        close();
      } else {
        setSnackbarStatus("error");
        setSnackbarMessage("Failed to submit bug report");
        setSnackbarVisible(true);
      }
    } catch (error) {
      setSnackbarStatus("error");
      setSnackbarMessage("Error while submitting bug report");
      setSnackbarVisible(true);
      console.error("Error while submitting bug report:", error);
    }
  };

  return (
    <div className="fixed bottom-[150px] right-[20px] z-[1000] bg-white p-[30px] block-shadow rounded-[30px]">
      <form
        onSubmit={handleSubmit}
        encType="multipart/form-data"
        className="space-y-4"
      >
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Feedback / Bug Report
          </label>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
            className="mt-1 p-2 w-full border rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Describe Your Bug
          </label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
            className="mt-1 p-2 w-full border rounded-md text-xs min-h-[100px]"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Add a Screenshot
          </label>
          <input
            type="file"
            name="image"
            accept="image/*"
            onChange={handleChange}
            className="mt-1 p-2 w-full border rounded-md text-sm"
          />
        </div>
        <Submit text="Submit Bug Report" />
      </form>
    </div>
  );
};

export default BugReportForm;
