import React, { useEffect } from "react";

function TwitterPost({ ...item }) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    document.getElementsByClassName("twitter-tweet")[0].appendChild(script);
  }, []);

  // Regular expressions to extract tweet information
  const tweetContentRegex = /<p lang="en" dir="ltr">(.*?)<\/p>/;
  const authorRegex = /<p lang="en" dir="ltr">(.*?)<\/p>/;
  const usernameRegex = /(@\w+)/;
  const tweetUrlRegex = /https:\/\/twitter.com\/(\w+)\/status\/(\d+)/;

  // Extract tweet content
  const tweetContentMatch = item.content.match(tweetContentRegex);
  const tweetContent = tweetContentMatch ? tweetContentMatch[1] : "";

  // Extract author and username
  const authorMatch = tweetContent.match(authorRegex);
  const author = authorMatch ? authorMatch[1] : "";
  const usernameMatch = author.match(usernameRegex);
  const username = usernameMatch ? usernameMatch[0] : "";

  // Extract tweet URL
  const tweetUrlMatch = item.content.match(tweetUrlRegex);
  const tweetUrl = tweetUrlMatch ? tweetUrlMatch[0] : "";

  return (
    <blockquote className="twitter-tweet">
      <p lang="en" dir="ltr">
        {tweetContent}
      </p>
      <p>
        <a href={tweetUrl} target="_blank" rel="noopener noreferrer">
          {username}
        </a>
      </p>
    </blockquote>
  );
}

export default TwitterPost;
