import { useCreateCompany } from "hooks/useCreateCompany";
import { makeApiRequest, apiRoutes } from "constants";
import { Input } from "components/Input";
import Select from "components/Select";
import { TextArea } from "components/TextArea";
import {
  companySize,
  fundingStatus,
  fundingType,
  industries,
} from "constants/constants";
import { Controller } from "react-hook-form";
import PrimaryActionButton from "components/Buttons/PrimaryActionButton";
import SecondaryActionButton from "components/Buttons/SecondaryActionButton";

const CreateCompany = ({ toggleCreateCompany }) => {
  const { isSaving, control, handleSubmit, handleSaveImage, companyLogoUrl } =
    useCreateCompany();
  const defaultLogo =
    "https://hs-icons.s3.us-east-2.amazonaws.com/hs_logo_blue.png";

  return (
    <div className="w-[800px] p-[30px] block-shadow bg-white rounded-[30px]">
      <h2 className="font-semibold text-xl text-slate-700 tracking-tight mb-2">
        Create your team on Hiring Spree
      </h2>
      <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-[15px]">
        <Controller
          control={control}
          name="companyName"
          render={({ field }) => (
            <Input
              label="Company Name"
              value={field.value}
              setValue={field.onChange}
              required={true}
              placeholder="Hiring Spree"
            />
          )}
        />
        <Controller
          control={control}
          name="companyId"
          render={({ field }) => (
            <Input
              label="Company ID"
              value={field.value}
              setValue={field.onChange}
              required={true}
              placeholder="hiringspree"
            />
          )}
        />
        <Controller
          control={control}
          name="blurb"
          render={({ field }) => (
            <TextArea
              label="Description"
              value={field.value}
              setValue={field.onChange}
              placeholder="Hiring Spree is the Recruiter co-pilot!"
              required={true}
            />
          )}
        />
        <Controller
          control={control}
          name="companySize"
          render={({ field }) => (
            <Select
              placeholder="Company Size"
              value={field.value}
              options={companySize}
              onChange={field.onChange}
              label="Company Size"
            />
          )}
        />

        <Controller
          control={control}
          name="foundedDate"
          render={({ field }) => (
            <Input
              label="Year Founded"
              type="text"
              value={field.value}
              setValue={field.onChange}
              required={true}
              placeholder="2023"
            />
          )}
        />
        <Controller
          control={control}
          name="fundingStatus"
          render={({ field }) => (
            <Select
              placeholder="Funding Status"
              value={field.value}
              options={fundingStatus}
              onChange={(selectedOption) => field.onChange(selectedOption)}
              label="Funding Status"
            />
          )}
        />
        <Controller
          control={control}
          name="fundingType"
          render={({ field }) => (
            <Select
              placeholder="Funding Type"
              value={field.value}
              options={fundingType}
              onChange={field.onChange}
              label="Funding Type"
            />
          )}
        />

        <Controller
          control={control}
          name="homepageUrl"
          render={({ field }) => (
            <Input
              label="Homepage URL"
              type="text"
              value={field.value}
              setValue={field.onChange}
              required={true}
              placeholder="https://hiringspree.io"
            />
          )}
        />

        <Controller
          control={control}
          name="hqLocation"
          render={({ field }) => (
            <Input
              label="HQ Location"
              type="text"
              value={field.value}
              setValue={field.onChange}
              required={true}
              placeholder="Chicago, Illinois, United States"
            />
          )}
        />

        <Controller
          control={control}
          name="industry"
          render={({ field }) => (
            <Select
              placement="top"
              placeholder="Industries"
              value={field.value}
              options={industries}
              onChange={field.onChange}
              multiple
              label="Industries"
            />
          )}
        />
        <div className="flex flex-row">
          <div className="flex items-center gap-[20px] bg-white p-3 pr-4 block-shadow rounded-full">
            <img
              className="h-[40px] w-[40px] rounded-full bg-white block-shadow"
              src={companyLogoUrl || defaultLogo}
              alt="logo"
            />

            <div className="flex flex-col">
              <label
                for="logo-upload"
                className="text-sm font-medium cursor-pointer text-[#3371ff]"
              >
                Change Logo
              </label>
              <input
                id="logo-upload"
                type="file"
                className="hidden"
                onChange={(e) => handleSaveImage(e.target.files[0])}
              />
            </div>
          </div>
        </div>
        <div></div>
        <div></div>
        <div className="flex justify-end mt-[30px] gap-[20px]">
          <SecondaryActionButton
            text="Company Search"
            action={toggleCreateCompany}
          />
          <PrimaryActionButton
            text={isSaving ? "..." : "Create Company"}
            buttonType="submit"
            disabled={!companyLogoUrl || isSaving}
          />
        </div>
      </form>
    </div>
  );
};

export default CreateCompany;
