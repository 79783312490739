import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import InputHeader from "./InputHeader";
import { ReactComponent as Text } from "assets/icons/text.svg";
import { Input } from "components/Input";
import { apiRoutes } from "constants/apiRoutes";
import { client } from "constants/api";
import { useAuth } from "util";
import InputModal from "./InputModal";
import CompanyComponentEditWarning from "./CompanyComponentEditWarning";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import PrimaryActionButton from "components/Buttons/PrimaryActionButton";
import { useCompany } from "util";

function TextBlockInput({
  close,
  setSelectedComponent,
  isEditing,
  existingContent = [],
  refetch,
  isTeamsPage,
  handleCreateSharedComponent,
  handleEditSharedComponent,
}) {
  const [blockData, setBlockData] = useState({
    blockType: "text",
    title: "",
    content: "",
  });
  const auth = useAuth();
  const { companyData } = useCompany();
  const companyId = companyData.companyId;
  const { roleId } = useParams();

  // Update blockData when editing existing content
  useEffect(() => {
    if (isEditing) {
      setBlockData({
        blockType: "text",
        title: existingContent.title,
        content: existingContent.content,
        id: existingContent.id, // Include id for editing
        order: existingContent.order, // Include order for editing
      });
    }
  }, [isEditing, existingContent]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a new block object or update an existing one
    const url = isEditing
      ? apiRoutes.editBlock(companyId, roleId, existingContent.id)
      : apiRoutes.createBlock(companyId, roleId);

    try {
      let response;
      if (isTeamsPage) {
        response = isEditing
          ? await handleEditSharedComponent(blockData)
          : await handleCreateSharedComponent(blockData);
      } else {
        response = await client[isEditing ? "put" : "post"](url, blockData, {
          headers: {
            Authorization: auth.user
              ? `${auth.user.id}.${auth.user.accessToken}`
              : "",
          },
        });
      }

      if (response.status < 300) {
        if (isEditing) {
          console.log("Block updated successfully");
          refetch();
        } else {
          console.log("Block created successfully");
        }
        setBlockData({ ...blockData, title: "" });
        setBlockData({ ...blockData, content: "" });
        close(true); // Close the modal or reset the form
        // Potential Quick Fix to refresh page:
        // window.location.reload();
      } else {
        console.error(
          isEditing ? "Failed to update block" : "Failed to create block"
        );
      }
    } catch (error) {
      console.error("Error sending request:", error);
    }
  };

  const unsavedChanges = isEditing
    ? blockData.title !== existingContent.title ||
      blockData.content !== existingContent.content
    : blockData.title !== "" || blockData.content !== "";

  return (
    <InputModal size="md">
      <InputHeader
        icon={<Text className="stroke-[#3371ff]" />} // Pass the appropriate icon for awards
        title="Text"
        isEditing={isEditing}
        existingContent={existingContent}
        setSelectedComponent={setSelectedComponent}
        close={close}
        unsavedChanges={unsavedChanges}
      />
      <form
        onSubmit={handleSubmit}
        className="flex flex-col mt-[20px] gap-[20px]"
      >
        <Input
          setValue={(e) =>
            setBlockData({ ...blockData, title: e.target.value })
          }
          onChange={(e) => {
            setBlockData({ ...blockData, title: e.target.value });
          }}
          value={blockData.title}
          required={true}
          label="Title"
          id="title"
        />
        <ReactQuill
          theme="snow"
          value={blockData.content}
          onChange={(editorContent) => {
            setBlockData({ ...blockData, content: editorContent });
          }}
          label="Content"
          id="content"
          require="true"
        />
        <CompanyComponentEditWarning sharedId={existingContent.sharedId} />
        <div className="ml-auto flex gap-[15px]">
          <PrimaryActionButton
            text={isEditing ? "Update Block" : "Create Block"}
            buttonType="submit"
            disabled={!unsavedChanges}
          />
        </div>
      </form>
    </InputModal>
  );
}

export default TextBlockInput;
