import React from 'react'
import GetForm from './GetForm';

const DNDModal = ({ onClose, selectedItem, refetch }) => {
    const { blockType } = selectedItem;
    const formToRender = GetForm(
      blockType,
      onClose,
      null, // You can update this as needed
      selectedItem !== null, // Check if selectedItem exists to determine editing mode
      selectedItem || { title: '', content: '' }, // Pass existing content or empty content
      refetch
    );
  
    return (
      <>
        {formToRender}
      </>
    );
  };

export default DNDModal




