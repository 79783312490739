import React from "react";
import InfoCardLayout from "./InfoCardLayout";
import ProfilePicture from "./components/ProfilePicture";
import InfoCardBody from "./components/InfoCardBody";
import InfoCardTitle from "./components/InfoCardTitle";
import InfoCardLinks from "./components/InfoCardLinks";

function InfoCard({
  companyData = null,
  userData = null,
  isMobileModal,
  infoCardDisplay = 1,
  // Default Display = User Profile Picture + Company Logo
  // Keeping the conditional rendering of the Company display
  // Because this will likely be used for any sort of Career Page
}) {
  return (
    <InfoCardLayout padding={isMobileModal ? 0 : undefined}>
      <ProfilePicture
        companyData={companyData}
        userData={userData}
        infoCardDisplay={infoCardDisplay}
      />
      <InfoCardTitle
        companyData={companyData}
        userData={userData}
        infoCardDisplay={infoCardDisplay}
      />
      <InfoCardBody
        companyData={companyData}
        infoCardDisplay={infoCardDisplay}
      />

      {isMobileModal ? null : <InfoCardLinks companyData={companyData} />}
    </InfoCardLayout>
  );
}

export default InfoCard;
