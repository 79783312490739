import React from "react";

// Define the Flag component
const VerifiedFlag = ({ status }) => {
  // Function to determine which flag to return based on status
  const renderFlag = () => {
    switch (status) {
      case "verified":
        return "✅ Verified";
      case "pending":
        return "⏳ Pending";
      case "notVerified":
        return "❌ Not Verified";
      default:
        return "❓ Unknown Status";
    }
  };

  return <div>{renderFlag()}</div>;
};

export default VerifiedFlag;
