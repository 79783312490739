import React from "react";
import Meta from "./../components/Meta";
import AuthSection from "./../components/Auth/AuthSection";
import { useRouter } from "../util";

function AuthPage(props) {
  const router = useRouter();

  return (
    <>
      <Meta title="Auth" />

      {router.query.type === "signup" ? (
        <AuthSection
          type={router.query.type}
          // providers={["google", "outlook"]}
          // REDIRECT TO STRIPE BILLING
          afterAuthPath={"/purchase/recruiter"}
        />
      ) : (
        <AuthSection
          type={router.query.type}
          // providers={["google", "outlook"]}
          afterAuthPath={"/pages"}
        />
      )}
    </>
  );
}

export default AuthPage;
