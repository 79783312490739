import React, { useState } from "react";
import Tooltip from "components/Tooltip";
import { ReactComponent as PendingCheck } from "assets/icons/badge-check-line.svg";
import { ReactComponent as PublishedCheck } from "assets/icons/badge-check.svg";
import { ReactComponent as EditIcon } from "assets/icons/pen-square.svg";
import { ReactComponent as Timer } from "assets/icons/timer.svg";
import { ReactComponent as Trash } from "assets/icons/trash.svg";
import { useAuth } from "util";
import { apiRoutes } from "constants";
import { useCompany } from "../../../util";
import { makeApiRequest } from "constants";
import Modal from "components/Modal";
import PrimaryActionButton from "components/Buttons/PrimaryActionButton";
import StyledSelect from "components/StyledSelect";
import { permissionsOptions } from "constants";
import ConfirmationModal from "components/ConfirmationModal";

function UserBlockWithActions({ userData }) {
  const auth = useAuth();
  const { companyData, userRole } = useCompany();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState("");

  function closeModal() {
    setIsModalOpen(false);
    setSelectedPermissions("");
  }

  // TODO - Blocked by Permissions Array
  async function handleDeleteUser() {
    const response = await makeApiRequest(
      "delete",
      apiRoutes.getUsersByCompanyId + "/" + userData.id,
      auth.user,
      companyData.companyId
    );
    if (response.status === 200) {
      console.log("User Deactivated Successfully");
    }
    setIsConfirmationModalOpen(false);
  }

  async function handleEditUser(updatedPermissions) {
    console.log(updatedPermissions);
    let permissionsObject = { userRole: updatedPermissions };

    const response = await makeApiRequest(
      "put",
      apiRoutes.getUsersByCompanyId + "/" + userData.id,
      auth.user,
      companyData.companyId,
      permissionsObject
    );
    if (response.status === 204) {
      console.log("User Permissions Updated Successfully");
    }
    closeModal();
  }

  return (
    <div
      className={`grid grid-cols-[1.5fr_1fr_1fr_1fr_.5fr] block-shadow p-[15px] pr-[22px] rounded-xl bg-white ${
        userData.isVerified === "disabled" ? "opacity-60" : null
      }`}
    >
      <div className="flex items-center gap-[15px]">
        {userData.userImage ? (
          <img
            className="h-[50px] w-[50px] rounded-full bg-white block-shadow"
            src={userData.userImage}
            alt="logo"
          />
        ) : (
          <div className="h-[50px] w-[50px] rounded-full bg-white block-shadow flex items-center justify-center">
            {userData.email.charAt(0).toUpperCase()}
          </div>
        )}
        <div className="flex flex-col">
          <p className="font-semibold text-md text-slate-700 tracking-tight">
            {userData.name ? userData.name : "Pending Invite"}{" "}
            {userData.name && userData.name === auth.user.name ? "(You)" : null}
          </p>
          <p className="font-regular text-sm text-[#3371ff]">
            {userData.email}
          </p>
        </div>
      </div>
      <div className="flex items-center">
        <p className="font-regular text-sm text-slate-700">
          Verification:{" "}
          <span className="font-medium">
            {userData.isVerified.charAt(0).toUpperCase() +
              userData.isVerified.slice(1)}
          </span>
        </p>
      </div>
      <div className="flex items-center">
        {userData.emailVerified}
        <p className="font-regular text-sm text-slate-700">
          Onboarding:{" "}
          <span className="font-medium">
            {userData.showOnboardingFlow ? (
              <span className="text-red-500">Incomplete</span>
            ) : (
              <span className="text-[#42946D]">Completed</span>
            )}
          </span>
        </p>
      </div>

      <div className="flex items-center">
        <p className="font-regular text-sm text-slate-700">
          Permissions:{" "}
          <span className="text-[#3371ff] font-medium">
            {userData.userRole}
          </span>
        </p>
      </div>

      <div className="flex items-center">
        <div className="ml-auto flex gap-[15px]">
          {userRole === "Employee" ? null : (
            <>
              <Tooltip text="Edit Permissions">
                <button
                  onClick={() => setIsModalOpen(true)}
                  className="flex items-center bg-transparent border-none cursor-pointer"
                  aria-label="Edit Permissions"
                >
                  <EditIcon className="stroke-slate-500 hover:stroke-[#3371ff] cursor-pointer" />
                </button>
              </Tooltip>

              <Tooltip text="Deactivate User">
                <button
                  onClick={() => setIsConfirmationModalOpen(true)}
                  className="flex items-center bg-transparent border-none cursor-pointer"
                  aria-label="Deactivate User"
                >
                  <Trash className="stroke-slate-500 hover:stroke-[#3371ff]" />
                </button>
              </Tooltip>
            </>
          )}
        </div>
      </div>
      {isConfirmationModalOpen ? (
        <ConfirmationModal
          title="Deactivate User?"
          message={`Are you sure you want to deactivate ${userData.name}'s account?`}
          onCancel={() => setIsConfirmationModalOpen(false)}
          onConfirm={handleDeleteUser}
        />
      ) : null}
      {isModalOpen ? (
        <Modal title="Edit User Permissions" size="sm" closeModal={closeModal}>
          <div className="mb-4">
            <p className="font-medium text-slate-700">
              Name: <span className="text-[#3371ff]">{userData.name}</span>
            </p>
            <p className="font-medium text-slate-700">
              Permissions:{" "}
              <span className="text-[#3371ff]">{userData.userRole}</span>
            </p>
          </div>
          <div className="flex flex-col gap-6">
            <ul className="flex flex-col gap-2 text-sm">
              <li className="text-slate-600">
                <span className="font-semibold text-slate-600">Employee:</span>{" "}
                Employee permissions include page and and template creation,
                read access for shared components, and read access for company
                information.
              </li>
              <li className="text-slate-600">
                <span className="font-semibold text-slate-600">
                  Company Admin:
                </span>{" "}
                Company Admin permissions include page and and template
                creation, edit access for pages across teams, read/write access
                for shared components, and read/write access for company
                information.
              </li>
            </ul>
            <StyledSelect
              label="Assign New Permissions"
              placeholder={userData.userRole}
              options={permissionsOptions}
              onChange={(selectedOption) => {
                setSelectedPermissions(selectedOption.value);
              }}
            />
          </div>
          <div className="mt-8 flex ml-auto">
            <PrimaryActionButton
              text="Save Permissions"
              type="button"
              action={() => handleEditUser(selectedPermissions)}
              disabled={selectedPermissions === ""}
            />
          </div>
        </Modal>
      ) : null}
    </div>
  );
}

export default UserBlockWithActions;
