import React, { useState } from "react";
import ConfirmationModal from "components/ConfirmationModal";
import Tooltip from "components/Tooltip";
import { useParams } from "react-router-dom";
import { ReactComponent as EditIcon } from "assets/icons/pen-square.svg";
import { ReactComponent as XMark } from "assets/icons/xmark.svg";
import { apiRoutes, makeApiRequest } from "../../../../constants";
import { useAuth, useCompany } from "../../../../util";

function LinkWithActions({
  newsroomLink,
  openEditModal,
  index,
  setLocalNewsroomLinks,
}) {
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const auth = useAuth();
  const { companyData } = useCompany();
  const companyId = companyData.companyId;
  const { roleId } = useParams();

  const handleDelete = () => {
    setConfirmationModalOpen(true);
  };

  const handleCancelDelete = () => {
    // Handle cancel action or simply close the modal
    setConfirmationModalOpen(false);
  };

  const handleDeleteLink = async (linkId) => {
    try {
      const response = await makeApiRequest(
        "delete",
        apiRoutes.deletePageLink(companyId, roleId, linkId),
        auth.user,
        companyData.companyId
      );

      if (response.status === 204) {
        // Fetch and update local newsroom links
        const updatedLinksResponse = await makeApiRequest(
          "get",
          apiRoutes.getPageLinks(companyId, roleId),
          auth.user,
          companyData.companyId
        );
        setLocalNewsroomLinks(updatedLinksResponse.data);
        setConfirmationModalOpen(false);
      } else {
        console.error("Failed to delete link");
      }
    } catch (error) {
      console.error("Error deleting link", error);
    }
  };

  return (
    <div className="flex justify-between items-center gap-4">
      <a
        href={newsroomLink.url}
        rel="noopener noreferrer"
        target="_blank"
        className="text-sm text-[#3371ff]"
      >
        {newsroomLink.title}
      </a>

      <div className="flex align-center gap-[20px]">
        <Tooltip text="Edit Link">
          <button onClick={() => openEditModal(index)}>
            <EditIcon className="stroke-slate-500 hover:stroke-[#3371ff]" />
          </button>
        </Tooltip>
        <Tooltip text="Delete Link">
          <button
            className="stroke-slate-500 hover:stroke-[#ff0000]"
            onClick={handleDelete}
          >
            <XMark />
          </button>
        </Tooltip>
      </div>
      {isConfirmationModalOpen && (
        <ConfirmationModal
          title={`Delete Link?`}
          message={`Are you sure you want to delete this link?`}
          onConfirm={() => handleDeleteLink(newsroomLink.id)}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
}

export default LinkWithActions;
