import React, { useEffect, useState } from "react";
import { requireAuth } from "util/auth";
import PostingBlockWithActions from "./components/PostingBlockWithActions";
import { useAuth } from "util/auth";
import { CreateEditRoleModal } from "./components/CreateEditRoleModal";
import { useCreateRole, useCreateTemplateRole } from "hooks/useCreateRole";
import { apiRoutes, makeApiRequest } from "constants/apiRoutes";
import SortRolesHeader from "./components/SortRolesHeader";
import { createLocationString } from "pages/LandingPage/Utilities/Formatting/Location";
import { ImportRoleFromUrlModal } from "./components/ImportRoleFromUrlModal";
import Meta from "components/Meta";
import { useParams } from "react-router";
import { useCompany } from "util";
import PagesEmptyState from "./components/PagesEmptyState";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { ReactComponent as FileUser } from "assets/icons/file-user.svg";
import { ReactComponent as BuildingUser } from "assets/icons/building-user.svg";
import { ReactComponent as Globe } from "assets/icons/globe-alt.svg";

function Home() {
  const auth = useAuth();
  const { companyData } = useCompany();
  const { view } = useParams();
  const [selectedView, setSelectedView] = useState(
    view === "templates" ? "templates" : "roles"
  );
  const [landingPages, setLandingPages] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImportFromUrlModalOpen, setIsImportFromUrlModalOpen] =
    useState(false);
  const [sortField, setSortField] = useState("isPublished");
  const [sortAscending, setSortAscending] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [selectedTemplateType, setSelectedTemplateType] =
    useState("Company Templates");

  const { name, id } = auth.user;
  // Temporary placeholder for onboarding incase user
  // creates account, navigates away, then logs back in
  // to finish.Prevents "destructuring error".
  const { companyId } = companyData;

  const pages =
    selectedView === "roles"
      ? landingPages
      : selectedTemplateType === "Company Templates"
      ? templates.filter((template) => template.companyId !== "global")
      : templates.filter((template) => template.companyId === "global");

  useEffect(() => {
    const fetchData = async () => {
      const [landingPagesResponse, templatesResponse] = await Promise.all([
        makeApiRequest(
          "get",
          apiRoutes.getPagesByUserID(auth.user.id),
          auth.user,
          companyId
        ),
        makeApiRequest(
          "get",
          apiRoutes.getTemplatesByUserID(auth.user.id),
          auth.user,
          companyId
        ),
      ]);

      setLandingPages(landingPagesResponse.data);
      setTemplates(templatesResponse.data);
    };
    if (auth.user.showOnboardingFlow === false) {
      fetchData();
    }
  }, [companyId]);

  const { control, handleSubmit, resetValues } = useCreateRole(
    name,
    companyId,
    id
  );

  const {
    control: templateControl,
    handleSubmit: handleTemplateSubmit,
    resetValues: resetTemplateValues,
  } = useCreateTemplateRole(name, companyId, id);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    if (selectedView === "templates") {
      resetTemplateValues();
    } else {
      resetValues();
    }
    setIsModalOpen(false);
  };

  const openImportFromUrlModal = () => {
    setIsImportFromUrlModalOpen(true);
  };

  const closeImportFromUrlModal = () => {
    setIsImportFromUrlModalOpen(false);
  };

  const goToGlobalTemplates = () => {
    setSelectedView("templates");
    setSelectedTemplateType("Global Templates");
    window.history.replaceState(null, "", "/pages/templates");
  };

  // We want to include roles with titles that contain the search text as a subsequence
  // e.g. role with title: "Software Engineer" should be included when search text is "sft"
  const containsSubsequence = (searchText, title) => {
    if (searchText.length > title.length) {
      return false;
    }

    let subsequencePointer = 0;
    for (let i = 0; i < title.length; i++) {
      if (searchText[subsequencePointer] === title[i]) {
        subsequencePointer++;
        if (subsequencePointer === searchText.length) {
          return true;
        }
      }
    }
    return false;
  };

  const sortedPages =
    searchText && pages.length
      ? pages.filter((role) =>
          containsSubsequence(
            searchText.toLowerCase(),
            role.title.toLowerCase()
          )
        )
      : pages;

  if (pages.length !== 0) {
    sortedPages.sort((a, b) => {
      let comparison;
      if (sortField === "isPublished") {
        comparison = b[sortField] - a[sortField];
      } else if (sortField === "location") {
        comparison = createLocationString(
          a["city"],
          a["state"],
          a["country"],
          a["isRemote"],
          a["isHybrid"]
        )
          .toLowerCase()
          .localeCompare(
            createLocationString(
              b["city"],
              b["state"],
              b["country"],
              b["isRemote"],
              b["isHybrid"]
            ).toLowerCase()
          );
      } else {
        comparison = a[sortField]
          .toLowerCase()
          .localeCompare(b[sortField].toLowerCase());
      }

      comparison = sortAscending ? comparison : -1 * comparison;

      return comparison === 0
        ? a.title.toLowerCase().localeCompare(b.title.toLowerCase())
        : comparison;
    });
  }
  return (
    <>
      <Meta title="My Pages" />
      <div>
        <div className="top-0 sticky pt-[40px] z-40 bg-[#f6f9ff] px-[75px]">
          <h1 className="font-bold tracking-tight text-slate-900 text-[32px]">
            Your Pages
          </h1>
          <p className="mt-2 text-sm">
            These are your Hiring Spree{" "}
            {selectedView === "roles" ? "landing pages" : "templates"}. Use the{" "}
            <span className="font-semibold">
              Create New {selectedView === "roles" ? "Page" : "Template"}
            </span>{" "}
            button to build a new{" "}
            {selectedView === "roles" ? "unpublished page" : "shared template"}.
          </p>

          {/* ===== GRID COL 2 ===== */}

          <div className="flex justify-between mt-[60px]">
            <div className="flex flex-row gap-2 whitespace-nowrap text-sm tracking-tight font-medium">
              <button
                className={`${
                  selectedView === "roles"
                    ? "text-[#3371ff] border-[#3371ff]"
                    : "border-slate-900"
                } border border-solid bg-white block-shadow font-medium text-[15px] pl-3 pr-4 py-2.5 rounded-lg flex flex-row gap-2 items-center`}
                onClick={() => {
                  setSelectedView("roles");
                  window.history.replaceState(null, "", "/pages");
                }}
              >
                <FileUser
                  className={`${
                    selectedView === "roles"
                      ? "stroke-[#3371ff]"
                      : "stroke-slate-900"
                  }`}
                />
                <p>
                  Your Pages{" "}
                  {landingPages.length > 0 && `(${landingPages.length})`}
                </p>
              </button>
              <button
                className={`${
                  selectedView === "templates" &&
                  selectedTemplateType === "Company Templates"
                    ? "text-[#3371ff] border-[#3371ff]"
                    : "border-slate-900"
                } border border-solid bg-white block-shadow font-medium text-[15px] pl-3 pr-4 py-2.5 rounded-lg flex flex-row gap-2 items-center`}
                onClick={() => {
                  setSelectedView("templates");
                  setSelectedTemplateType("Company Templates");
                  window.history.replaceState(null, "", "/pages/templates");
                }}
              >
                <BuildingUser
                  className={`${
                    selectedView === "templates" &&
                    selectedTemplateType === "Company Templates"
                      ? "stroke-[#3371ff]"
                      : "stroke-slate-900"
                  }`}
                />
                <p>
                  Team Templates{" "}
                  {templates.length > 0 &&
                    `(${
                      templates.length -
                      templates.filter(
                        (template) => template.companyId === "global"
                      ).length
                    })`}
                </p>
              </button>
              <button
                className={`${
                  selectedView === "templates" &&
                  selectedTemplateType === "Global Templates"
                    ? "text-[#3371ff] border-[#3371ff]"
                    : "border-slate-900"
                } border border-solid bg-white block-shadow font-medium text-[15px] pl-3 pr-4 py-2.5 rounded-lg flex flex-row gap-2 items-center`}
                onClick={() => {
                  setSelectedView("templates");
                  setSelectedTemplateType("Global Templates");
                  window.history.replaceState(null, "", "/pages/templates");
                }}
              >
                <Globe
                  className={`${
                    selectedView === "templates" &&
                    selectedTemplateType === "Global Templates"
                      ? "stroke-[#3371ff]"
                      : "stroke-slate-900"
                  }`}
                />
                <p>
                  Hiring Spree Templates{" "}
                  {templates.length > 0 &&
                    `(${
                      templates.filter(
                        (template) => template.companyId === "global"
                      ).length
                    })`}
                </p>
              </button>
            </div>
            <div className="flex gap-[10px]">
              {auth.user.featureFlag === "beta" && (
                <button
                  className="bg-white text-[#3371ff] border border-solid border-[#3371ff] block-shadow font-medium text-[15px] pl-3 pr-5 py-2.5 rounded-lg flex flex-row gap-2 items-center"
                  type="button"
                  onClick={openImportFromUrlModal}
                >
                  <Plus className="stroke-[#3371ff]" />
                  <p>Import Job Description</p>
                </button>
              )}
              <button
                className="bg-white text-[#3371ff] border border-solid border-[#3371ff] block-shadow font-medium text-[15px] pl-3 pr-5 py-2.5 rounded-lg flex flex-row gap-2 items-center"
                type="button"
                onClick={openModal}
              >
                <Plus className="stroke-[#3371ff]" />
                <p>{`Create New ${
                  selectedView === "roles" ? "Page" : "Template"
                }`}</p>
              </button>
            </div>
          </div>
          <SortRolesHeader
            sortField={sortField}
            setSortField={setSortField}
            sortAscending={sortAscending}
            setSortAscending={setSortAscending}
            searchText={searchText}
            setSearchText={setSearchText}
            isTemplateHeader={selectedView === "templates"}
          />
        </div>
        <div className="px-[75px]">
          <div className="grid mt-[20px] gap-[20px] pb-[10vh]">
            {sortedPages.length > 0 ? (
              sortedPages.map((role) => (
                <PostingBlockWithActions
                  key={role._id}
                  role={role}
                  setLandingPages={setLandingPages}
                  setTemplates={setTemplates}
                  isTemplate={selectedView === "templates"}
                  setSelectedView={setSelectedView}
                />
              ))
            ) : pages.length > 0 ? (
              <p className="pl-4 text-sm text-slate-900">
                No results match your text search...
              </p>
            ) : (
              <PagesEmptyState
                createNew={openModal}
                navigateToGlobalTemplates={goToGlobalTemplates}
              />
            )}
          </div>
        </div>

        {/* Render the modal when isModalOpen is true */}
        {isModalOpen && (
          <CreateEditRoleModal
            control={selectedView === "templates" ? templateControl : control}
            handleSubmit={
              selectedView === "templates" ? handleTemplateSubmit : handleSubmit
            }
            closeModal={closeModal}
            mode="Create"
            isTemplateRole={selectedView === "templates"}
          />
        )}

        {/* render the import from url modal when it is open */}
        {isImportFromUrlModalOpen && (
          <ImportRoleFromUrlModal
            closeModal={closeImportFromUrlModal}
            companyId={companyId}
          />
        )}
      </div>
    </>
  );
}

export default requireAuth(Home);
