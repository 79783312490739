import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import InputHeader from "./InputHeader";
import { ReactComponent as ImageIcon } from "assets/icons/image-square.svg";
import { Input } from "components/Input";
import { apiRoutes, makeApiRequest } from "constants/apiRoutes";
import { client } from "constants/api";
import { ReactComponent as EyeOn } from "assets/icons/eye-on.svg";
import { ReactComponent as EyeOff } from "assets/icons/eye-off.svg";
import { useAuth } from "util";
import InputModal from "./InputModal";
import CompanyComponentEditWarning from "./CompanyComponentEditWarning";
import PrimaryActionButton from "components/Buttons/PrimaryActionButton";
import { useCompany } from "util";

function ImageBlockInput({
  close,
  setSelectedComponent,
  isEditing,
  existingContent = {},
  refetch,
  isTeamsPage,
  handleCreateSharedComponent,
  handleEditSharedComponent,
}) {
  const [title, setTitle] = useState(isEditing ? existingContent.title : "");
  const [showImage, setShowImage] = useState(false);
  const [imageUrl, setImageUrl] = useState(
    isEditing ? existingContent.content : ""
  );
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const auth = useAuth();
  const { companyData } = useCompany();
  const companyId = companyData.companyId;
  const { roleId } = useParams();

  const handleSaveImage = async (file) => {
    if (!file) {
      setImageUrl("");
      return;
    } else {
      document.getElementById("imageUrl").removeAttribute("required");
    }

    try {
      setIsLoadingImage(true);
      const formData = new FormData();
      formData.append("image", file);
      const response = await makeApiRequest(
        "post",
        apiRoutes.imageFile(),
        auth.user,
        companyId,
        formData
      );
      if (response.data) {
        console.log(response.data.toString());
        setImageUrl(response.data);
        setIsLoadingImage(false);
      }
    } catch (error) {
      setIsLoadingImage(false);
      console.error(error, "error");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const blockData = {
      blockType: "image",
      title,
      content: imageUrl,
      ...(isEditing && {
        id: existingContent.id,
        order: existingContent.order,
      }),
    };

    const url = isEditing
      ? apiRoutes.editBlock(companyId, roleId, existingContent.id)
      : apiRoutes.createBlock(companyId, roleId);

    try {
      let response;
      if (isTeamsPage) {
        response = isEditing
          ? await handleEditSharedComponent(blockData)
          : await handleCreateSharedComponent(blockData);
      } else {
        response = await client[isEditing ? "put" : "post"](url, blockData, {
          headers: {
            Authorization: auth.user
              ? `${auth.user.id}.${auth.user.accessToken}`
              : "",
          },
        });
      }

      if (response.status < 300) {
        if (isEditing) {
          console.log("Block updated successfully");
          refetch();
        } else {
          refetch();
          console.log("Block created successfully");
        }
        close();
      }
    } catch (error) {
      console.error("Error sending request:", error);
    }
  };

  useEffect(() => {
    if (isEditing) {
      setTitle(existingContent.title);
      setImageUrl(existingContent.content);
    }
  }, [isEditing, existingContent]);

  const unsavedChanges = isEditing
    ? title !== existingContent.title || imageUrl !== existingContent.content
    : title !== "" || imageUrl !== "";

  return (
    <InputModal size="md">
      <InputHeader
        icon={<ImageIcon className="stroke-[#3371ff]" />} // Pass the appropriate icon for awards
        title="Image"
        isEditing={isEditing}
        existingContent={existingContent}
        setSelectedComponent={setSelectedComponent}
        close={close}
        unsavedChanges={unsavedChanges}
      />
      <form
        onSubmit={handleSubmit}
        className="flex flex-col mt-[20px] gap-[20px]"
      >
        <Input
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          value={title}
          required={true}
          label="Title"
          id="title"
        />
        <div className="relative">
          <div
            className="absolute right-0"
            onClick={() => setShowImage(!showImage)}
          >
            {showImage ? (
              <EyeOff className="fill-slate-500" />
            ) : (
              <EyeOn className="fill-slate-500" />
            )}
          </div>
          <Input
            onChange={(e) => {
              setImageUrl(e.target.value);
            }}
            value={imageUrl}
            required={true}
            label="Image URL"
            id="imageUrl"
            disabled={isLoadingImage}
          />
          {showImage && (
            <img
              className="mt-5"
              src={imageUrl}
              width={100}
              height={100}
              alt="preview"
            />
          )}
        </div>
        <div className="flex flex-col gap-[10px]">
          <label
            htmlFor="imageFile"
            className="text-[13px] font-medium tracking-tight text-slate-700"
          >
            Upload Image
          </label>
          <input
            type="file"
            id="imageFile"
            accept="image/*"
            onChange={(e) => handleSaveImage(e.target.files[0])}
            disabled={isLoadingImage}
            className="text-sm"
          />
        </div>
        <CompanyComponentEditWarning sharedId={existingContent.sharedId} />
        <div className="ml-auto flex gap-[15px]">
          <PrimaryActionButton
            text={isEditing ? "Update Block" : "Create Block"}
            buttonType="submit"
            disabled={!unsavedChanges}
          />
        </div>
      </form>
    </InputModal>
  );
}

export default ImageBlockInput;
