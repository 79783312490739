import React from "react";
import EditLandingPageLinks from "./EditLandingPageLinks";
import { ReactComponent as LinkIcon } from "assets/icons/link-alt-1.1.svg";

function LinkContainer({
  localNewsroomLinks,
  setLocalNewsroomLinks,
  openNewsroomLinkModal,
  featuredRoleData,
}) {
  const isAddLinkButtonDisabled =
    featuredRoleData.links && featuredRoleData.links.length >= 5;
  return (
    <div className="hs-block bg-white px-[30px] pt-[20px] pb-[30px]">
      <div className="flex items-center">
        <div className="flex gap-[10px] items-center">
          <LinkIcon className="fill-[#3371ff]" />
          <h2 className="font-semibold text-xl text-slate-700 tracking-tight">
            Links
          </h2>
        </div>
        <button
          className={`ml-auto text-[#3371ff] hover:text-[#3371ff] font-normal bg-[#3371ff10] hover:bg-[#3371ff15] text-sm cursor-pointer rounded-lg px-[20px] h-[42px] ${
            isAddLinkButtonDisabled ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={isAddLinkButtonDisabled ? null : openNewsroomLinkModal}
          disabled={isAddLinkButtonDisabled}
        >
          {isAddLinkButtonDisabled ? "Max Links Added" : "Add Link"}
        </button>
      </div>
      <div className="mt-4">
        {localNewsroomLinks?.length > 0 ? (
          <EditLandingPageLinks
            localNewsroomLinks={localNewsroomLinks}
            setLocalNewsroomLinks={setLocalNewsroomLinks}
          />
        ) : (
          <>
            <p className="text-sm">
              This is your Link Container. Click{" "}
              <span
                className="font-semibold text-[#3371ff] cursor-pointer"
                onClick={openNewsroomLinkModal}
              >
                Add Link
              </span>{" "}
              to start adding articles, podcasts, press releases, you name it.
            </p>
            <p className="mt-2 text-sm">
              We cap the amount of links you can add at{" "}
              <span className="font-semibold text-[#3371ff]">5</span> to ensure
              you highlight your best content!
            </p>
          </>
        )}
      </div>
    </div>
  );
}

export default LinkContainer;
