import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function PreviewBanner({
  roleId,
  authenticatedUser,
  createdByUser,
  postingOwner,
  isTemplateRole = false,
}) {
  useEffect(() => {
    const buttons = document.getElementsByTagName("button");
    const links = document.getElementsByTagName("a");

    for (let i = 0; i < buttons.length; i++) {
      buttons[i].setAttribute("data-disable-analytics", 1);
    }

    for (let i = 0; i < links.length; i++) {
      links[i].setAttribute("data-disable-analytics", 1);
    }
  }, []);
  return (
    <div className="fixed bottom-0 bg-[#3371ff] w-full text-center p-3">
      <p className="text-white text-sm">
        You're viewing this page in{" "}
        <span className="font-semibold">Preview Mode</span>. Analytics aren't
        tracked.{" "}
        {authenticatedUser === createdByUser ? (
          <>
            <Link
              to={`/pages/edit/${roleId}`}
              className="font-semibold underline"
            >
              Return To Editor
            </Link>{" "}
            |{" "}
          </>
        ) : (
          <>
            This {isTemplateRole ? "template" : "page"} was created
            {postingOwner === "Hiring Spree" ? " with ❤️" : null} by{" "}
            <span className="font-semibold">{postingOwner}</span> |{" "}
          </>
        )}
        <Link
          to={`/pages${isTemplateRole ? "/templates" : ""}`}
          className="font-semibold underline"
        >
          View All {isTemplateRole ? "Templates" : "Pages"}
        </Link>
      </p>
    </div>
  );
}

export default PreviewBanner;
