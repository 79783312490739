import React, { useState } from "react";
import Tooltip from "components/Tooltip";
import Location from "pages/LandingPage/Utilities/Formatting/Location";
import Tag from "components/Tag";
import { apiRoutes, makeApiRequest } from "constants/apiRoutes";
import CopyToClipboardIcon from "components/Buttons/CopyToClipboardIcon";
import { ReactComponent as EditIcon } from "assets/icons/pen-square.svg";
import { ReactComponent as Trash } from "assets/icons/trash.svg";
import { ReactComponent as PendingCheck } from "assets/icons/badge-check-line.svg";
import { ReactComponent as PublishedCheck } from "assets/icons/badge-check.svg";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as Clone } from "assets/icons/clone.svg";
import { ReactComponent as FilePlus } from "assets/icons/file-plus.svg";
import ConfirmationModal from "components/ConfirmationModal";
import { useAuth } from "util";
import { useCompany } from "util";

function PostingBlockWithActions({
  role,
  setLandingPages,
  setTemplates,
  isTemplate,
}) {
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const auth = useAuth();
  const { companyData } = useCompany();
  const isUserCreatedTemplate = isTemplate && role.userId === auth.user.id;

  const duplicateRole = async (roleToDuplicate, onSuccess) => {
    try {
      // Copy properties from roleToDuplicate and modify the necessary fields
      const { _id, ...duplicatedRoleData } = {
        ...roleToDuplicate,
        title: `${roleToDuplicate.title} - ${
          isTemplate ? "From Template" : "Copy"
        }`,
        // If duplicating a global template, we need to replace the companyId. In all other cases,
        // the existing companyId should match that of the authenticated user.
        companyId: companyData.companyId,
        userId: auth.user.id, // When duplicating a template, need to override the userId.
        isPublished: false,
        isTemplate: false, // Should be false both when duplicating a role and creating one from template
      };

      const response = await makeApiRequest(
        "post",
        apiRoutes.createPageByUserID(duplicatedRoleData.userId),
        auth.user,
        companyData.companyId,
        duplicatedRoleData
      );

      if (response.status === 201) {
        // Redirect to role newly created from template.
        // We don't need to refetch the roles list in this case, since the user won't be seeing that
        // page on account of the redirect.
        if (isTemplate) {
          window.location.href = `${window.location.origin}/pages/edit/${response.data._id}`;
          return;
        }

        const res = (
          await makeApiRequest(
            "get",
            apiRoutes.getPagesByUserID(roleToDuplicate.userId),
            auth.user,
            companyData.companyId
          )
        ).data;
        onSuccess(res);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const archiveRole = async (roleToArchive, onSuccess, isTemplateRole) => {
    try {
      const _id = roleToArchive._id;

      await makeApiRequest(
        "delete",
        apiRoutes.deletePageByUserAndPageID(roleToArchive.userId, _id),
        auth.user,
        companyData.companyId
      );

      const updatedRolesResponse = await makeApiRequest(
        "get",
        isTemplateRole
          ? apiRoutes.getTemplatesByUserID(roleToArchive.userId)
          : apiRoutes.getPagesByUserID(roleToArchive.userId),
        auth.user,
        companyData.companyId
      );
      const updatedRoles = await updatedRolesResponse.data;

      onSuccess(updatedRoles); // Assuming you have an onSuccess callback
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = () => {
    setConfirmationModalOpen(true);
  };

  const handleConfirmDelete = () => {
    archiveRole(role, isTemplate ? setTemplates : setLandingPages, isTemplate); // Pass the role's ID to the onDelete function
  };

  const handleCancelDelete = () => {
    // Handle cancel action or simply close the modal
    setConfirmationModalOpen(false);
  };

  const handleDuplicate = () => {
    duplicateRole(role, setLandingPages);
  };

  return (
    <>
      <div className="hs-block bg-white h-[60px] pl-3 pr-5 text-sm tracking-tight font-medium grid grid-cols-[2fr_1fr_1fr_1fr_1fr]">
        {/* col 1 */}
        <div className="pl-[5px] flex items-center gap-[10px]">
          {isTemplate ? null : role.isPublished ? (
            <PublishedCheck className="fill-[#3371ff]" />
          ) : (
            <PendingCheck className="stroke-slate-500" />
          )}
          <div>
            <p
              className={
                role.isPublished || isTemplate
                  ? "text-slate-900"
                  : "text-slate-500"
              }
            >
              {role.title}
            </p>
          </div>
        </div>

        {/* col 2 */}
        <div className="flex items-center">
          <p
            className={
              role.isPublished || isTemplate
                ? "text-slate-900"
                : "text-slate-500"
            }
          >
            {role.department}
          </p>
        </div>
        {/* col 2 */}
        <div className="flex items-center">
          <p
            className={
              role.isPublished || isTemplate
                ? "text-slate-900"
                : "text-slate-500"
            }
          >
            <Location
              city={role.city}
              state={role.state}
              country={role.country}
              remote={role.isRemote}
              hybrid={role.isHybrid}
            />
          </p>
        </div>

        {/* col 3 - show "Created By" if template, "CtaType" otherwise*/}
        <div className="flex items-center">
          {isTemplate ? (
            <p
              className={
                role.isPublished || isTemplate
                  ? "text-slate-900"
                  : "text-slate-500"
              }
            >
              {role.companyId === "global" ? "Hiring Spree" : role.postingOwner}
            </p>
          ) : role.ctaType === "Apply" || role.ctaType === "BookIntro" ? (
            <Tag
              text={role.ctaType === "Apply" ? "Apply" : "Book Call"}
              color={role.ctaType === "Apply" ? "green" : "blue"}
            />
          ) : null}
        </div>

        {/* col 4 */}
        <div className="flex items-center gap-[15px] justify-end">
          {!isTemplate && role.isPublished ? (
            <Tooltip text="Copy Page Link">
              <CopyToClipboardIcon
                textToCopy={`${
                  process.env.NODE_ENV === "development"
                    ? "http://localhost:3000"
                    : "https://hiringspree.io"
                }/${role.companyId}/${role._id}`}
              />
            </Tooltip>
          ) : null}

          <Tooltip text={isTemplate ? "Preview Template" : "Preview Page"}>
            <a href={`/pages/${role.companyId}/${role._id}/preview`}>
              <EyeIcon className="stroke-slate-500 hover:stroke-[#3371ff]" />
            </a>
          </Tooltip>
          <Tooltip
            text={isTemplate ? "Create Page from Template" : "Duplicate Page"}
          >
            <button
              className="flex items-center bg-transparent border-none cursor-pointer"
              onClick={handleDuplicate}
              aria-label={
                isTemplate ? "Create Page from Template" : "Duplicate Page"
              }
            >
              {isTemplate ? (
                <FilePlus className="stroke-slate-500 hover:stroke-[#3371ff]" />
              ) : (
                <Clone className="stroke-slate-500 hover:stroke-[#3371ff]" />
              )}
            </button>
          </Tooltip>
          {isTemplate && !isUserCreatedTemplate ? null : (
            <Tooltip text={isTemplate ? "Edit Template" : "Edit Page"}>
              <a href={`/pages/edit/${role._id}`}>
                <EditIcon className="stroke-slate-500 hover:stroke-[#3371ff]" />
              </a>
            </Tooltip>
          )}
          {isTemplate && !isUserCreatedTemplate ? null : (
            <Tooltip text={isTemplate ? "Archive Template" : "Archive Page"}>
              <button
                className="flex items-center bg-transparent border-none cursor-pointer"
                onClick={handleDelete}
                aria-label="Delete Role"
              >
                <Trash className="stroke-slate-500 hover:stroke-[#3371ff]" />
              </button>
            </Tooltip>
          )}
          {isConfirmationModalOpen && (
            <ConfirmationModal
              title="You sure about that?"
              message="Are you sure you want to delete this page?"
              onConfirm={handleConfirmDelete}
              onCancel={handleCancelDelete}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default PostingBlockWithActions;
