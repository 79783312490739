import React from "react";

function BlockTitle({ title, children }) {
  return (
    <div className="flex items-center gap-[10px]">
      {children}
      <h2 className="font-semibold tracking-tight text-slate-900 text-xl dark:text-slate-200">
        {title}
      </h2>
    </div>
  );
}

export default BlockTitle;
