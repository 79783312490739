import React from "react";
import Meta from "../../components/Meta";
import useWindowDimensions from "hooks/useWindowDimensions";

function AboutPage(props) {
  const isMobileScreen = useWindowDimensions();

  return (
    <>
      <Meta title="Our Story" description="Learn about our company and team" />

      <div
        className={`${
          isMobileScreen ? "mx-[20px] p-[30px]" : "mx-[150px] p-[60px] "
        } flex flex-col gap-4 bg-white block-shadow rounded-[30px] text-slate-800 my-[30px]`}
      >
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-3">
          Hey friends 👋
        </h1>
        <p>
          My name is{" "}
          <a
            href="https://linkedin.com/in/thisismickstallone"
            className="font-semibold text-blue-600"
          >
            Mick
          </a>
          , and I'm the founder of Hiring Spree.
        </p>
        <p>
          Hiring Spree started to come together in March of 2021 when I got laid
          off from Thumbtack. I was a Senior Sourcer living in San Francisco
          prior to the layoff, but after the zoom meeting ended and my computer
          was shut down remotely, I found myself in the new reality where
          Recruiters were in high supply and non-existent demand. I took a bet
          by enrolling in an Intro to Programming Nanodegree on Udacity, and
          upon completion I was on the right path for self-directed learning.
        </p>
        <p>
          I left San Francisco for Joshua Tree, which is a wonderful place to
          wait out a global pandemic. Thanks to the infinite amount educational
          content on the internet, I was able to continue my remote education.
          When I first touched down in Palm Springs, I started coding my first
          real project: Hiring Spree. I set out to build a job board to help me
          search for work, and I built something pretty cool for myself, but
          ultimately shelved it when I got a job at Attentive. Recruiters were
          in high demand again, and I started as a Senior Sourcer before quickly
          getting promoted to leading the Sourcing team, rolling up directly
          into the SVP of Recruiting.
        </p>
        <p>
          I was visiting a friend in New York when I got the email, "Your role
          has been eliminated". For the second time in 3 years I had been laid
          off. Without flinching, I took Hiring Spree off the shelf and started
          coding again. I didn't know exactly "what" I was setting out to build,
          or it would become, but I knew I wanted to pull the thread. Since
          then, I've encountered Recruiters, Sourcers, Candidates, Advisors, and
          Friends who have helped to shape our direction.
        </p>
        <div>
          <p>
            If you're in{" "}
            <span className="font-semibold text-blue-600">
              Talent Acquisition
            </span>
            , we're building your co-pilot.
          </p>
          <p className="mt-2">
            If you're a{" "}
            <span className="font-semibold text-blue-600">Candidate</span>,
            we're building better introductions.
          </p>
        </div>

        <p>
          If you want to get in touch or just want to say hello, drop me a line
          at{" "}
          <span className="font-semibold text-blue-600">
            mick@hiringspree.io
          </span>
          . I'd love to hear from you.
        </p>
        <div className="mt-4">
          <p>
            <span className="font-semibold text-slate-800">Mick Stallone</span>
            <br />
            <span className="text-slate-500">Founder, Hiring Spree</span>
          </p>
        </div>
      </div>
    </>
  );
}

export default AboutPage;
