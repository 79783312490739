import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "components/Navigation/Navbar";
import Footer from "pages/Home/Sections/Footer";

function Layout() {
  return (
    <div className="bg-[#fafbff]">
      <Navbar isLoggedIn={false} />
      <Outlet />
      <Footer />
    </div>
  );
}

export default Layout;
