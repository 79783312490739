// Modules
import React from "react";

function VideoBlock({ ...props }) {
  return (
    <div className="videoCard">
      <iframe
        className="rounded-[20px] block-shadow"
        src={props.content}
        title="Video Player"
        frameBorder="0"
        allowFullScreen
      />
    </div>
  );
}

export default VideoBlock;
