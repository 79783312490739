import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import InputHeader from "./InputHeader";
import { ReactComponent as BuildingUser } from "assets/icons/building-user.svg";
import { ReactComponent as XMark } from "assets/icons/xmark.svg";
import { Input } from "components/Input";
import { TextArea } from "components/TextArea";
import { client } from "constants/api";
import { apiRoutes } from "constants/apiRoutes";
import { useAuth } from "util";
import InputModal from "./InputModal";
import CompanyComponentEditWarning from "./CompanyComponentEditWarning";
import PrimaryActionButton from "components/Buttons/PrimaryActionButton";
import { useCompany } from "util";

function PersonnelBlockInput({
  close,
  setSelectedComponent,
  isEditing,
  existingContent = {},
  refetch,
  isTeamsPage,
  handleCreateSharedComponent,
  handleEditSharedComponent,
}) {
  const [title, setTitle] = useState(isEditing ? existingContent.title : "");
  const [description, setDescription] = useState(
    isEditing ? existingContent.description : ""
  );
  const [personnel, setPersonnel] = useState(
    isEditing ? existingContent.content.map((person) => ({ ...person })) : []
  );
  const auth = useAuth();
  const { companyData } = useCompany();
  const companyId = companyData.companyId;
  const { roleId } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a new block object or update an existing one
    const blockData = {
      blockType: "personnel",
      title,
      description,
      content: personnel,
      ...(isEditing && {
        id: existingContent.id,
        order: existingContent.order,
      }), // Include id and order when editing
    };
    const url = isEditing
      ? apiRoutes.editBlock(companyId, roleId, existingContent.id)
      : apiRoutes.createBlock(companyId, roleId);

    try {
      let response;
      if (isTeamsPage) {
        response = isEditing
          ? await handleEditSharedComponent(blockData)
          : await handleCreateSharedComponent(blockData);
      } else {
        response = await client[isEditing ? "put" : "post"](url, blockData, {
          headers: {
            Authorization: auth.user
              ? `${auth.user.id}.${auth.user.accessToken}`
              : "",
          },
        });
      }

      if (response.status < 300) {
        if (isEditing) {
          console.log("Block updated successfully");
          refetch();
        } else {
          console.log("Block created successfully");
        }
        setTitle("");
        setDescription("");
        setPersonnel([]);
        close(true);
      }
    } catch (error) {
      console.error("Error sending request:", error);
    }
  };

  const handleAddPerson = () => {
    setPersonnel([
      ...personnel,
      { name: "", title: "", linkedinUrl: "", imageUrl: "" },
    ]);
  };

  const handleRemovePerson = (index) => {
    const updatedPersonnel = [...personnel];
    updatedPersonnel.splice(index, 1);
    setPersonnel(updatedPersonnel);
  };

  const handlePersonChange = (index, field, value) => {
    const updatedPersonnel = [...personnel];
    updatedPersonnel[index][field] = value;
    setPersonnel(updatedPersonnel);
  };

  useEffect(() => {
    if (isEditing) {
      // Pre-fill the form fields with existing content when in editing mode
      setTitle(existingContent.title);
      setPersonnel(existingContent.content.map((person) => ({ ...person })));
    }
  }, [isEditing, existingContent]);

  const unsavedChanges = isEditing
    ? title !== existingContent.title ||
      JSON.stringify(personnel) !== JSON.stringify(existingContent.content)
    : title !== "" || personnel.length !== 0;

  return (
    <InputModal size="md">
      <InputHeader
        icon={<BuildingUser className="stroke-[#3371ff]" />} // Pass the appropriate icon for awards
        title="Personnel"
        isEditing={isEditing}
        existingContent={existingContent}
        setSelectedComponent={setSelectedComponent}
        close={close}
        unsavedChanges={unsavedChanges}
      />
      <form
        onSubmit={handleSubmit}
        className="flex flex-col mt-[20px] gap-[20px]"
      >
        <div className="flex flex-col gap-[10px]">
          <Input
            setValue={(e) => setTitle(e.target.value)}
            value={title}
            required={true}
            label="Title"
          />
          <TextArea
            setValue={(e) => setDescription(e.target.value)}
            value={description}
            required={true}
            label="Description"
          />
        </div>
        <div className="flex flex-col gap-[10px]">
          <label className="text-[13px] font-medium tracking-tight text-slate-700">
            Personnel
          </label>
          {personnel.map((person, index) => (
            <div key={index} className="person-group">
              {index > 0 && (
                <button
                  className="stroke-[#ff0000] w-full flex justify-end mt-2"
                  onClick={() => handleRemovePerson(index)}
                >
                  <XMark />
                </button>
              )}
              <Input
                setValue={(e) =>
                  handlePersonChange(index, "name", e.target.value)
                }
                value={person.name}
                required={true}
                placeholder="Name"
              />
              <Input
                setValue={(e) =>
                  handlePersonChange(index, "title", e.target.value)
                }
                value={person.title}
                required={true}
                placeholder="Title"
              />
              <Input
                setValue={(e) =>
                  handlePersonChange(index, "linkedinUrl", e.target.value)
                }
                value={person.linkedinUrl}
                required={true}
                placeholder="LinkedIn URL"
              />
              <Input
                setValue={(e) =>
                  handlePersonChange(index, "imageUrl", e.target.value)
                }
                value={person.imageUrl}
                required={true}
                placeholder="Image URL"
              />
            </div>
          ))}
          <div>
            <button className="text-sm" type="button" onClick={handleAddPerson}>
              Add New Person
            </button>
          </div>
        </div>
        <CompanyComponentEditWarning sharedId={existingContent.sharedId} />
        <div className="ml-auto flex gap-[15px]">
          <PrimaryActionButton
            text={isEditing ? "Update Block" : "Create Block"}
            buttonType="submit"
            disabled={!unsavedChanges}
          />
        </div>
      </form>
    </InputModal>
  );
}

export default PersonnelBlockInput;
