import React, { useState } from "react";

const DateRangePicker = ({ onDateChange }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);
    if (newStartDate && endDate) {
      onDateChange(newStartDate, endDate);
    }
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    setEndDate(newEndDate);
    if (startDate && newEndDate) {
      onDateChange(startDate, newEndDate);
    }
  };

  return (
    <div className="flex flex-row gap-2">
      <div className="text-slate-700 border-slate-700 border border-solid bg-white block-shadow font-medium text-sm pl-5 pr-4 py-2.5 rounded-lg flex flex-row gap-2 items-center">
        <label className="text-[#3371ff]">From</label>
        <input type="date" value={startDate} onChange={handleStartDateChange} />
      </div>
      <div className="text-slate-700 border-slate-700 border border-solid bg-white block-shadow font-medium text-sm pl-5 pr-4 py-2.5 rounded-lg flex flex-row gap-2 items-center">
        <label className="text-[#3371ff]">To</label>
        <input type="date" value={endDate} onChange={handleEndDateChange} />
      </div>
    </div>
  );
};

export default DateRangePicker;
