import React from "react";
import { formatCurrency } from "util/format";

function formatCompensation(compType, compLow, compHigh) {
  const amount1 = formatCurrency(compLow);
  const amount2 =
    compLow !== compHigh && compHigh !== "0" && compHigh !== ""
      ? ` - ${formatCurrency(compHigh)}`
      : "";
  const label = compType === "salary" ? "per year" : "per hour";

  return `${amount1}${amount2} ${label}`;
}

function Compensation({ compType, compLow, compHigh }) {
  const formattedCompensation = formatCompensation(compType, compLow, compHigh);

  return <>{formattedCompensation}</>;
}

export default Compensation;
