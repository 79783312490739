import React from "react";
import { ReactComponent as XMark } from "assets/icons/xmark.svg";

function Modal({
  title,
  closeModal,
  children,
  size = "",
  bgColor = "white",
  bgColorDark,
}) {
  // Define size options and their corresponding pixel values
  const sizeOptions = {
    sm: "w-[450px]",
    md: "w-[600px]",
    lg: "w-[800px]",
  };

  // Determine the width based on the size prop or use the default size
  const width = sizeOptions[size] || sizeOptions.lg;

  return (
    <div className="fixed top-0 left-0 px-2 w-full h-full bg-[#0e56ffb4] flex items-center justify-center z-[1000]">
      <div
        className={`${width} p-[30px] bg-${bgColor} ${
          bgColorDark ? `dark:bg-${bgColorDark}` : ""
        } rounded-[30px]`}
      >
        <div className="modal-input-header flex items-center gap-[10px] mb-[15px]">
          <h2 className="font-semibold text-xl text-slate-700 tracking-tight">
            {title}
          </h2>
          {closeModal === "disabled" ? null : (
            <button
              className="ml-auto flex items-center bg-transparent border-none cursor-pointer stroke-slate-700 hover:stroke-[#ff0000]"
              onClick={closeModal}
            >
              <XMark className="w-[18px]" />
            </button>
          )}
        </div>
        {children}
      </div>
    </div>
  );
}

export default Modal;
