import React, { useState } from "react";
import { ReactComponent as EditIcon } from "assets/icons/pen-square.svg";
import { ReactComponent as XMark } from "assets/icons/xmark.svg";
import { ReactComponent as LinkIcon } from "assets/icons/link-alt-1.1.svg";
import { ReactComponent as Facebook } from "assets/icons/social/facebook_icon.svg";
import { ReactComponent as Twitter } from "assets/icons/social/twitter_icon.svg";
import { ReactComponent as LinkedIn } from "assets/icons/social/linkedin_icon.svg";
import { ReactComponent as Crunchbase } from "assets/icons/social/crunchbase_icon.svg";
import { ReactComponent as GitHub } from "assets/icons/social/github_icon.svg";
import { ReactComponent as Youtube } from "assets/icons/social/youtube_icon.svg";
import { ReactComponent as Discord } from "assets/icons/social/discord_icon.svg";
import { ReactComponent as TikTok } from "assets/icons/social/tiktok_icon.svg";
import { ReactComponent as WhatsApp } from "assets/icons/social/whatsapp_icon.svg";
import { ReactComponent as SnapChat } from "assets/icons/social/snapchat_icon.svg";
import { ReactComponent as Instagram } from "assets/icons/social/instagram_icon.svg";
import { ReactComponent as Glassdoor } from "assets/icons/social/glassdoor_icon.svg";
import { ReactComponent as Medium } from "assets/icons/social/medium_icon.svg";
import { ReactComponent as Pinterest } from "assets/icons/social/pinterest_icon.svg";
import { ReactComponent as Telegram } from "assets/icons/social/telegram_icon.svg";
import { ReactComponent as Reddit } from "assets/icons/social/reddit_icon.svg";
import { ReactComponent as Vimeo } from "assets/icons/social/vimeo_icon.svg";
import { ReactComponent as Dribbble } from "assets/icons/social/dribbble_icon.svg";
import EditSocialLinks from "./EditSocialLinks";
import CopyToClipboardIcon from "components/Buttons/CopyToClipboardIcon";
import Tooltip from "components/Tooltip";
import ConfirmationModal from "components/ConfirmationModal";
import { useCompany } from "util";

const socialLinkData = [
  { platform: "twitter", icon: <Twitter />, name: "Twitter" },
  { platform: "linkedin", icon: <LinkedIn />, name: "LinkedIn" },
  { platform: "dribbble", icon: <Dribbble />, name: "Dribbble" },
  { platform: "crunchbase", icon: <Crunchbase />, name: "Crunchbase" },
  { platform: "github", icon: <GitHub />, name: "GitHub" },
  { platform: "youtube", icon: <Youtube />, name: "YouTube" },
  { platform: "instagram", icon: <Instagram />, name: "Instagram" },
  { platform: "facebook", icon: <Facebook />, name: "Facebook" },
  { platform: "discord", icon: <Discord />, name: "Discord" },
  { platform: "glassdoor", icon: <Glassdoor />, name: "Glassdoor" },
  { platform: "tiktok", icon: <TikTok />, name: "TikTok" },
  { platform: "whatsapp", icon: <WhatsApp />, name: "WhatsApp" },
  { platform: "snapchat", icon: <SnapChat />, name: "SnapChat" },
  { platform: "medium", icon: <Medium />, name: "Medium" },
  { platform: "pinterest", icon: <Pinterest />, name: "Pinterest" },
  { platform: "telegram", icon: <Telegram />, name: "Telegram" },
  { platform: "reddit", icon: <Reddit />, name: "Reddit" },
  { platform: "vimeo", icon: <Vimeo />, name: "Vimeo" },
];

function SocialLinkWithActions({ socialLink, onDelete }) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const { userRole } = useCompany();

  const linkInfo = socialLinkData.find(
    (link) => link.platform === socialLink.platform
  );

  if (!linkInfo) {
    return null;
  }

  const handleDelete = () => {
    onDelete(socialLink.id);
  };

  const handleEditClick = () => {
    setIsEditModalOpen(true);
  };

  const handleModalClose = () => {
    setIsEditModalOpen(false);
  };

  return (
    <div className="flex justify-between">
      <div className="flex items-center gap-[20px]">
        <div className="h-[22px] w-[22px] fill-[#3371ff]">{linkInfo.icon}</div>
        <p className="text-sm">{socialLink.label}</p>
      </div>
      <div className="flex align-center gap-[15px]">
        <Tooltip text="Copy Link">
          <CopyToClipboardIcon textToCopy={socialLink.url} />
        </Tooltip>
        <Tooltip text="Follow Link">
          <a
            className="self-center fill-slate-500 hover:fill-[#3371ff]"
            href={socialLink.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkIcon />
          </a>
        </Tooltip>
        {userRole === "Employee" ? null : (
          <>
            <Tooltip text="Edit Link">
              <button onClick={handleEditClick}>
                <EditIcon className="stroke-slate-500 hover:stroke-[#3371ff]" />
              </button>
            </Tooltip>
            <Tooltip text="Delete Link">
              <button onClick={() => setIsConfirmationModalOpen(true)}>
                <XMark className="stroke-slate-500 hover:stroke-[#ff0000]" />
              </button>
            </Tooltip>
          </>
        )}
      </div>
      {isEditModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-[#3371ff75] flex items-center justify-center z-[1000]">
          <div className="w-[600px] hs-block p-[30px]">
            <EditSocialLinks
              socialLinkData={socialLink}
              onClose={handleModalClose}
            />
          </div>
        </div>
      )}
      {isConfirmationModalOpen && (
        <ConfirmationModal
          title={`Delete Link?`}
          message={`Are you sure you want to delete this link?`}
          onConfirm={() => handleDelete()}
          onCancel={() => setIsConfirmationModalOpen(false)}
        />
      )}
    </div>
  );
}

export default SocialLinkWithActions;
