import Submit from "components/Buttons/Submit";
import { Input } from "components/Input";
import Select from "components/Select";
import { TextArea } from "components/TextArea";
import {
  companySize,
  fundingStatus,
  fundingType,
  industries,
} from "constants/constants";
import { Controller } from "react-hook-form";

export const CreateEditTeamForm = ({ isSaving, control, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-[15px]">
      <Controller
        control={control}
        name="blurb"
        render={({ field }) => (
          <TextArea
            label="Description"
            value={field.value}
            setValue={field.onChange}
          />
        )}
      />

      <Controller
        control={control}
        name="companySize"
        render={({ field }) => (
          <Select
            placeholder="Company Size"
            value={field.value}
            options={companySize}
            onChange={field.onChange}
            label="Company Size"
          />
        )}
      />

      <Controller
        control={control}
        name="foundedDate"
        render={({ field }) => (
          <Input
            label="Year Founded"
            type="text"
            value={field.value}
            setValue={field.onChange}
          />
        )}
      />
      <Controller
        control={control}
        name="fundingStatus"
        render={({ field }) => (
          <Select
            placeholder="Funding Status"
            value={field.value}
            options={fundingStatus}
            onChange={(selectedOption) => field.onChange(selectedOption)}
            label="Funding Status"
          />
        )}
      />

      <Controller
        control={control}
        name="fundingType"
        render={({ field }) => (
          <Select
            placeholder="Funding Type"
            value={field.value}
            options={fundingType}
            onChange={field.onChange}
            label="Funding Type"
          />
        )}
      />

      <Controller
        control={control}
        name="homepageUrl"
        render={({ field }) => (
          <Input
            label="Homepage URL"
            type="text"
            value={field.value}
            setValue={field.onChange}
          />
        )}
      />

      <Controller
        control={control}
        name="hqLocation"
        render={({ field }) => (
          <Input
            label="HQ Location"
            type="text"
            value={field.value}
            setValue={field.onChange}
          />
        )}
      />

      <Controller
        control={control}
        name="industry"
        render={({ field }) => (
          <Select
            placement="top"
            placeholder="Industries"
            value={field.value}
            options={industries}
            onChange={field.onChange}
            multiple
            label="Industries"
          />
        )}
      />

      <Submit text={isSaving ? "Saving..." : "Save"} disabled={isSaving} />
    </form>
  );
};
