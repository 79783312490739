// Modules
import React from "react";
import LinkWithAnalytics from "pages/LandingPage/Components/Links/LinkWithAnalytics";

function LinksBlock({ data, homepageUrl, isMobileModal = false }) {
  // Check if data has a length greater than zero
  if (data && data.length > 0) {
    const articles = data.map((article, index) => (
      <li
        key={index + new Date().toISOString()}
        className="flex align-top pt-3 [&:not(:last-child)]:pb-3 [&:not(:last-child)]:border-solid border-slate-200 dark:border-slate-600 border-b"
      >
        <div className="gap-3 flex flex-row">
          <div className="text-sm text-[#3371ff] font-normal dark:font-medium flex flex-col gap-1">
            <LinkWithAnalytics
              url={article.url}
              text={article.title}
              title={`links-card:` + index}
            />
            <p className="text-slate-500 dark:text-slate-400 text-xs font-normal">
              {article.source}
            </p>
          </div>
        </div>
      </li>
    ));

    return (
      <div
        className={`flex-none w-[300px] lg:w-[250px] xl:w-[350px] top-0 sticky rounded-[20px] bg-[#f3f7ff] dark:bg-slate-800 ${
          isMobileModal ? "p-0" : "p-[30px]"
        }`}
      >
        <h2 className="font-semibold text-xl text-slate-900 dark:text-slate-200 tracking-tight">
          Links
        </h2>
        <div>
          <ul className="flex flex-col">{articles}</ul>
        </div>
      </div>
    );
  } else {
    // Return an <li> element with homepageUrl as the link for the empty state
    return (
      <div
        className={`flex-none w-[300px] lg:w-[250px] xl:w-[350px] top-0 sticky rounded-[20px] bg-[#f3f7ff] dark:bg-slate-800 ${
          isMobileModal ? "p-0" : "p-[30px]"
        }`}
      >
        <h2 className="font-semibold text-xl text-slate-900 dark:text-slate-200 tracking-tight">
          Links
        </h2>

        <div>
          <ul className="flex flex-col">
            <li className="flex align-top pt-3 pb-3 border-solid border-slate-200 dark:border-slate-600 border-b">
              <div className="gap-3 flex flex-row">
                <div className="text-sm text-[#3371ff] font-normal flex flex-col gap-1">
                  <LinkWithAnalytics
                    url={homepageUrl}
                    text="Visit our homepage to learn more about us!"
                    title="links-card-default"
                  />
                  {/* You can customize the text and styling as needed */}
                  <p className="text-slate-500 dark:text-slate-400 text-xs font-normal">
                    Company Website
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default LinksBlock;
