import React from "react";
import BlockTitle from "components/BlockTitle";
import Tag from "components/Tag";
import { ReactComponent as PendingCheck } from "assets/icons/badge-check-line.svg";
import { ReactComponent as PublishedCheck } from "assets/icons/badge-check.svg";

function EditorTitle({ isPublished, title, ctaType, isTemplateRole }) {
  return (
    <div className="flex items-center gap-[10px]">
      {isPublished ? (
        <PublishedCheck className="fill-[#3371ff]" />
      ) : (
        <PendingCheck className="stroke-slate-700" />
      )}
      <h2 className="font-semibold tracking-tight text-slate-900 text-xl">
        {title}
      </h2>
      <Tag
        text={
          isTemplateRole
            ? "Template Role"
            : ctaType === "Apply"
            ? "Apply"
            : "Book Call"
        }
        color={isTemplateRole ? "gray" : ctaType === "Apply" ? "green" : "blue"}
      />
    </div>
  );
}

export default EditorTitle;
