import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ReactComponent as ChevronDown } from "../assets/icons/chevron-down.svg";
import { ReactComponent as Tick } from "../assets/icons/tick.svg";

/**DEPRECATED: use StyledSelect.js instead */
const CustomSelect = ({
  options,
  multiple,
  value,
  onChange,
  placeholder = "Select",
  label,
  placement = "bottom",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const listRef = useRef(null);
  const [uniqueId] = useState();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ref.current &&
        listRef.current &&
        !ref.current.contains(event.target) &&
        !listRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const selectedValue = useMemo(() => {
    if (value && Array.isArray(value) && value.length) {
      return value.map((item) => item).join(", ");
    }
    if (value && !Array.isArray(value)) return value;
    return placeholder;
  }, [value, placeholder]);

  const toggleOpen = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const onSelect = useCallback(
    (event) => {
      let res = event;
      if (multiple) {
        if (!Array.isArray(value)) {
          res = [];
        }
        const isValueAlreadySelected = !!value.find((item) => item === event);
        res = isValueAlreadySelected
          ? value.filter((item) => item !== event)
          : [...value, event];
      }
      if (!multiple) {
        setIsOpen(false);
      }
      onChange(res);
    },
    [multiple, value, onChange]
  );

  const renderSelectedIcon = useCallback(
    (item) => {
      if (value) {
        const isSelected = Array.isArray(value)
          ? !!value.find((el) => el === item)
          : value === item;
        if (isSelected) {
          return <Tick />;
        }
      }
      return;
    },
    [value]
  );

  const renderPlacement = useMemo(() => {
    return placement === "top"
      ? label
        ? "top-[-68px]"
        : "top-[-93px]"
      : label
      ? "top-[50px]"
      : "top-[25px]";
  }, [placement]);

  return (
    <div id={uniqueId} className={"relative"}>
      {label && (
        <label className="text-[13px] font-medium tracking-tight text-slate-700">
          {label}
        </label>
      )}
      <div
        ref={ref}
        onClick={toggleOpen}
        className="px-3 flex gap-2 justify-between items-center h-[36px] text-sm mt-1 bg-slate-100 rounded-md cursor-pointer"
      >
        <p>{selectedValue}</p>
        <div className={isOpen ? "rotate-180" : "rotate-0"}>
          <ChevronDown className="w-[18px] fill-black" />
        </div>
      </div>
      {isOpen && options && options.length && (
        <div
          ref={listRef}
          className={`z-10 py-2 mt-5 gap-1 absolute px-3 hs-block rounded-md flex flex-col  bg-[#fff] min-h-[25px] max-h-[100px] w-full overflow-auto ${renderPlacement}`}
        >
          {options.map((item, index) => (
            <>
              <div
                className="flex flex-row justify-between items-center text-sm hover:text-[#3371ff] w-full cursor-pointer"
                onClick={() => onSelect(item)}
                key={index}
              >
                {item}
                {renderSelectedIcon(item)}
              </div>
            </>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
