// Modules
import React from "react";

function ImageBlock({ ...item }) {
  return (
    <>
      <img
        src={item.content}
        alt={item.title}
        style={{ maxWidth: "100%" }}
        className="hs-block"
      />
    </>
  );
}

export default ImageBlock;
