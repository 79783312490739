import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/img/hs_logo_black.png";
import exampleUsage from "../../../assets/img/exampleUsage.png";

function PricingSection({ isMobileScreen }) {
  return (
    <div
      className={`${
        isMobileScreen
          ? "flex flex-col-reverse  mx-[20px] mb-[30px]"
          : "grid grid-cols-2"
      } gap-[30px]`}
    >
      <div
        className={`${
          isMobileScreen
            ? "my-0 p-[30px] text-center"
            : "px-[60px] py-[90px] my-[60px]"
        }
          bg-white block-shadow rounded-xl`}
      >
        {!isMobileScreen ? (
          <>
            <img
              src={logo}
              className="h-[60px] w-[60px] block-shadow rounded-full mb-4"
              alt="logo"
            />
          </>
        ) : null}

        <h1
          className={`${
            isMobileScreen
              ? "text-[42px] leading-[3rem]"
              : "text-[60px] leading-[4rem]"
          } tracking-tight font-bold text-slate-900`}
        >
          As many pages <br />{" "}
          <span className="text-[#3370ff]">as you want</span>.
        </h1>
        <p className="mt-4 mb-8 text-[18px] font-normal tracking-normal text-slate-700">
          Send <span className="font-semibold text-[#3371ff]">unlimited</span>,{" "}
          personalized landing pages, and A/B test without limitation.
        </p>
        <Link
          to="/auth/signup"
          className="w-full rounded-xl px-6 py-2.5 text-md font-medium bg-gradient-to-b from-blue-400 via-blue-500 to-indigo-500 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#3371ff]"
        >
          Signup for $19 per month
        </Link>
      </div>
      <div
        className={`${
          isMobileScreen ? "py-[40px] my-0" : ""
        } my-[60px] flex items-center m-auto bg-[#202635] px-[40px] rounded-xl`}
      >
        <img
          src={exampleUsage}
          className="rounded-xl block-shadow"
          alt="example usage"
        />
      </div>
    </div>
  );
}

export default PricingSection;
