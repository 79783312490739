import React from "react";
import TeamDetailsView from "./TeamDetailsView";
import { useEditCompany } from "hooks/useEditCompany";
import { CreateEditTeamForm } from "./CreateEditTeamForm";
import { ReactComponent as Buildings } from "assets/icons/buildings.svg";
import { useCompany } from "util";

function EditTeam({ companyData, id, toggleEditForm, editTeamInfo }) {
  const { userRole } = useCompany();
  const { control, handleSubmit, isSaving } = useEditCompany(
    id,
    toggleEditForm
  );

  return (
    <div className="bg-white block-shadow rounded-xl p-[30px] flex flex-col gap-[20px]">
      <div className="flex items-center gap-[10px]">
        <div className="flex gap-[10px] items-center">
          <Buildings className="fill-[#3371ff]" />
          <h2 className="font-semibold text-xl text-slate-700 tracking-tight">
            Company Information
          </h2>
        </div>
        {userRole === "Employee" ? null : (
          <button
            onClick={toggleEditForm}
            className="text-sm text-[#3371ff] ml-auto"
          >
            {editTeamInfo ? (
              <span className="text-red-500">cancel</span>
            ) : (
              "edit"
            )}
          </button>
        )}
      </div>
      {editTeamInfo ? (
        <CreateEditTeamForm
          control={control}
          handleSubmit={handleSubmit}
          isSaving={isSaving}
        />
      ) : (
        <TeamDetailsView companyData={companyData} />
      )}
    </div>
  );
}

export default EditTeam;
