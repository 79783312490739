import React from "react";
import Modal from "./Modal";
import PrimaryActionButton from "./Buttons/PrimaryActionButton";
import SecondaryActionButton from "./Buttons/SecondaryActionButton";

const ConfirmationModal = ({ title, message, onConfirm, onCancel }) => {
  const handleConfirm = () => {
    onConfirm();
    // Optionally, close the modal or reset its state
  };

  const handleCancel = () => {
    onCancel();
    // Optionally, close the modal or reset its state
  };

  return (
    <Modal title={title} closeModal={onCancel} size="sm">
      <p className="mb-8 text-slate-700">{message}</p>
      <div className="flex mt-[15px] ml-auto gap-[10px]">
        <SecondaryActionButton text="Cancel" action={handleCancel} />
        <PrimaryActionButton
          text="Confirm"
          buttonType="button"
          action={handleConfirm}
        />
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
