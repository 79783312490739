import React from "react";
import Auth from "./Auth";
import AuthFooter from "./AuthFooter";
import logo from "../../assets/img/hs_logo_black.png";

function AuthSection(props) {
  // Options by auth type
  const optionsByType = {
    signup: {
      // Top Title
      title: "Let's get you an account",
      // Button text
      buttonAction: "Subscribe for $19/month",
      // Footer text and links
      showFooter: true,
      signinText: "Already have an account?",
      signinAction: "Sign in",
      signinPath: "/auth/signin",
      // Terms and privacy policy agreement
      showAgreement: true,
    },
    signin: {
      title: "Welcome back!",
      buttonAction: "Sign in",
      showFooter: true,
      signupAction: "Create an account",
      signupPath: "/auth/signup",
      forgotPassAction: "Forgot Password?",
      forgotPassPath: "/auth/forgotpass",
    },
    forgotpass: {
      title: "Forgot your password?",
      buttonAction: "Reset password",
      showFooter: true,
      signinText: "Remember it after all?",
      signinAction: "Sign in",
      signinPath: "/auth/signin",
    },
    changepass: {
      title: "Choose a new password",
      buttonAction: "Change password",
    },
  };

  // Ensure we have a valid auth type
  const type = optionsByType[props.type] ? props.type : "signup";

  // Get options object for current auth type
  const options = optionsByType[type];

  return (
    <section className="flex items-center h-screen bg-gradient-to-r from-blue-400 via-blue-500 to-indigo-500">
      <div className="container mx-auto max-w-sm text-center bg-white p-[30px] rounded-[30px] block-shadow">
        <img
          src={logo}
          alt="logo"
          className="h-[70px] mx-auto mb-4 block-shadow rounded-full"
        />
        <h1 className="mb-5 font-semibold tracking-tight text-slate-900 text-xl">
          {options.title}
        </h1>
        <Auth
          type={type}
          buttonAction={options.buttonAction}
          providers={props.providers}
          afterAuthPath={props.afterAuthPath}
          key={type}
        />

        {options.showFooter && <AuthFooter type={type} {...options} />}
      </div>
    </section>
  );
}

export default AuthSection;
