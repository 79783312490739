import React, { useEffect, useState } from "react";
import Meta from "./../components/Meta";
import PageLoader from "./../components/PageLoader";
import { useAuth, requireAuth } from "../util";
import { useRouter } from "../util";
import { redirectToCheckout } from "../util";
import logo from "../assets/img/hs_logo_black.png";
import { NavLink } from "react-router-dom";

function PurchasePage(props) {
  const router = useRouter();
  const auth = useAuth();
  const [formAlert, setFormAlert] = useState();

  useEffect(() => {
    if (
      auth.user.stripeSubscriptionStatus === "active" &&
      !auth.user.showOnboardingFlow
    ) {
      // If user already has an active plan
      // and has completed onboarding
      // then take them to Account Home
      router.navigate("/pages");
    } else if (
      auth.user.stripeSubscriptionStatus === "active" &&
      auth.user.showOnboardingFlow
    ) {
      router.navigate("/onboarding");
    } else {
      // Otherwise go to checkout
      redirectToCheckout(router.query.plan).catch((error) => {
        setFormAlert({
          type: "error",
          message: error.message,
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Meta title="Purchase" />
      <PageLoader>
        {formAlert && (
          <>
            <Meta title="Page not found" />
            <section className="flex items-center h-screen bg-gradient-to-r from-blue-400 via-blue-500 to-indigo-500 px-[20px]">
              <div className="container mx-auto max-w-lg text-center bg-white p-[50px] rounded-[30px] block-shadow">
                <img
                  src={logo}
                  alt="logo"
                  className="h-[70px] mx-auto mb-4 block-shadow rounded-full"
                />

                <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                  Something went wrong...
                </h1>
                <p className="mt-6 text-base leading-7 text-gray-600">
                  There was an Error: {formAlert.message}
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                  <NavLink
                    to="/"
                    className="bg-[#3371ff] text-white border-none rounded-[10px] font-medium text-sm px-6 py-3"
                  >
                    Go to home page
                  </NavLink>
                </div>
              </div>
            </section>
          </>
        )}
      </PageLoader>
    </>
  );
}

export default requireAuth(PurchasePage);
