import React from "react";
import User from "./User";
import { useAuth } from "util";
import { useCompany } from "util";
import Meta from "components/Meta";

function Settings() {
  const auth = useAuth();
  const { companyData } = useCompany();

  return (
    <>
      <Meta title="Settings" />
      <User user={auth.user} company={companyData} />
    </>
  );
}

export default Settings;
