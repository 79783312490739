import React, { useState } from "react";
import { useQuery } from "react-query";
import { useAuth } from "util";
import { apiRoutes, makeApiRequest } from "constants/apiRoutes";
import StyledSelect from "components/StyledSelect";
import { useCompany } from "util";
import { ReactComponent as SwitchHorizontal } from "../../assets/icons/switch-horizontal.svg";

const CompanySwitch = () => {
  const auth = useAuth();
  const { companyData, setSelectedCompany } = useCompany();
  const [isSelectorVisible, setIsSelectorVisible] = useState(false);

  const currentCompanyId = companyData.companyId;
  const companyIds = auth.user.permissions.map(
    (permission) => permission.companyId
  );

  const fetchCompanyNames = async (companyIds) => {
    const response = await makeApiRequest(
      "get",
      apiRoutes.companyDataByIds(companyIds),
      auth.user,
      companyData.companyId
    );
    return response.data;
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ["companies", { companyIds }],
    queryFn: () => fetchCompanyNames(companyIds),
  });

  if (isLoading) {
    return null;
  }

  if (error) {
    console.error(
      `Could not load company data for: ${auth.user.permissions
        .map((permission) => permission.companyId)
        .join(", ")}`
    );
    return null;
  }

  const companyOptions = data.map((company) => ({
    value: company.companyId,
    label: company.companyName,
  }));

  const companyImageSrc = `https://hs-icons.s3.us-east-2.amazonaws.com/${companyData.companyId}square.png`;
  function onError(e) {
    e.target.src =
      "https://hs-icons.s3.us-east-2.amazonaws.com/hs_logo_blue.png";
  }

  return (
    <div className="relative">
      {isSelectorVisible && (
        <div className="hs-block flex items-center gap-3 absolute left-[65px] bottom-1/2 transform translate-y-1/2 w-max bg-white px-3 py-2 rounded z-50">
          <div className="text-sm tracking-tight font-medium text-slate-900">
            Select company:
          </div>

          <StyledSelect
            options={companyOptions}
            value={companyOptions.find(
              (option) => option.value === currentCompanyId
            )}
            onChange={(event) => {
              setSelectedCompany(event.value);
              setIsSelectorVisible(false);
            }}
            menuPlacement="top"
          />
        </div>
      )}
      <button onClick={() => setIsSelectorVisible(!isSelectorVisible)}>
        <div className="flex items-end">
          <img
            className="h-[45px] w-[45px] rounded-full bg-white border-[2px] border-solid border-slate-300"
            src={companyImageSrc}
            onError={(e) => onError(e)}
            alt="logo"
          />
          <SwitchHorizontal
            className={`h-5 w-5  ml-[-15px] bg-white stroke-slate-500 p-0.5 rounded-full border-[2px] border-solid border-slate-300`}
          />
        </div>
      </button>
    </div>
  );
};

export default CompanySwitch;
