import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useAuth } from "../../../util";
import PrimaryActionButton from "components/Buttons/PrimaryActionButton";

function SettingsGeneral(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: auth.user.name || "",
      userTitle: auth.user.userTitle || "",
      userLinkedIn: auth.user.userLinkedIn || "",
      userImage: auth.user.userImage || "",
    },
  });

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);
    data.name = `${data.firstName} ${data.lastName}`;

    return auth
      .updateProfile(data)
      .then(() => {
        // Set success status
        props.onStatus({
          type: "success",
          message: "Your profile has been updated",
        });
        props.closeModal();
      })
      .catch((error) => {
        if (error.code === "auth/requires-recent-login") {
          props.onStatus({
            type: "requires-recent-login",
            // Resubmit after reauth flow
            callback: () => onSubmit(data),
          });
        } else {
          // Set error status
          props.onStatus({
            type: "error",
            message: error.message,
          });
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="inline-flex gap-2">
        <div>
          <label className="text-[13px] font-medium tracking-tight text-slate-700">
            First Name
          </label>
          <input
            className="text-sm w-full mt-1 py-2 px-3 bg-slate-100 rounded-md"
            {...register("firstName", {
              required: "Please enter your first name",
            })}
            type="text"
            placeholder="First name"
            defaultValue={auth.user.firstName}
          />
          {errors.name && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.firstName.message}
            </p>
          )}
        </div>
        <div>
          <label className="text-[13px] font-medium tracking-tight text-slate-700">
            Last Name
          </label>
          <input
            className="text-sm w-full mt-1 py-2 px-3 bg-slate-100 rounded-md"
            {...register("lastName", {
              required: "Please enter your last name",
            })}
            type="text"
            placeholder="Last name"
            defaultValue={auth.user.lastName}
          />
          {errors.name && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.name.message}
            </p>
          )}
        </div>
      </div>
      <div className="mt-3">
        <label className="text-[13px] font-medium tracking-tight text-slate-700">
          Job Title
        </label>
        <input
          className="text-sm w-full mt-1 py-2 px-3 bg-slate-100 rounded-md"
          {...register("userTitle", {
            required: "What do you do?",
          })}
          type="text"
          placeholder="Job Title"
          defaultValue={auth.user.userTitle}
        />
        {errors.jobTitle && (
          <p className="mt-1 text-sm text-left text-red-600">
            {errors.userTitle.message}
          </p>
        )}
      </div>
      <div className="mt-3">
        <label className="text-[13px] font-medium tracking-tight text-slate-700">
          LinkedIn Profile
        </label>
        <input
          className="text-sm w-full mt-1 py-2 px-3 bg-slate-100 rounded-md"
          {...register("userLinkedIn", {
            required: "LinkedIn Profile",
          })}
          type="url"
          placeholder="LinkedIn Profile"
          defaultValue={auth.user.userLinkedIn}
        />
        {errors.linkedInUrl && (
          <p className="mt-1 text-sm text-left text-red-600">
            {errors.userLinkedIn.message}
          </p>
        )}
      </div>
      <div className="flex justify-end mt-[20px]">
        <PrimaryActionButton
          text={pending ? "..." : "Save"}
          type="submit"
          disabled={pending}
        />
      </div>
    </form>
  );
}

export default SettingsGeneral;
