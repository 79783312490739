import React from "react";
import TextBlockInput from "../BlockInputs/TextBlockInput";
import VideoBlockInput from "../BlockInputs/VideoBlockInputs";
import ImageBlockInput from "../BlockInputs/ImageBlockInputs";
import HeroBlockInput from "../BlockInputs/HeroBlockInputs";
import PersonnelBlockInput from "../BlockInputs/PersonnelBlockInputs";
import EmbedBlockInput from "../BlockInputs/EmbedBlockInputs";

const GetForm = (
  blockType,
  onClose,
  setSelectedComponent,
  isEditing,
  existingContent,
  refetch
) => {
  switch (blockType) {
    case "text":
      return (
        <TextBlockInput
          close={onClose}
          setSelectedComponent={setSelectedComponent}
          isEditing={isEditing}
          existingContent={existingContent} 
          refetch={refetch}
        />
      );
    case "video":
      return (
        <VideoBlockInput
          close={onClose}
          setSelectedComponent={setSelectedComponent}
          isEditing={isEditing}
          existingContent={existingContent} 
          refetch={refetch}
        />
      );
    case "image":
      return (
        <ImageBlockInput
          close={onClose}
          setSelectedComponent={setSelectedComponent}
          isEditing={isEditing}
          existingContent={existingContent} 
          refetch={refetch}
        />
      );
    case "hero":
      return (
        <HeroBlockInput
          close={onClose}
          setSelectedComponent={setSelectedComponent}
          isEditing={isEditing}
          existingContent={existingContent} 
          refetch={refetch}
        />
      );
    case "personnel":
      return (
        <PersonnelBlockInput
          close={onClose}
          setSelectedComponent={setSelectedComponent}
          isEditing={isEditing}
          existingContent={existingContent} 
          refetch={refetch}
        />
      );
    case "linkedin-post":
    case "twitter-post":
    case "twitter-timeline":
      return (
        <EmbedBlockInput
          close={onClose}
          setSelectedComponent={setSelectedComponent}
          isEditing={isEditing}
          existingContent={existingContent} 
          refetch={refetch}
        />
      );
    default:
      return null; // Handle unknown block types as needed
  }
};

export default GetForm;
