import React from "react";
import { apiRoutes, makeApiRequest } from "../../constants";
import { useAuth } from "util";
import { useCompany } from "util";

function CreateRoleFromTemplateButton({ roleData }) {
  const auth = useAuth();
  const { companyData } = useCompany();

  const handleDuplicateRole = async () => {
    try {
      // Copy properties from roleToDuplicate and modify the necessary fields
      const { _id, ...duplicatedRoleData } = {
        ...roleData,
        title: `${roleData.title} - From Template`,
        // If creating role from a global template, we need to replace the companyId. In all other cases,
        // the existing companyId should match that of the authenticated user.
        companyId: companyData.companyId,
        userId: auth.user.id, // When creating a role from a template, need to override the userId.
        isPublished: false,
        isTemplate: false,
      };

      const response = await makeApiRequest(
        "post",
        apiRoutes.createPageByUserID(duplicatedRoleData.userId),
        auth.user,
        companyData.companyId,
        duplicatedRoleData
      );

      // Redirect to the editor for the newly created role
      if (response.status === 201) {
        window.location.href = `${window.location.origin}/pages/edit/${response.data._id}`;
      }
    } catch (error) {
      console.error(error);
      window.alert("There was an error creating a role from this template");
    }
  };

  return (
    <button
      className={`text-sm rounded-lg px-[20px] h-[42px] bg-[#3371ff] text-white font-medium  
      ${"hover:bg-[#3370ffe4] cursor-pointer"}`}
      onClick={handleDuplicateRole}
    >
      {"Create New Page from Template"}
    </button>
  );
}

export default CreateRoleFromTemplateButton;
