import React, { useState, useEffect } from "react";

const Snackbar = ({ message, status, duration = 3000, onClose }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      if (onClose) {
        onClose();
      }
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  const getStatusColor = () => {
    switch (status) {
      case "success":
        return "text-green-700";
      case "error":
        return "text-red-700";
      case "warning":
        return "text-yellow-700";
      case "info":
        return "text-blue-700";
      default:
        return "text-slate-800";
    }
  };

  return (
    <div
      className={`z-[10000] fixed bottom-4 left-4 bg-white ${getStatusColor()} block-shadow px-8 py-4 rounded-lg transition-opacity duration-1000 ${
        visible ? "opacity-100" : "opacity-0"
      }`}
    >
      <p className="m-0">{message}</p>
    </div>
  );
};

export default Snackbar;
