import React from "react";
import { ReactComponent as Eye } from "assets/icons/eye.svg";
import { ReactComponent as Cube } from "assets/icons/cube-alt-1.svg";
import { ReactComponent as Layout } from "assets/icons/document-layout-left.svg";
import { ReactComponent as Shield } from "assets/icons/shield-alt.svg";

function ProductSection() {
  return (
    <div className="grid grid-col-1 md:grid-cols-2 lg:grid-cols-4 my-[20px] md:my-[60px] mx-[20px] md:mx-0 gap-8">
      <div className="p-[30px] rounded-xl bg-white block-shadow">
        <div className="flex flex-col gap-[10px]">
          <div className="flex justify-between">
            <Eye className="stroke-[#3371ff] w-[24px] h-[24px]" />
            <span className="text-xs rounded-full text-[#3371ff] bg-blue-100 py-1 px-3">
              Coming Soon
            </span>
          </div>
          <h2 className="font-semibold tracking-tight text-slate-900 text-xl">
            Real-time Insights
          </h2>
        </div>
        <p className="mt-4">
          Understand what content resonates with your audience, and who is
          actively checking out your landing pages so your team can invest time
          following up with the most engaged candidates.
        </p>
      </div>
      <div className="p-[30px] rounded-xl bg-white block-shadow">
        <div className="flex flex-col gap-[10px]">
          <Cube className="fill-[#3371ff] w-[24px] h-[24px]" />
          <h2 className="font-semibold tracking-tight text-slate-900 text-xl">
            Shared Components
          </h2>
        </div>
        <p className="mt-4">
          Edit once, update everywhere. Effortlessly maintain a consistent look
          across your organizations pages by creating reusable elements. Take
          control of your content with quick, real-time adjustments.
        </p>
      </div>
      <div className="p-[30px] rounded-xl bg-white block-shadow">
        <div className="flex flex-col gap-[10px]">
          <Layout className="stroke-[#3371ff] w-[24px] h-[24px]" />
          <h2 className="font-semibold tracking-tight text-slate-900 text-xl ">
            Team-wide Templates
          </h2>
        </div>
        <p className="mt-4">
          Don’t reinvent the wheel. Set up commonly used templates so that your
          team can spin up landing pages in one click. Start with a Hiring Spree
          global template, or create a template from scratch for full
          customization.
        </p>
      </div>
      <div className="p-[30px] rounded-xl bg-white block-shadow">
        <div className="flex flex-col gap-[10px]">
          <Shield className="stroke-[#3371ff] w-[24px] h-[24px]" />
          <h2 className="font-semibold tracking-tight text-slate-900 text-xl">
            Team Permissions
          </h2>
        </div>
        <p className="mt-4">
          Company Admins have the ability to add members of your team, update
          permissions across users, and own shared components ensuring your
          employer brand is expressed in a consistent manner.
        </p>
      </div>
    </div>
  );
}

export default ProductSection;
