import React from "react";
import PropTypes from "prop-types";

const Spinner = ({ size }) => {
  const spinnerStyle = {
    width: size,
    height: size,
  };

  return (
    <div className="inline-block animate-spin" style={spinnerStyle}>
      <div
        className={`border-[#3371ff] border-t-2 border-solid rounded-full w-full h-full`}
      ></div>
    </div>
  );
};

export default Spinner;
