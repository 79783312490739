import React from "react";

function InfoCardLayout({ children, padding = "p-[30px]" }) {
  return (
    <div className="flex-none lg:w-[250px] xl:w-[300px] w-auto">
      <div
        className={`bg-[#f3f7ff] dark:bg-slate-800 rounded-[20px] ${padding} `}
      >
        {children}
      </div>
    </div>
  );
}

export default InfoCardLayout;
