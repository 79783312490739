// Modules
import React from "react";

const LinkedInPost = ({ ...item }) => {
  function extractLinkedInEmbedAttributes(inputString) {
    const srcRegex = /src="([^"]+)"/;
    const heightRegex = /height="([^"]+)"/;
    const widthRegex = /width="([^"]+)"/;

    const srcMatch = inputString.match(srcRegex);
    const heightMatch = inputString.match(heightRegex);
    const widthMatch = inputString.match(widthRegex);

    const src = srcMatch ? srcMatch[1] : null;
    const height = heightMatch ? heightMatch[1] : null;
    const width = widthMatch ? widthMatch[1] : null;

    return { src, height, width };
  }

  let data = extractLinkedInEmbedAttributes(item.content);

  return (
    <iframe
      id="linkedin-iframe"
      src={data.src}
      height={data.height} // Use the state variable for height
      frameborder="0"
      allowfullscreen=""
      title="Embedded LinkedIn Post"
    ></iframe>
  );
};

export default LinkedInPost;
