import React, { useState } from "react";
import { requireAuth, useAuth, useCompany } from "../../../util";
import SocialLinkContainer from "./Social/SocialLinkContainer";
import SocialLinkInput from "./Social/SocialLinkInput";
import { ReactComponent as LinkIcon } from "assets/icons/link-alt-1.1.svg";
import Modal from "components/Modal";
import EditTeam from "./TeamDetails/EditTeam";
// import TeamIntegrations from "./TeamIntegrations/TeamIntegrations";
import Meta from "components/Meta";
import { makeApiRequest, apiRoutes } from "constants";

function CompanySettings() {
  const [showAddForm, setShowAddForm] = useState(false);
  const [editTeamInfo, setEditTeamInfo] = useState(false);
  const { companyData, fetchCompanyData, userRole } = useCompany();
  const auth = useAuth();

  function onError(e) {
    e.target.src =
      "https://hs-icons.s3.us-east-2.amazonaws.com/hs_logo_blue.png";
  }

  const handleSaveImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("image", file);
      const response = await makeApiRequest(
        "post",
        apiRoutes.companyLogo(companyData.companyId),
        auth.user,
        companyData.companyId,
        formData
      );
      if (response.status === 201) {
        fetchCompanyData();
      }
    } catch (error) {
      console.error(error, "error");
    }
  };

  const toggleEditForm = () => {
    setEditTeamInfo(!editTeamInfo);
  };

  const toggleAddForm = () => {
    setShowAddForm(!showAddForm);
  };

  const isTeamExist = companyData !== null;

  return (
    <>
      <Meta title="Team" />
      <div className="py-[40px] px-[75px]">
        <div className="flex gap-[10px] items-center justify-between mb-[40px]">
          <h1 className="font-bold tracking-tight text-slate-900 text-[32px]">
            Company Settings
          </h1>
        </div>
        {/* TeamPage Editor Block */}

        <div className="mt-[20px] grid gap-[20px] grid-cols-[2fr_1.5fr]">
          <div className="flex flex-col gap-[20px]">
            <EditTeam
              companyData={companyData}
              id={companyData.companyId}
              toggleEditForm={toggleEditForm}
              editTeamInfo={editTeamInfo}
            />
          </div>
          {/* Social Link Block */}
          <div className="flex flex-col gap-[20px]">
            {userRole !== "Employee" && (
              <div className="flex flex-row">
                <div className="flex items-center gap-[20px] bg-white p-3 pr-4 block-shadow rounded-full">
                  <img
                    className="h-[40px] w-[40px] rounded-full bg-white block-shadow"
                    src={companyData?.companyLogoUrl}
                    onError={(e) => onError(e)}
                    alt="logo"
                  />

                  <div className="flex flex-col">
                    <label
                      for="logo-upload"
                      className="text-sm font-medium cursor-pointer text-[#3371ff]"
                    >
                      Change Logo
                    </label>
                    <input
                      id="logo-upload"
                      type="file"
                      className="hidden"
                      onChange={(e) => handleSaveImage(e.target.files[0])}
                    />
                  </div>
                </div>
              </div>
            )}
            {/* {userRole !== "Employee" ? <TeamIntegrations /> : null} */}

            {isTeamExist && (
              <SocialLinkContainer
                socialLinks={companyData.socialLinks}
                toggleAddForm={toggleAddForm}
              />
            )}
          </div>
        </div>

        {showAddForm ? (
          <Modal
            closeModal={toggleAddForm}
            title="Add Link"
            icon={<LinkIcon className="fill-[#3371ff]" />}
          >
            <SocialLinkInput
              companyId={companyData.companyId}
              onClose={toggleAddForm}
            />
          </Modal>
        ) : null}
      </div>
    </>
  );
}

export default requireAuth(CompanySettings);
