import React, { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { ReactComponent as ChevronDown } from "assets/icons/chevron-down.svg";
import Block from "components/Block";
import Tooltip from "components/Tooltip";
import ConfirmationModal from "components/ConfirmationModal";
import Tag from "components/Tag";
import { ReactComponent as XMark } from "assets/icons/xmark.svg";
import { ReactComponent as EditIcon } from "assets/icons/pen-square.svg";
import { ReactComponent as VideoIcon } from "assets/icons/video.svg";
import { ReactComponent as TextIcon } from "assets/icons/text.svg";
import { ReactComponent as BuildingUser } from "assets/icons/building-user.svg";
import { ReactComponent as TagsIcon } from "assets/icons/tags-line.svg";
import { ReactComponent as InvestorIcon } from "assets/icons/money-check-dollar.svg";
import { ReactComponent as AwardIcon } from "assets/icons/trophy.svg";
import { ReactComponent as ImageIcon } from "assets/icons/image-square.svg";
import { ReactComponent as EmbedIcon } from "assets/icons/code.svg";
import { ReactComponent as HeroIcon } from "assets/icons/shield-alt.svg";
import { ReactComponent as ListIcon } from "assets/icons/square-list.svg";
import LandingPageBlocks from "pages/LandingPage/Components/LandingPageBlocks";
import { useCompany } from "util";

const blockIcons = [
  { blockType: "hero", icon: <HeroIcon className="stroke-[#3371ff]" /> },
  { blockType: "text", icon: <TextIcon className="stroke-[#3371ff]" /> },
  { blockType: "image", icon: <ImageIcon className="stroke-[#3371ff]" /> },
  { blockType: "video", icon: <VideoIcon className="stroke-[#3371ff]" /> },
  { blockType: "list", icon: <ListIcon className="stroke-[#3371ff]" /> },
  {
    blockType: "personnel",
    icon: <BuildingUser className="stroke-[#3371ff]" />,
  },
  {
    blockType: "linkedin-post",
    icon: <EmbedIcon className="stroke-[#3371ff]" />,
  },
  {
    blockType: "twitter-post",
    icon: <EmbedIcon className="stroke-[#3371ff]" />,
  },
  {
    blockType: "twitter-timeline",
    icon: <EmbedIcon className="stroke-[#3371ff]" />,
  },
  {
    blockType: "investor",
    icon: <InvestorIcon className="stroke-[#3371ff]" />,
  },
  { blockType: "industry", icon: <TagsIcon className="stroke-[#3371ff]" /> },
  { blockType: "awards", icon: <AwardIcon className="stroke-[#3371ff]" /> },
];

const ItemType = "Section";

const DraggableItem = ({
  id,
  block,
  index,
  moveItem,
  onDeleteItem,
  onEditItem,
  reoderItems,
}) => {
  const { companyData } = useCompany();
  const itemRef = useRef(null);
  // eslint-disable-next-line
  const [hoverClientY, setHoverClientY] = useState(null);
  // eslint-disable-next-line
  const [hoverMiddleY, setHoverMiddleY] = useState(null);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isInlinePreviewOpen, setIsInlinePreviewOpen] = useState(false);

  const [{ opacity }, ref] = useDrag({
    type: ItemType,
    item: { id, index },
    collect: (monitor) => {
      if (monitor.isDragging()) {
        setIsInlinePreviewOpen(false);
      }
      return {
        opacity: monitor.isDragging() ? 0.4 : 1,
      };
    },
  });

  const [, drop] = useDrop({
    accept: ItemType,
    drop: () => {
      if (!itemRef.current) {
        return;
      }
      reoderItems();
    },
    hover: (draggedItem, monitor) => {
      if (!itemRef.current) {
        return;
      }

      const hoverBoundingRect = itemRef.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      setHoverClientY(hoverClientY);
      setHoverMiddleY(hoverMiddleY);
      if (draggedItem.index !== index) {
        if (
          (draggedItem.index < index && hoverClientY < hoverMiddleY) ||
          (draggedItem.index > index && hoverClientY > hoverMiddleY)
        ) {
          moveItem(draggedItem.index, index);
          draggedItem.index = index;
        }
      }
    },
  });

  const handleDelete = () => {
    setConfirmationModalOpen(true);
  };

  const handleCancelDelete = () => {
    // Handle cancel action or simply close the modal
    setConfirmationModalOpen(false);
  };

  const handleConfirmDelete = async (componentId) => {
    try {
      onDeleteItem(componentId);
    } catch (error) {
      console.error("Error deleting link", error);
    }
  };

  const blockInfo = blockIcons.find(
    (blockIcon) => blockIcon.blockType === block.blockType
  );

  return (
    <div
      ref={(node) => {
        itemRef.current = node;
        ref(drop(node));
      }}
      className="cursor-move"
      style={{ opacity }}
    >
      <Block>
        <div className="flex items-center gap-[10px]">
          <button onClick={() => setIsInlinePreviewOpen(!isInlinePreviewOpen)}>
            <ChevronDown
              className={`stroke-[#3371ff] fill-[#3371ff] h-4 w-4 ${
                isInlinePreviewOpen ? "" : "-rotate-90"
              }`}
            />
          </button>
          <div className="flex items-center gap-[10px]">
            {blockInfo.icon}
            <h2 className="font-semibold tracking-tight text-slate-900 text-lg">
              {block.title}
            </h2>
          </div>
          {block.sharedId ? (
            <Tooltip text="This is a company component. If edited, it will no longer sync with company information.">
              <Tag text="Company Component" color="blue" />
            </Tooltip>
          ) : null}
          <div className="ml-auto flex align-center gap-[15px]">
            <Tooltip text="Edit Block">
              <button
                className="stroke-slate-500 hover:stroke-[#3371ff] flex items-center bg-transparent border-none cursor-pointer"
                onClick={onEditItem}
              >
                <EditIcon />
              </button>
            </Tooltip>
            <Tooltip text="Delete Block">
              <button
                type="button"
                className="stroke-slate-500 hover:stroke-[#ff0000] flex items-center bg-transparent border-none cursor-pointer"
                onClick={handleDelete}
              >
                <XMark />
              </button>
            </Tooltip>
          </div>
        </div>
      </Block>
      {isInlinePreviewOpen && (
        <div className="bg-slate-100 dark:bg-slate-800 rounded-[6px] -mt-2 p-5 pt-7 z-[-1] relative">
          <LandingPageBlocks contentFeed={[block]} companyData={companyData} />
        </div>
      )}
      {isConfirmationModalOpen && (
        <ConfirmationModal
          title={`Delete ${block.title}?`}
          message={`Are you sure you want to delete ${block.title}?`}
          onConfirm={() => handleConfirmDelete(id)}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
};

export default DraggableItem;
