// Modules
import React, { useCallback, useEffect, useState } from "react";
import BlockTitle from "components/BlockTitle";
import parse from "html-react-parser";
import useSessionId from "hooks/useSessionId";
import sendActivityData from "pages/LandingPage/Utilities/sendActivityData";

const THROTTLE_TIMEOUT = 1000; // 1 second

function TextBlock({ ...item }) {
  const textContent = item.content;
  const contentId = item.title + "-content";
  const [isClickAllowed, setIsClickAllowed] = useState(true);
  const title = item.title;
  const { sessionId } = useSessionId();

  const handleLinkClick = useCallback(
    async (e) => {
      if (isClickAllowed) {
        setIsClickAllowed(false);

        let text = e.target.innerText;
        let url = e.target.href;

        const analyticsData = {
          activity: "link_click",
          timestamp: new Date().toISOString(),
          link_title: text,
          card_title: title,
          url,
        };

        // Check if "data-disable-analytics" is set to 1
        if (parseInt(e.target.getAttribute("data-disable-analytic")) === 1) {
          console.log("Analytics data disabled for this link");
        } else {
          try {
            await sendActivityData(analyticsData, sessionId);
            console.log(analyticsData);
          } catch (error) {
            console.error("Error sending analytics data:", error);
          }
        }

        // Allow clicking again after a delay
        setTimeout(() => {
          setIsClickAllowed(true);
        }, THROTTLE_TIMEOUT);
      }
    },
    // eslint-disable-next-line
    [isClickAllowed, sessionId]
  );

  useEffect(() => {
    //need to attach analytics data to the a tags in the content block
    let aTagNodes = document.getElementById(contentId).querySelectorAll("a");

    //look through each a tag and add on the selector
    for (let i = 0; i < aTagNodes.length; i++) {
      aTagNodes[i].onclick = handleLinkClick;
    }
  });

  return (
    <div>
      <BlockTitle title={item.title} />
      <div
        id={contentId}
        className="mt-2 text-sm font-base leading-5 text-slate-600 dark:text-slate-300 list"
      >
        {parse(textContent)}
      </div>
    </div>
  );
}
export default TextBlock;
