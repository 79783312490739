import { apiRoutes, makeApiRequest } from "constants/apiRoutes";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useCompany } from "util";
import { useAuth } from "util";

export const useEditRole = (roleData, onSuccess, closeModal, roleId) => {
  const { control, handleSubmit, reset } = useForm();
  const auth = useAuth();
  const { companyData } = useCompany();

  useEffect(() => {
    reset({
      title: roleData.title,
      city: roleData.city,
      state: roleData.state,
      country: roleData.country,
      department: roleData.department,
      ctaUrl: roleData.ctaUrl,
      ctaType: roleData.ctaType,
      isRemote: roleData.isRemote,
      isHybrid: roleData.isHybrid,
      compType: roleData.compType,
      compLow: roleData.compLow,
      compHigh: roleData.compHigh,
    });
  }, [roleData, reset]);

  const onSubmit = async (data) => {
    try {
      const response = await makeApiRequest(
        "put",
        apiRoutes.updatePageByUserAndPageID(auth.user.id, roleId),
        auth.user,
        companyData.companyId,
        data
      );

      if (response.status === 200) {
        onSuccess();
        closeModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    resetValues: () =>
      reset({
        title: roleData.title,
        city: roleData.city,
        state: roleData.state,
        country: roleData.country,
        department: roleData.department,
        ctaUrl: roleData.ctaUrl,
        ctaType: roleData.ctaType,
        isRemote: roleData.isRemote,
        isHybrid: roleData.isHybrid,
        compType: roleData.compType,
        compLow: roleData.compLow,
        compHigh: roleData.compHigh,
      }),
  };
};
