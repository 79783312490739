import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import SecondaryActionButton from "components/Buttons/SecondaryActionButton";
import PrimaryActionButton from "components/Buttons/PrimaryActionButton";
import { Controller } from "react-hook-form";
import { Input } from "components/Input";
import { isValidUrl } from "util";
import StyledSelect from "components/StyledSelect";
import { infoCardDisplayOptions } from "constants";

const compensationOptions = [
  { value: "salary", label: "Salary" },
  { value: "hourly", label: "Hourly" },
  { value: "hidden", label: "Hidden" },
];

const ctaTypeOptions = [
  { value: "BookIntro", label: "Book Intro Call" },
  { value: "Apply", label: "Apply Here" },
];

export const CreateEditRoleModal = ({
  closeModal,
  control,
  handleSubmit,
  mode,
  currentCta = "",
  isTemplateRole = false,
}) => {
  const [ctaType, setCtaType] = useState(currentCta ? currentCta : "BookIntro");
  const [areCityStateRequired, setAreCityStateRequired] = useState(false);
  const [isCopmensationHidden, setIsCompensationHidden] = useState(false);
  const [showInvalidUrlMessage, setShowInvalidUrlMessage] = useState(false);
  const isEdit = mode === "Edit";

  useEffect(
    () =>
      setAreCityStateRequired(
        document.getElementById("isHybrid").checked ||
          (!document.getElementById("isRemote").checked &&
            !document.getElementById("isHybrid").checked)
      ),
    []
  );

  const handleSubmitAndValidateUrl = (event) => {
    event.preventDefault();
    if (!isValidUrl(event.target.ctaUrl.value)) {
      setShowInvalidUrlMessage(true);
      return;
    } else {
      setShowInvalidUrlMessage(false);
    }
    handleSubmit(event);
  };

  const toggleRemoteHybridRequirements = () => {
    //if neither remote or hybrid is checked, require city & state
    //if hybrid is checked, require city and state
    //if both hybrid and remote are checked, require city and state
    if (
      document.getElementById("isHybrid").checked ||
      (!document.getElementById("isRemote").checked &&
        !document.getElementById("isHybrid").checked)
    ) {
      document.getElementById("city").required = true;
      document.getElementById("state").required = true;
      setAreCityStateRequired(true);
    } else {
      //if role is remote only, country only required
      document.getElementById("city").required = false;
      document.getElementById("state").required = false;
      setAreCityStateRequired(false);
    }
  };

  return (
    <Modal
      title={
        isEdit
          ? `Edit ${isTemplateRole ? "Template" : "Page"}`
          : `Create New ${isTemplateRole ? "Template" : "Page"}`
      }
      closeModal={closeModal}
      size="lg"
    >
      <form
        onSubmit={(event) => {
          handleSubmitAndValidateUrl(event);
        }}
        className="flex flex-col gap-[20px]"
      >
        <div className="grid grid-cols-2 gap-[50px]">
          <div className="flex flex-col gap-[20px]">
            <Controller
              name="title"
              control={control}
              // rules={{ required: true }}
              render={({ field }) => (
                <Input
                  placeholder="Enter Job Title"
                  value={field.value}
                  setValue={field.onChange}
                  label="Job Title *"
                  required
                />
              )}
            />
            <Controller
              name="department"
              control={control}
              // rules={{ required: true }}
              render={({ field }) => (
                <Input
                  placeholder="Enter Department"
                  value={field.value}
                  setValue={field.onChange}
                  label="Department *"
                  required
                />
              )}
            />

            <Controller
              name="city"
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  placeholder="Chicago"
                  id="city"
                  value={field.value}
                  setValue={field.onChange}
                  label={`City${areCityStateRequired ? " *" : ""}`}
                  required={areCityStateRequired}
                />
              )}
            />
            <Controller
              name="state"
              control={control}
              render={({ field }) => (
                <Input
                  placeholder="Illinois"
                  id="state"
                  value={field.value}
                  setValue={field.onChange}
                  label={`State${areCityStateRequired ? " *" : ""}`}
                  required={areCityStateRequired}
                />
              )}
            />
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <Input
                  value={field.value}
                  setValue={field.onChange}
                  label="Country *"
                  required
                />
              )}
            />
          </div>
          <div className="flex flex-col gap-[20px]">
            <div className="flex flex-col">
              <p className="text-[13px] font-medium tracking-tight text-slate-700">
                Remote Options
              </p>
              <div className="flex mt-4 mb-[8px] gap-[50px]">
                <Controller
                  name="isRemote"
                  control={control}
                  render={({ field }) => (
                    <div className="flex flex-row gap-[10px] items-center">
                      <input
                        type="checkbox"
                        id="isRemote"
                        name="isRemote"
                        checked={field.value}
                        onChange={(event) => {
                          field.onChange(event);
                          toggleRemoteHybridRequirements();
                        }}
                      />
                      <label
                        htmlFor="isRemote"
                        className="text-sm font-medium tracking-tight text-slate-700"
                      >
                        Remote
                      </label>
                    </div>
                  )}
                />
                <Controller
                  name="isHybrid"
                  control={control}
                  render={({ field }) => (
                    <div className="flex flex-row gap-[10px] items-center">
                      <input
                        type="checkbox"
                        id="isHybrid"
                        name="isHybrid"
                        checked={field.value}
                        onChange={(event) => {
                          field.onChange(event);
                          toggleRemoteHybridRequirements();
                        }}
                      />
                      <label
                        htmlFor="isHybrid"
                        className="text-sm font-medium tracking-tight text-slate-700"
                      >
                        Hybrid
                      </label>
                    </div>
                  )}
                />
              </div>
            </div>
            <div className="flex">
              <Controller
                name="compType"
                control={control}
                render={({ field }) => (
                  <StyledSelect
                    label="Compensation"
                    placeholder="Select Compensation"
                    options={compensationOptions}
                    value={compensationOptions.find(
                      (option) => option.value === field.value
                    )}
                    onChange={(selectedOption) => {
                      setIsCompensationHidden(
                        selectedOption.value === "hidden"
                      );
                      field.onChange(selectedOption.value);
                    }}
                  />
                )}
              />
            </div>
            <div className="flex gap-[20px]">
              <Controller
                name="compLow"
                control={control}
                render={({ field }) => (
                  <Input
                    value={field.value}
                    type="number"
                    setValue={field.onChange}
                    label={`Minimum Compensation${
                      !isCopmensationHidden ? " *" : ""
                    }`}
                    required={!isCopmensationHidden}
                  />
                )}
              />
              <Controller
                name="compHigh"
                control={control}
                render={({ field }) => (
                  <Input
                    value={field.value}
                    type="number"
                    setValue={field.onChange}
                    label="Maximum Compensation"
                  />
                )}
              />
            </div>
            <div className="flex gap-[20px]">
              <Controller
                name="ctaType"
                control={control}
                render={({ field }) => (
                  <StyledSelect
                    label="Call To Action"
                    placeholder="Select CTA"
                    options={ctaTypeOptions}
                    value={ctaTypeOptions.find(
                      (option) => option.value === field.value
                    )}
                    onChange={(selectedOption) => {
                      field.onChange(selectedOption.value);
                      setCtaType(selectedOption.value);
                    }}
                  />
                )}
              />
            </div>
            <div className="flex flex-col gap-[5px]">
              <Controller
                control={control}
                name="ctaUrl"
                // rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="ctaUrl"
                    placeholder={`example.com/${
                      ctaType === "BookIntro" ? "booking" : "apply"
                    }`}
                    value={field.value}
                    setValue={field.onChange}
                    label={
                      ctaType === "BookIntro"
                        ? "Booking Link *"
                        : "Application Link *"
                    }
                    required
                  />
                )}
              />
              {showInvalidUrlMessage ? (
                <p className="text-xs pl-1 text-red-500">
                  Invalid url: use http/https protocol
                </p>
              ) : null}
            </div>

            <div className="flex mt-[15px] ml-auto gap-[10px]">
              <SecondaryActionButton text="Cancel" action={closeModal} />
              <PrimaryActionButton
                text={isEdit ? "Save Changes" : "Create Page"}
                buttonType="submit"
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};
