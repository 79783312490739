import React from "react";
import VideoBlock from "pages/LandingPage/Components/DisplayBlocks/VideoBlock";
import hsVideoPlaceholder from "../../../assets/img/hsVideoPlaceholder.png";

function DemoSection() {
  return (
    <div className="mx-[20px] lg:mx-[100px] my-[30px] text-center">
      <VideoBlock content="https://www.youtube.com/embed/r7L4nYnZFI0?si=mjj3nSd8fVYvRaH3" />
    </div>
  );
}

export default DemoSection;
