import React, { useState } from "react";
import { useAuth } from "util/auth";
import { ReactComponent as LinkIcon } from "assets/icons/link-alt-1.1.svg";

import { LINK_OPTIONS } from "constants/constants";
import { apiRoutes, makeApiRequest } from "../../../../constants";
import { useCompany } from "util";
import StyledSelect from "components/StyledSelect";
import Modal from "components/Modal";

function EditSocialLinks({ socialLinkData, onClose }) {
  const { companyData, fetchCompanyData } = useCompany();
  const auth = useAuth();

  const [editedSocialLink, setEditedSocialLink] = useState({
    ...socialLinkData,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditedSocialLink((prevLink) => ({
      ...prevLink,
      [name]: value,
    }));
  };

  const handleSelect = (event) => {
    setEditedSocialLink((prev) => ({
      ...prev,
      platform: event.value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await makeApiRequest(
        "put",
        apiRoutes.updateSocialLinkById(
          companyData.companyId,
          socialLinkData.id
        ),
        auth.user,
        companyData.companyId,
        editedSocialLink
      );

      if (response.status < 300) {
        fetchCompanyData();
        onClose(); // Close the modal
      } else {
        console.error("Failed to update social link");
      }
    } catch (error) {
      console.error("Error updating social link", error);
    }
  };

  return (
    <Modal
      closeModal={onClose}
      title="Edit Link"
      icon={<LinkIcon className="fill-[#3371ff]" />}
    >
      <form
        onSubmit={handleSubmit}
        className="flex flex-col mt-[20px] gap-[20px]"
      >
        <StyledSelect
          label="Platform:"
          placeholder="Select Platform"
          options={LINK_OPTIONS}
          value={LINK_OPTIONS.find(
            (option) => option.value === editedSocialLink.platform
          )}
          onChange={handleSelect}
        />
        {Object.keys(editedSocialLink).map((fieldName) => {
          if (fieldName !== "id" && fieldName !== "platform") {
            return (
              <label
                className="text-[13px] font-medium tracking-tight text-slate-700"
                key={fieldName}
              >
                {fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}:
                <input
                  className="text-[14px] w-full mt-1 py-2 px-3 bg-slate-100 rounded-md"
                  type="text"
                  name={fieldName}
                  value={editedSocialLink[fieldName]}
                  onChange={handleChange}
                />
              </label>
            );
          }
          return null;
        })}
        <div>
          <button
            type="submit"
            className="bg-[#3371ff] text-white border-none rounded-md font-semibold w-full h-[40px] cursor-pointer modal-submit"
          >
            Save Changes
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default EditSocialLinks;
