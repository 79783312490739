import { client } from "./api";

export const apiRoutes = {
  // Page Actions - PAGE
  getPageByPageID: (pageId) => `pages/${pageId}`,
  getPrivatePageByPageID: (pageId) => `pages/${pageId}/private`,

  // Page Actions - USER
  getPagesByUserID: (userId) => `pages/users/${userId}`,
  getTemplatesByUserID: (userId) => `pages/users/${userId}/templates`,
  createPageByUserID: (userId) => `pages/users/${userId}`,
  deletePageByUserAndPageID: (userId, pageId) =>
    `pages/users/${userId}/${pageId}`,
  updatePageByUserAndPageID: (userId, pageId) =>
    `pages/users/${userId}/${pageId}`,
  reorderPageByUserAndPageID: (userId, pageId) =>
    `/pages/users/${userId}/${pageId}/reorder`,
  scrapePageByUrl: (companyId) => `pages/company/${companyId}/scrape`,
  accessPageByPassword: "/pages/password",

  // Page Actions - COMPANY
  getPageByCompanyAndPageID: (companyId, pageId) =>
    `/pages/company/${companyId}/${pageId}/unpublished`,
  updatePageByCompanyAndPageID: (companyId, pageId) =>
    `/pages/company/${companyId}/${pageId}/reorder`,
  deletePageByCompanyAndPageID: (companyId, pageId, deletedItemId) =>
    `/pages/company/${companyId}/${pageId}/job-feed/${deletedItemId}`,
  getPublishedPage: (companyId, pageId) =>
    `/pages/company/${companyId}/${pageId}/published`,
  getPreviewPage: (companyId, pageId) =>
    `/pages/company/${companyId}/${pageId}/unpublished`,
  togglePublishedStatus: (companyId, pageId) =>
    `/pages/company/${companyId}/${pageId}/toggle-publish`,
  getPagesByCompany: (companyId) => `/pages/company/${companyId}`,

  // Page Block Actions
  createBlock: (companyId, pageId) =>
    `/pages/company/${companyId}/${pageId}/job-feed/add-block`,
  editBlock: (companyId, pageId, blockId) =>
    `/pages/company/${companyId}/${pageId}/job-feed/${blockId}`,
  deleteBlock: (companyId, pageId, blockId) =>
    `/pages/company/${companyId}/${pageId}/job-feed/${blockId}`,

  // User Routes:
  getUsersByCompanyId: `/users`,
  inviteUser: `/users/invite`,
  getUserByIdAndRoleId: (userId, pageId) => `/users/${userId}/${pageId}`,
  slackAuth: (code) => `slack/oauth/callback?code=${code}`,

  // Company Routes
  createCompany: "companies",
  companyDataById: (companyId) => `/companies/${companyId}`,
  companyDataByIds: (companyIds) =>
    `/companies?companyIds=${companyIds.join(",")}`,
  companiesByName: (companyName) => `/companies/search/${companyName}`,

  // Analytics Routes
  sendAnalyticsData: "/page-analytics",
  createSession: "/sessions",
  getSessionData: "/sessions",
  addSessionActivity: (sessionId, activity) =>
    `/sessions/${sessionId}/${activity}`,

  // Company Social Link Routes
  createCompanySocialLink: (companyId) => `/company-links/${companyId}`,
  getUpdatedCompanySocialLink: (companyId) => `/company-links/${companyId}`,
  updateSocialLinkById: (companyId, linkId) =>
    `/company-links/${companyId}/${linkId}`,
  updatedSocialLink: (companyId, linkId) =>
    `/company-links/${companyId}/${linkId}`,
  deleteSocialLinkById: (companyId, linkId) =>
    `/company-links/${companyId}/${linkId}`,

  // Page Link Actions
  getPageLinks: (companyId, pageId) =>
    `/page-links/${companyId}/pages/${pageId}`,
  createPageLink: (companyId, pageId) =>
    `/page-links/${companyId}/pages/${pageId}`,
  updatePageLink: (companyId, pageId, linkId) =>
    `/page-links/${companyId}/pages/${pageId}/${linkId}`,
  deletePageLink: (companyId, pageId, linkId) =>
    `/page-links/${companyId}/pages/${pageId}/${linkId}`,

  // Image Actions
  imageFile: () => "/images",
  profilePicture: (userId) => `/images/user/${userId}`,
  companyLogo: (companyId) => `/images/company/${companyId}`,

  // Data Link Actions
  getDataLink: (dataLinkId) => `/data-links/${dataLinkId}`,
  createDataLink: () => `/data-links`,
  deleteDataLink: (dataLinkid) => `/data-links/${dataLinkid}`,

  // Contact
  contact: "/contact",
  beta: "/contact/beta",
  reportBugs: (userId) => `/contact/bugs/${userId}`,
};

export const makeApiRequest = async (
  method,
  url,
  user,
  companyId,
  data,
  optionalConfigs
) => {
  const config = {
    headers: {
      Authorization: user ? `${user.id}.${user.accessToken}` : "",
      CompanyHeader: companyId ? companyId : "",
    },
    ...optionalConfigs,
  };

  switch (method) {
    case "put":
    case "post":
      return await client[method](url, data, config);
    case "get":
    case "delete":
      return await client[method](url, config);
    default:
      throw new Error("Unsupported API method");
  }
};
