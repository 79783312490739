import React, { useState } from "react";
import PropTypes from "prop-types";

const Tooltip = ({ text, children, position = "above" }) => {
  const [isVisible, setIsVisible] = useState(false);

  const showTooltip = () => {
    setIsVisible(true);
  };

  const hideTooltip = () => {
    setIsVisible(false);
  };

  const getPositionStyles = () => {
    switch (position) {
      case "below":
        return "bottom-[-40px] right-1/2 transform translate-x-1/2";
      case "left":
        return "right-[35px] bottom-1/2 transform translate-y-1/2";
      case "right":
        return "left-[35px] bottom-1/2 transform translate-y-1/2";
      case "above":
        return "top-[-40px] right-1/2 transform translate-x-1/2";
      default:
        return "top-[-40px] right-1/2 transform translate-x-1/2";
    }
  };

  return (
    <div className="relative inline-block">
      <div
        className="group relative" // Add relative positioning here
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        aria-describedby={isVisible ? "tooltip" : null}
      >
        {isVisible && (
          <>
            <div
              className={`absolute ${getPositionStyles()} bg-slate-900 text-white text-xs text-center font-medium px-3 py-2 rounded inline-block z-50 hs-block min-w-[100px] max-w-[175px] whitespace-nowrap`}
              role="tooltip"
              id="tooltip"
            >
              {text}
            </div>
          </>
        )}
        {children}
      </div>
    </div>
  );
};

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Tooltip;
