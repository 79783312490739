import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import InputHeader from "./InputHeader";
import { ReactComponent as Video } from "assets/icons/video.svg";
import { Input } from "components/Input";
import { apiRoutes } from "constants/apiRoutes";
import { client } from "constants/api";
import { useAuth } from "util";
import InputModal from "./InputModal";
import CompanyComponentEditWarning from "./CompanyComponentEditWarning";
import PrimaryActionButton from "components/Buttons/PrimaryActionButton";
import { useCompany } from "util";

function VideoBlockInput({
  close,
  closeNoRefresh,
  setSelectedComponent,
  isEditing,
  existingContent = {},
  refetch,
  isTeamsPage,
  handleCreateSharedComponent,
  handleEditSharedComponent,
}) {
  const [title, setTitle] = useState(isEditing ? existingContent.title : "");
  const [videoUrl, setVideoUrl] = useState(
    isEditing ? existingContent.content : ""
  );
  const auth = useAuth();
  const { companyData } = useCompany();
  const companyId = companyData.companyId;
  const { roleId } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a new block object or update an existing one
    const blockData = {
      blockType: "video",
      title,
      content: videoUrl,
      ...(isEditing && {
        id: existingContent.id,
        order: existingContent.order,
      }), // Include id and order when editing
    };

    const url = isEditing
      ? apiRoutes.editBlock(companyId, roleId, existingContent.id)
      : apiRoutes.createBlock(companyId, roleId);

    try {
      let response;
      if (isTeamsPage) {
        response = isEditing
          ? await handleEditSharedComponent(blockData)
          : await handleCreateSharedComponent(blockData);
      } else {
        response = await client[isEditing ? "put" : "post"](url, blockData, {
          headers: {
            Authorization: auth.user
              ? `${auth.user.id}.${auth.user.accessToken}`
              : "",
          },
        });
      }

      if (response.status < 300) {
        if (isEditing) {
          console.log("Block updated successfully");
          refetch();
        } else {
          console.log("Block created successfully");
        }
        setTitle("");
        setVideoUrl("");
        close(true); // Close the modal or reset the form
      } else {
        console.error(
          isEditing ? "Failed to update block" : "Failed to create block"
        );
      }
    } catch (error) {
      console.error("Error sending request:", error);
    }
  };

  useEffect(() => {
    if (isEditing) {
      // Pre-fill the form fields with existing content when in editing mode
      setTitle(existingContent.title);
      setVideoUrl(existingContent.content);
    }
  }, [isEditing, existingContent]);

  const unsavedChanges = isEditing
    ? videoUrl !== existingContent.content || title !== existingContent.title
    : title !== "" || videoUrl !== "";

  return (
    <InputModal size="md">
      <InputHeader
        icon={<Video className="stroke-[#3371ff]" />} // Pass the appropriate icon for awards
        title="Video"
        isEditing={isEditing}
        existingContent={existingContent}
        setSelectedComponent={setSelectedComponent}
        close={close}
        unsavedChanges={unsavedChanges}
      />
      <form
        onSubmit={handleSubmit}
        className="flex flex-col mt-[20px] gap-[20px]"
      >
        <Input
          setValue={(e) => setTitle(e.target.value)}
          value={title}
          required={true}
          label="Title"
          id="title"
        />
        <Input
          setValue={(e) => setVideoUrl(e.target.value)}
          value={videoUrl}
          required={true}
          label="Video Embed URL"
          id="videoUrl"
        />
        <CompanyComponentEditWarning sharedId={existingContent.sharedId} />
        <div className="ml-auto flex gap-[15px]">
          <PrimaryActionButton
            text={isEditing ? "Update Block" : "Create Block"}
            buttonType="submit"
            disabled={!unsavedChanges}
          />
        </div>
      </form>
    </InputModal>
  );
}

export default VideoBlockInput;
