import React from "react";
import logo from "../../../assets/img/hs_logo_black.png";
import { Link } from "react-router-dom";
import { ReactComponent as Twitter } from "assets/icons/social/twitter_icon.svg";
import { ReactComponent as LinkedIn } from "assets/icons/social/linkedin_icon.svg";
import { ReactComponent as Instagram } from "assets/icons/social/instagram_icon.svg";
import useWindowDimensions from "hooks/useWindowDimensions";

function Footer() {
  const isMobileScreen = useWindowDimensions();

  return (
    <div
      className={`${
        isMobileScreen ? "px-[20px]" : "px-[75px]"
      } border-solid border-t border-t-[#e0e7ff] flex gap-8 py-[50px] text-slate-800 text-[15px] font-medium justify-between`}
    >
      <div className="flex flex-col justify-between">
        <div className="flex items-center gap-4">
          <img
            src={logo}
            className="w-[50px] h-[50px] bg-white block-shadow rounded-full"
          />
          <p className="mt-2 text-[12px] text-[#adbced]">
            © 2024 Hiring Spree LLC
          </p>
        </div>
        <div className="flex gap-6 items-center">
          <a href="https://www.linkedin.com/company/hiring-spree">
            <div className="h-[20px] w-[20px] fill-[#3370ff80] hover:fill-[#2566fda0]">
              <LinkedIn />
            </div>
          </a>
          <a href="https://www.instagram.com/hiringspree/">
            <div className="h-[20px] w-[20px] fill-[#3370ff80] hover:fill-[#2566fda0]">
              <Instagram />
            </div>
          </a>
          <a href="https://twitter.com/HiringSpreeHQ">
            <div className="h-[20px] w-[20px] fill-[#3370ff80] hover:fill-[#2566fda0]">
              <Twitter />
            </div>
          </a>
        </div>
      </div>
      <div className="flex flex-row gap-8">
        <div
          className={`${
            isMobileScreen ? "text-[11px]" : "text-[13px]"
          } flex flex-col gap-4`}
        >
          <p className="text-[#adbced] tracking-wide font-medium">RESOURCES</p>
          <Link className="hover:text-[#3370ff]" to="legal/terms-of-use">
            Terms of Use
          </Link>
          <Link className="hover:text-[#3370ff]" to="legal/privacy-policy">
            Privacy Policy
          </Link>
          {/* <Link className="hover:text-[#3370ff]" to="legal/cookie-policy">
          Cookie Policy
        </Link> */}
        </div>
        <div
          className={`${
            isMobileScreen ? "text-[11px]" : "text-[13px]"
          } flex flex-col gap-4 justify-self-end`}
        >
          <p className="text-[#adbced] tracking-wide font-medium">COMPANY</p>
          <Link className="hover:text-[#3370ff]" to="Contact">
            Get in Touch
          </Link>
          <Link className="hover:text-[#3370ff]" to="About">
            Our Story
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
