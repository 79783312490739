import { apiRoutes, makeApiRequest } from "constants/apiRoutes";
import { useEffect, useState } from "react";
import { useCompany } from "util";
import { useAuth } from "util";

// This hook is specifically used for the Landing Page Editor

const useGetPageEditorInfo = (companyId, roleId) => {
  const [isLoading, setIsLoading] = useState(false);
  const [featuredRoleData, setFeaturedRoleData] = useState([]);
  const [localNewsroomLinks, setLocalNewsroomLinks] = useState([]);
  const auth = useAuth();
  const { companyData } = useCompany();

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await makeApiRequest(
        "get",
        apiRoutes.getPageByCompanyAndPageID(companyId, roleId),
        auth.user,
        companyData.companyId
      );
      if (response.status !== 200) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }
      setFeaturedRoleData(response.data);
      setLocalNewsroomLinks(response.data.links);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [companyId, roleId]);

  return {
    featuredRoleData,
    localNewsroomLinks,
    refetch: fetchData,
    isLoading,
    setLocalNewsroomLinks,
  };
};

export default useGetPageEditorInfo;
