import React from "react";
import { useAuth } from "util";
import useWindowDimensions from "hooks/useWindowDimensions";
import { NavLink, Link } from "react-router-dom";
import logo from "../../assets/img/hs_logo_black.png";
import Tooltip from "components/Tooltip";
import { ReactComponent as Cube } from "assets/icons/cube-alt-1.svg";
import { ReactComponent as Chart } from "assets/icons/chart-simple.svg";
import { ReactComponent as Users } from "assets/icons/users.svg";
import { ReactComponent as Buildings } from "assets/icons/buildings.svg";
import { ReactComponent as Gear } from "assets/icons/gear.svg";
import { ReactComponent as CircleQuestion } from "assets/icons/circle-question.svg";
import { ReactComponent as SignOut } from "assets/icons/arrow-right-from-bracket.svg";
import { ReactComponent as CircleBolt } from "assets/icons/circle-bolt.svg";
import { ReactComponent as FileUser } from "assets/icons/file-user.svg";
import CompanySwitch from "./CompanySwitch";
import { requireAuth } from "util";
import { useCompany } from "util";

function VerticalNav() {
  const auth = useAuth();
  const { userRole } = useCompany();
  const isMobileScreen = useWindowDimensions();

  const loggedInLinks = [
    {
      to: "/pages",
      label: "Pages",
      icon: <FileUser className="stroke-slate-700 hover:stroke-[#3371ff]" />,
    },

    {
      to: "/components",
      label: "Components",
      icon: <Cube className="fill-slate-700 hover:fill-[#3371ff]" />,
    },
    {
      to: "/analytics",
      label: "Analytics",
      icon: <Chart className="stroke-slate-700 hover:stroke-[#3371ff]" />,
    },
    {
      to: "/team",
      label: "Team",
      icon: <Users className="stroke-slate-700 hover:stroke-[#3371ff]" />,
    },
    {
      to: "/company",
      label: "Company",
      icon: <Buildings className="fill-slate-700 hover:fill-[#3371ff]" />,
    },
  ];

  // Conditionally filter out navigation based on User Role, Beta Access, and Verification Status
  const filteredLinks = loggedInLinks.filter((link) => {
    if (
      (link.to === "/team" && userRole === "Employee") ||
      (link.to === "/team" && auth.user.isVerified !== "verified")
    ) {
      // Skip rendering link
      return false;
    }
    // Render other links
    return true;
  });

  return (
    <header
      className={`z-[100] block-shadow bg-white fixed h-[100vh] w-[65px] py-[20px] flex flex-col items-center ${
        isMobileScreen ? null : null
      }`}
    >
      <NavLink to="/" className="w-[45px]">
        <img src={logo} alt="logo" />
      </NavLink>
      <div className="mt-[35px]">
        <nav className="flex flex-col items-center gap-6">
          {filteredLinks.map((link, index) => (
            <Tooltip text={link.label} key={index} position="right">
              <NavLink to={link.to}>{link.icon}</NavLink>
            </Tooltip>
          ))}
          {auth.user.featureFlag === "beta" && (
            <Tooltip text="Beta Features Enabled" position="right">
              <CircleBolt className="stroke-yellow-500" />
            </Tooltip>
          )}
        </nav>
      </div>

      <div className="mt-auto gap-5 flex flex-col items-center">
        {auth.user?.permissions?.length > 1 ? <CompanySwitch /> : null}
        {auth.user.featureFlag === "beta" && (
          <Tooltip text="Support" position="right">
            <NavLink to="/support">
              <CircleQuestion className="stroke-yellow-500 hover:stroke-[#3371ff]" />
            </NavLink>
          </Tooltip>
        )}
        <Tooltip text="Settings" position="right">
          <NavLink to="/settings">
            <Gear className="stroke-slate-700 hover:stroke-[#3371ff]" />
          </NavLink>
        </Tooltip>
        <Tooltip text="Log out" position="right">
          <Link
            to="/auth/signout"
            onClick={(e) => {
              e.preventDefault();
              auth.signout();
            }}
          >
            <SignOut className="stroke-slate-700 hover:stroke-red-500" />
          </Link>
        </Tooltip>
      </div>
    </header>
  );
}

export default requireAuth(VerticalNav, false);
