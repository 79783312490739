import React from "react";

function PrimaryActionButton({
  text,
  buttonType,
  action = null,
  disabled = false,
}) {
  return (
    <button
      type={buttonType}
      onClick={disabled ? null : action}
      disabled={disabled}
      className={`bg-[#3371ff] text-white border-none rounded-[10px] font-medium text-sm px-6 py-3 ${
        disabled
          ? "opacity-50 cursor-not-allowed"
          : "hover:bg-[#3370ffe4] cursor-pointer"
      }}`}
    >
      {text}
    </button>
  );
}

export default PrimaryActionButton;
