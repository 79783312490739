import { useState } from "react";
import { Cookies } from "react-cookie";
import { v4 as uuidv4 } from "uuid";

const cookies = new Cookies();
const visitorCookieName = "VISITOR_ID";

const useVisitorId = () => {
  const [visitorId, setVisitorId] = useState(
    cookies.get(visitorCookieName) || uuidv4()
  );

  if (!cookies.get(visitorCookieName)) {
    cookies.set(visitorCookieName, visitorId);
  }

  return visitorId;
};

export default useVisitorId;
