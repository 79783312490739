import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { requireAuth } from "util";
import VerticalNav from "components/Navigation/VerticalNav";

// TODO - Move BugNub from AccountLayout to Veritcal Nav
import BugReportForm from "components/BugNub/BugNubForm";
import BugNub from "components/BugNub/BugNub";
import Snackbar from "components/Snackbar";
import { useRouter } from "util";
import { useAuth } from "util";

function AccountLayout() {
  const router = useRouter();
  const [isBugReportFormVisible, setBugReportFormVisible] = useState(false);
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarStatus, setSnackbarStatus] = useState("");
  const auth = useAuth();

  useEffect(() => {
    if (auth.user.showOnboardingFlow) {
      router.navigate("/onboarding");
    }
  }, []);

  const handleSnackbarClose = () => {
    setSnackbarVisible(false);
    setSnackbarMessage("");
  };

  const toggleBugReportForm = () => {
    setBugReportFormVisible((prevVisible) => !prevVisible);
  };
  if (!auth.user?.showOnboardingFlow) {
    return (
      <div className="flex bg-[#0e56ff09]">
        <VerticalNav />
        {/* ml-[65px] in place to fit the vertical navigation */}
        {/* if the width of the navigation changes, so should the margin */}
        <div className="flex-1 ml-[65px] w-full min-h-[100vh]">
          <Outlet />
        </div>
        <BugNub isOpen={isBugReportFormVisible} toggle={toggleBugReportForm} />
        {/* BugReportForm with conditional visibility */}
        {isBugReportFormVisible ? (
          <BugReportForm
            close={toggleBugReportForm}
            setSnackbarVisible={setSnackbarVisible}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarStatus={setSnackbarStatus}
          />
        ) : null}
        {snackbarVisible && (
          <Snackbar
            message={snackbarMessage}
            status={snackbarStatus}
            onClose={handleSnackbarClose}
          />
        )}
      </div>
    );
  }
}

export default requireAuth(AccountLayout);
