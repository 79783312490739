import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as XMark } from "assets/icons/xmark.svg";
import { ReactComponent as ChevronDown } from "assets/icons/chevron-down.svg";

// MultiSelect takes 4 parameters:
//
// 1. options - an array of objects with values and labels
// 2. selectedOptions - initially an empty array, then an array containing options as it updates
// 3. onChange - setState of selectedOptions
// 4. placeholder – placeholder text when there are no selectedOptions
//
// NOTE: 2 & 3 are a single react hook.
// 2 being the constant, and 3 being the setState function
// i.e.const[selectedBlocks (this is param #2), setSelectedBlocks (this is param #3)] = useState([])

const MultiSelect = ({ options, selectedOptions, onChange, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleOptionClick = (option) => {
    const isSelected = selectedOptions.some(
      (selected) => selected.value === option.value
    );
    if (isSelected) {
      onChange(
        selectedOptions.filter((selected) => selected.value !== option.value)
      );
    } else {
      onChange([...selectedOptions, option]);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleRemoveOption = (e, option) => {
    e.stopPropagation();
    handleOptionClick(option);
  };

  return (
    <div
      ref={dropdownRef}
      className={`relative inline-block min-w-[100px] bg-white block-shadow rounded-lg border border-solid ${
        isOpen ? "border-[#3371ff]" : "border-gray-900"
      }`}
    >
      <div
        className={`rounded-lg h-[44px] px-5 cursor-pointer z-1 flex items-center`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex flex-wrap gap-2 items-center">
          {selectedOptions.length > 0 ? (
            selectedOptions.map((option) => (
              <span
                key={option.value}
                className="flex gap-2 items-center bg-blue-200 rounded px-2 py-1 text-sm"
              >
                {option.label}
                <div
                  className="z-20"
                  onClick={(e) => handleRemoveOption(e, option)}
                >
                  <XMark className="stroke-slate-900 w-[15px]" />
                </div>
              </span>
            ))
          ) : (
            <span className={`${isOpen ? "text-[#3371ff]" : "text-slate-700"}`}>
              {placeholder}
            </span>
          )}
          <ChevronDown
            className={`${
              isOpen ? "fill-[#3371ff]" : "fill-slate-900"
            } w-[15px] ml-3`}
          />
        </div>
      </div>
      {/* Open Options */}
      {isOpen && (
        <div className="absolute left-0 top-full mt-2 min-w-[150px] max-w-[550px] text-slate-900 border border-solid border-slate-900 rounded-lg bg-white shadow-lg z-10">
          {options.map((option) => (
            <div
              key={option.value}
              className={`py-2.5 px-5 cursor-pointer hover:bg-blue-100 first:rounded-t-lg last:rounded-b-lg  ${
                selectedOptions.includes(option) ? "bg-blue-200" : ""
              }`}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
