import axios from "axios";

export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8080/"
    : "https://hiringspree-6a7a84df5582.herokuapp.com/";

export const client = axios.create({
  baseURL: BASE_URL,
});
