import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import useWindowDimensions from "./useWindowDimensions";

const useSessionData = () => {
  const [queryParams] = useSearchParams();

  return useMemo(() => {
    const name = queryParams.get("name");
    const firstName = queryParams.get("firstName");
    const lastName = queryParams.get("lastName");
    const email = queryParams.get("email");
    const platform = queryParams.get("platform");
    const company = queryParams.get("company");

    return {
      userAgent: navigator.userAgent,
      referrer: document.referrer,
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      ...(name && { name }),
      ...(firstName && { firstName }),
      ...(lastName && { lastName }),
      ...(email && { email }),
      ...(platform && { platform }),
      ...(company && { company }),
    };
  }, [queryParams]);
};

export default useSessionData;
