import { useState } from "react";
import { useForm } from "react-hook-form";
import { apiRoutes, makeApiRequest } from "constants";
import { useAuth } from "util";

export const useCreateCompany = () => {
  const auth = useAuth();
  const [isSaving, setIsSaving] = useState(false);
  const [companyLogoUrl, setCompanyLogoUrl] = useState(null);

  const { handleSubmit, control } = useForm({
    defaultValues: {
      companyName: "",
      companyId: "",
      blurb: "",
      companySize: "",
      foundedDate: "",
      fundingStatus: "",
      fundingType: "",
      homepageUrl: "",
      hqLocation: "",
      industry: [],
    },
  });

  // TODO - Integrate handleSaveImage into onSubmit function.
  const handleSaveImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("image", file);
      const response = await makeApiRequest(
        "post",
        apiRoutes.imageFile(),
        auth.user,
        null,
        formData
      );
      if (response.status === 201) {
        setCompanyLogoUrl(response.data);
      }
    } catch (error) {
      console.error(error, "error");
    }
  };

  // Create CompanyID based off company name
  // Send Data to MongoDB Companies collection
  // Maybe this is a server thing

  // Copypasta from useCreateRole, update API request to match company endpoint
  const onSubmit = async (data) => {
    setIsSaving(true);
    data.companyLogoUrl = companyLogoUrl;
    try {
      console.log("Submitted!");
      const response = await makeApiRequest(
        "post",
        apiRoutes.createCompany,
        auth.user,
        null,
        data
      );
      if (response.status === 201) {
        let permissions = {
          companyId: response.data.companyId,
          userRole: "Employee",
        };
        auth.updateProfile(permissions);
        console.log(`Created Team with ID: ${response.data.companyId}`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  return {
    handleSubmit: handleSubmit(onSubmit),
    control,
    isSaving,
    handleSaveImage,
    companyLogoUrl,
  };
};
