import React from "react";
import SocialLinks from "../../SocialLinks";

function InfoCardLinks({ companyData }) {
  return (
    <div>
      <p className="text-[13px] font-semibold text-slate-900 dark:text-slate-300">
        Online Presence:
      </p>
      <SocialLinks socialLinks={companyData?.socialLinks} />
    </div>
  );
}

export default InfoCardLinks;
