import React, { useState } from "react";
import { useAuth } from "../../../util";
import { redirectToBilling } from "../../../util";
import Spinner from "components/Spinner";
import { ReactComponent as CreditCard } from "assets/icons/credit-card.svg";

function Billing(props) {
  const auth = useAuth();
  const [loading, setLoading] = useState(false);

  const handleBillingRedirect = () => {
    setLoading(true);
    if (auth.user.planIsActive) {
      redirectToBilling().catch((error) => {
        setLoading(false);
        props.onStatus({
          type: "error",
          message: error.message,
        });
      });
    } else {
      setLoading(false);
      props.onStatus({
        type: "error",
        message: "No active plan found.",
      });
    }
  };

  return (
    <>
      <button
        onClick={handleBillingRedirect}
        className="text-slate-900 border-slate-900 border border-solid bg-white block-shadow font-medium text-[15px] pl-3 pr-4 py-2.5 rounded-lg flex flex-row gap-2 items-center"
        disabled={loading}
      >
        {loading ? (
          <>
            <Spinner size="15px" />
            <span className="ml-2">Redirecting to Stripe...</span>
          </>
        ) : (
          <>
            <CreditCard className="fill-slate-900" />
            <span>Billing</span>
          </>
        )}
      </button>
    </>
  );
}

export default Billing;
