import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { aggregateSessionsByDay, fillMissingDates } from "util/sessions";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TimeSeriesChart = ({ sessionData }) => {
  const [processedData, setProcessedData] = useState({
    labels: [],
    data: [],
  });
  useEffect(() => {
    if (sessionData.length > 0) {
      const startDate = dayjs(sessionData[0].session_start).format(
        "YYYY-MM-DD"
      );
      const endDate = dayjs(
        sessionData[sessionData.length - 1].session_start
      ).format("YYYY-MM-DD");
      const { labels, data } = aggregateSessionsByDay(sessionData);
      const filledData = fillMissingDates(
        data.map((item, index) => ({
          date: labels[index],
          value: item,
        })),
        startDate,
        endDate
      );

      setProcessedData({
        labels: filledData.map((item) => item.date),
        data: filledData.map((item) => item.value),
      });
    } else {
      setProcessedData({
        labels: [],
        data: [],
      });
    }
  }, [sessionData]);

  const chartData = {
    labels: processedData.labels,
    datasets: [
      {
        label: "Sessions",
        data: processedData.data,
        borderColor: "rgba(51, 113, 255, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: true,
        tension: 0.3,
        pointRadius: 5,
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        position: "bottom",
      },
    },
    responsive: true,
    scales: {
      x: {
        type: "category",
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        title: {
          display: true,
          text: "Sessions",
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div>
      <h2 className="text-lg font-semibold mb-2 text-slate-900">
        Session Activity
      </h2>
      <div className="p-[30px] block-shadow bg-white rounded-md">
        <Line data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};

export default TimeSeriesChart;
