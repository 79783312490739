import React from "react";
import Tooltip from "components/Tooltip";
import { ReactComponent as ChevronDown } from "assets/icons/chevron-down.svg";
import { ReactComponent as XMark } from "assets/icons/xmark.svg";
import { ReactComponent as EditIcon } from "assets/icons/pen-square.svg";
import { ReactComponent as VideoIcon } from "assets/icons/video.svg";
import { ReactComponent as TextIcon } from "assets/icons/text.svg";
import { ReactComponent as BuildingUser } from "assets/icons/building-user.svg";
import { ReactComponent as TagsIcon } from "assets/icons/tags-line.svg";
import { ReactComponent as InvestorIcon } from "assets/icons/money-check-dollar.svg";
import { ReactComponent as AwardIcon } from "assets/icons/trophy.svg";
import { ReactComponent as ImageIcon } from "assets/icons/image-square.svg";
import { ReactComponent as EmbedIcon } from "assets/icons/code.svg";
import { ReactComponent as HeroIcon } from "assets/icons/shield-alt.svg";
import { ReactComponent as ListIcon } from "assets/icons/square-list.svg";
import { useState } from "react";
import ConfirmationModal from "components/ConfirmationModal";
import LandingPageBlocks from "pages/LandingPage/Components/LandingPageBlocks";
import { useCompany } from "util";

function SharedComponent({ component, onEditComponent, onDeleteComponent }) {
  const { companyData } = useCompany();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isInlinePreviewOpen, setIsInlinePreviewOpen] = useState(false);

  const { title, blockType, id } = component;

  const onDelete = async (id) => {
    try {
      const response = await onDeleteComponent(id);
      if (response.status < 300) {
        console.log("Block deleted successfully");
        setIsConfirmationModalOpen(false);
      } else {
        console.error("Failed to delete block");
      }
    } catch (error) {
      console.error("Error sending request:", error);
    }
  };

  const blockIcons = [
    { blockType: "hero", icon: <HeroIcon className="stroke-[#3371ff]" /> },
    { blockType: "text", icon: <TextIcon className="stroke-[#3371ff]" /> },
    { blockType: "image", icon: <ImageIcon className="stroke-[#3371ff]" /> },
    { blockType: "video", icon: <VideoIcon className="stroke-[#3371ff]" /> },
    { blockType: "list", icon: <ListIcon className="stroke-[#3371ff]" /> },
    {
      blockType: "personnel",
      icon: <BuildingUser className="stroke-[#3371ff]" />,
    },
    {
      blockType: "linkedin-post",
      icon: <EmbedIcon className="stroke-[#3371ff]" />,
    },
    {
      blockType: "twitter-post",
      icon: <EmbedIcon className="stroke-[#3371ff]" />,
    },
    {
      blockType: "twitter-timeline",
      icon: <EmbedIcon className="stroke-[#3371ff]" />,
    },
    {
      blockType: "investor",
      icon: <InvestorIcon className="stroke-[#3371ff]" />,
    },
    { blockType: "industry", icon: <TagsIcon className="stroke-[#3371ff]" /> },
    { blockType: "awards", icon: <AwardIcon className="stroke-[#3371ff]" /> },
  ];

  const blockInfo = blockIcons.find(
    (blockIcon) => blockIcon.blockType === blockType
  );

  return (
    <>
      <div>
        <div className="p-[30px] block-shadow bg-white rounded-xl">
          <div className="flex items-center gap-[10px]">
            <Tooltip text="Preview Content">
              <div className="flex items-center">
                <button
                  onClick={() => setIsInlinePreviewOpen(!isInlinePreviewOpen)}
                >
                  <ChevronDown
                    className={`stroke-[#3371ff] fill-[#3371ff] h-4 w-4 ${
                      isInlinePreviewOpen ? "" : "-rotate-90"
                    }`}
                  />
                </button>
              </div>
            </Tooltip>
            <div className="flex items-center gap-[10px]">
              {blockInfo.icon}
              <h2 className="font-semibold tracking-tight text-slate-900 text-lg">
                {title}
              </h2>
            </div>

            <div className="ml-auto flex align-center gap-[15px]">
              <Tooltip text="Edit Block">
                <button
                  className="stroke-slate-500 hover:stroke-[#3371ff] flex items-center bg-transparent border-none cursor-pointer"
                  onClick={() => onEditComponent()}
                >
                  <EditIcon />
                </button>
              </Tooltip>
              <Tooltip text="Delete Block">
                <button
                  type="button"
                  className="stroke-slate-500 hover:stroke-[#ff0000] flex items-center bg-transparent border-none cursor-pointer"
                  onClick={() => setIsConfirmationModalOpen(true)}
                >
                  <XMark />
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
        {isInlinePreviewOpen && (
          <div className="bg-slate-100 dark:bg-slate-800 rounded-[6px] -mt-2 p-5 pt-7 z-[-1] relative">
            <LandingPageBlocks
              contentFeed={[component]}
              companyData={companyData}
            />
          </div>
        )}
      </div>
      {isConfirmationModalOpen && (
        <ConfirmationModal
          title={`Delete Shared Component?`}
          message={`Are you sure you want to delete this shared component? Doing so will not affect the existing data on any roles that use it.`}
          onConfirm={() => {
            onDelete(id);
          }}
          onCancel={() => setIsConfirmationModalOpen(false)}
        />
      )}
    </>
  );
}

export default SharedComponent;
