// Modules
import React from "react";
import PersonWithTitle from "components/Avatars/PersonWithTitle";
import BlockTitle from "components/BlockTitle";

function Personnel({ ...item }) {
  return (
    <div>
      <div className="mx-auto max-w-7xl gap-x-8 gap-y-20">
        <div className="max-w-2xl">
          <BlockTitle title={item.title} />
          <p className="mt-2 text-sm text-slate-600 dark:text-slate-300">
            {item.description}
          </p>
        </div>
        <ul className="grid gap-x-8 gap-y-4 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 sm:gap-y-6 xl:col-span-2 mt-6">
          {item.content.map((person, index) => (
            <li key={index}>
              <PersonWithTitle person={person} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Personnel;
