import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import InputHeader from "./InputHeader";
import { ReactComponent as HeroIcon } from "assets/icons/shield-alt.svg";
import { Input } from "components/Input";
import { apiRoutes } from "constants/apiRoutes";
import { client } from "constants/api";
import { useAuth } from "util";
import InputModal from "./InputModal";
import PrimaryActionButton from "components/Buttons/PrimaryActionButton";
import { useCompany } from "util";

function HeroBlockInputs({
  close,
  setSelectedComponent,
  isEditing,
  existingContent = {},
  refetch,
}) {
  const [title, setTitle] = useState(isEditing ? existingContent.title : "");
  const [videoUrl, setVideoUrl] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const [videos, setVideos] = useState(
    isEditing ? existingContent.content : []
  );
  const auth = useAuth();
  const { companyData } = useCompany();
  const companyId = companyData.companyId;
  const { roleId } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a new block object or update an existing one
    const blockData = {
      blockType: "hero",
      title,
      content: videos,
      ...(isEditing && {
        id: existingContent.id,
        order: existingContent.order,
      }), // Include id and order when editing
    };

    const url = isEditing
      ? apiRoutes.editBlock(companyId, roleId, existingContent.id)
      : apiRoutes.createBlock(companyId, roleId);

    try {
      const response = await client[isEditing ? "put" : "post"](
        url,
        blockData,
        {
          headers: {
            Authorization: auth.user
              ? `${auth.user.id}.${auth.user.accessToken}`
              : "",
          },
        }
      );

      if (response.status < 300) {
        if (isEditing) {
          console.log("Block updated successfully");
          refetch();
        } else {
          console.log("Block created successfully");
        }
        setTitle("");
        setVideoUrl("");
        setVideoTitle("");
        setVideos([]);
        close(true); // Close the modal or reset the form
      } else {
        console.error(
          isEditing ? "Failed to update block" : "Failed to create block"
        );
      }
    } catch (error) {
      console.error("Error sending request:", error);
    }
  };

  const addVideoGroup = () => {
    if (videoUrl && videoTitle) {
      // Create a new video group and add it to the videos array
      const newVideoGroup = {
        videoUrl,
        videoTitle,
      };
      setVideos([...videos, newVideoGroup]);

      // Clear input fields
      setVideoUrl("");
      setVideoTitle("");
    }
  };

  const removeVideoGroup = (index) => {
    const updatedVideos = [...videos];
    updatedVideos.splice(index, 1);
    setVideos(updatedVideos);
  };

  useEffect(() => {
    if (isEditing) {
      // Pre-fill the form fields with existing content when in editing mode
      setTitle(existingContent.title);
      setVideos(existingContent.content);
    }
  }, [isEditing, existingContent]);

  const unsavedChanges = isEditing
    ? title !== existingContent.title ||
      JSON.stringify(videos) !== JSON.stringify(existingContent.content)
    : title !== "" || videos.length > 0;

  return (
    <InputModal size="md">
      <InputHeader
        icon={<HeroIcon className="stroke-[#3371ff]" />} // Pass the appropriate icon for awards
        title="Hero"
        isEditing={isEditing}
        existingContent={existingContent}
        setSelectedComponent={setSelectedComponent}
        close={close}
        unsavedChanges={unsavedChanges}
      />
      <form
        onSubmit={handleSubmit}
        className="flex flex-col mt-[20px] gap-[20px]"
      >
        <Input
          setValue={(e) => setTitle(e.target.value)}
          value={title}
          required={true}
          label="Title:"
          id="title"
        />
        <Input
          setValue={(e) => setVideoUrl(e.target.value)}
          value={videoUrl}
          label="Video URL:"
          id="videoUrl"
        />
        <Input
          setValue={(e) => setVideoTitle(e.target.value)}
          value={videoTitle}
          label="Video Title:"
          id="videoTitle"
        />
        <button type="button" onClick={addVideoGroup}>
          Add Video
        </button>

        {videos.map((videoGroup, index) => (
          <div key={index}>
            <p>{videoGroup.videoTitle}</p>
            <p>{videoGroup.videoUrl}</p>
            <button type="button" onClick={() => removeVideoGroup(index)}>
              Remove
            </button>
          </div>
        ))}

        <div className="ml-auto flex gap-[15px]">
          <PrimaryActionButton
            text={isEditing ? "Update Block" : "Create Block"}
            buttonType="submit"
            disabled={!unsavedChanges}
          />
        </div>
      </form>
    </InputModal>
  );
}

export default HeroBlockInputs;
