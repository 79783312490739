import React, { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableItem from "./DraggableItem";
import DNDModal from "./DNDModal";
import { apiRoutes, makeApiRequest } from "constants/apiRoutes";
import { useAuth } from "util";
import { useCompany } from "util";

const DragAndDrop = ({ jobFeedData, companyId, roleId, refetch }) => {
  const [items, setItems] = useState(jobFeedData);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const auth = useAuth();
  const { companyData } = useCompany();

  const reoderItems = async () => {
    try {
      const response = await makeApiRequest(
        "put",
        apiRoutes.reorderPageByUserAndPageID(auth.user.id, roleId),
        auth.user,
        companyData.companyId,
        items
      );

      if (response.status > 205) {
        throw new Error("Failed to update order on the server.");
      }

      console.log("Order updated on the server.");
    } catch (error) {
      console.error("Error updating order on the server:", error);
    }
  };

  const moveItem = async (fromIndex, toIndex) => {
    const reorderedItems = [...items];
    const [movedItem] = reorderedItems.splice(fromIndex, 1);
    reorderedItems.splice(toIndex, 0, movedItem);
    setItems(reorderedItems);
  };

  const handleDeleteItem = async (deletedItemId) => {
    try {
      // Make the DELETE request to delete the item
      const response = await makeApiRequest(
        "delete",
        apiRoutes.deleteBlock(companyId, roleId, deletedItemId),
        auth.user,
        companyData.companyId
      );

      if (response.status === 200) {
        console.log("Component deleted on the server.");
        refetch();
        // Fetch the updated content feed after successful deletion
      }
    } catch (error) {
      console.error("Error deleting component on the server:", error);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex flex-col gap-[20px] pb-[50px]">
        {items?.map((item, index) => {
          const block = item.sharedId
            ? {
                ...companyData.sharedComponents?.find(
                  (sharedComponent) => sharedComponent.id === item.sharedId
                ),
                id: item.id,
                sharedId: item.sharedId,
                order: item.order,
              }
            : item;
          return (
            <div key={block.id}>
              <DraggableItem
                key={block.id}
                id={block.id}
                block={block}
                index={index}
                moveItem={moveItem}
                reoderItems={reoderItems}
                onDeleteItem={handleDeleteItem}
                onEditItem={() => {
                  setSelectedItem(block);
                  setIsEditModalOpen(true);
                }}
              />
            </div>
          );
        })}
      </div>
      {isEditModalOpen && (
        <DNDModal
          onClose={() => setIsEditModalOpen(false)}
          selectedItem={selectedItem}
          refetch={refetch}
        />
      )}
    </DndProvider>
  );
};

export default DragAndDrop;
