import React from "react";
import { ReactComponent as ClockLines } from "assets/icons/clock-lines.svg";
import { ReactComponent as Stopwatch } from "assets/icons/stopwatch.svg";

const ActiveTime = ({ totalActiveTime, averageActiveTime }) => {
  return (
    <div>
      <h2 className="text-lg font-semibold mb-2 text-slate-900">Active Time</h2>
      <div className="flex flex-col gap-[15px]">
        <div className="hs-block bg-white py-4 pl-3 pr-5 text-sm tracking-tight font-medium">
          <div className="pl-[5px] flex items-center gap-[15px]">
            <div className="p-2 bg-blue-100 rounded-md stroke-[#3371ff]">
              <ClockLines />
            </div>
            <div>
              <p>Total Active Time</p>
              <p className="text-slate-400">Combined Total</p>
            </div>
            <div className="ml-auto">
              <p className="text-[#3371ff] text-lg">{totalActiveTime}</p>
            </div>
          </div>
        </div>
        <div className="hs-block bg-white py-4 pl-3 pr-5 text-sm tracking-tight font-medium">
          <div className="pl-[5px] flex items-center gap-[15px]">
            <div className="p-2 bg-blue-100 rounded-md stroke-[#3371ff]">
              <Stopwatch />
            </div>
            <div>
              <p>Average Active Time</p>
              <p className="text-slate-400">Combined Average</p>
            </div>
            <div className="ml-auto">
              <p className="text-[#3371ff] text-lg">{averageActiveTime}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveTime;
