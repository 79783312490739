import React from "react";
import Meta from "../../components/Meta";
import ContactSection from "./Sections/ContactSection";
import useWindowDimensions from "hooks/useWindowDimensions";

function ContactPage(props) {
  const isMobileScreen = useWindowDimensions();

  return (
    <>
      <Meta title="About" description="Learn about our company and team" />

      <div
        className={`${
          isMobileScreen
            ? "flex flex-col mx-[20px] p-[30px] m-[20px]"
            : "grid grid-cols-2 gap-[50px] my-[50px] mx-[75px] p-[75px]"
        } bg-white block-shadow rounded-[30px]`}
      >
        <div className="flex flex-col">
          <h2 className="text-base font-semibold leading-7 text-blue-600">
            Get in touch
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Your recruiter co-pilot
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Reach out if you have any questions about the product, would like to
            see a detailed demo, or want to discuss Teams pricing.
          </p>
          {/* <div className="mt-auto">
            <a href="/auth/signup" className="text-blue-600">
              Sign up here
            </a>
          </div> */}
        </div>
        <ContactSection />
      </div>
    </>
  );
}

export default ContactPage;
