import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../util";
import axios from "axios";
import { Link } from "react-router-dom";
import { isCompanyEmail } from "../../util";

const errorMessages = {
  "Firebase: Error (auth/email-already-in-use).":
    "The email address is already in use by another account. Please use a different email address.",
  "Firebase: Error (auth/invalid-login-credentials).":
    "The email or password is incorrect. Please try again.",
};

function AuthForm(props) {
  const auth = useAuth();
  const [ip, setIP] = useState("");
  const [tacAcknowledged, setTacAcknowledged] = useState(false);
  const [pending, setPending] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    setError, // To manually set errors
    clearErrors, // To clear specific errors
  } = useForm();

  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIP(res.data.ip);
  };

  useEffect(() => {
    // Passing getData method to the lifecycle method
    getData();
  }, []);

  const submitHandlersByType = {
    signin: ({ email, pass }) => {
      return auth.signin(email, pass).then((user) => {
        // Call auth complete handler
        props.onAuth(user);
      });
    },
    signup: ({ email, pass, termsAndConditionsObject }) => {
      return auth.signup(email, pass, termsAndConditionsObject).then((user) => {
        // Call auth complete handler
        props.onAuth(user);
      });
    },
    forgotpass: ({ email }) => {
      return auth.sendPasswordResetEmail(email).then(() => {
        setPending(false);
        // Show success alert message
        props.onFormAlert({
          type: "success",
          message: "Password reset email sent",
        });
      });
    },
    changepass: ({ pass }) => {
      return auth.confirmPasswordReset(pass).then(() => {
        setPending(false);
        // Show success alert message
        props.onFormAlert({
          type: "success",
          message: "Your password has been changed",
        });
      });
    },
  };

  // Handle form submission
  const onSubmit = ({ email, pass }) => {
    // Show pending indicator
    setPending(true);

    const termsAndConditionsObject = tacAcknowledged
      ? {
          termsAndConditionsAcknowledged: new Date(),
          ipAddress: ip, // Implement logic to get the IP address
          browser: navigator.userAgent, // Get user agent from the browser
          // Potentially add version number
        }
      : null;

    if (props.type === "signup") {
      if (!isCompanyEmail(email)) {
        setError("email", {
          type: "manual",
          message: "Please enter a valid company email address.",
        });
        setPending(false);
        return;
      }

      submitHandlersByType[props.type]({
        email,
        pass,
        termsAndConditionsObject, // Pass termsAndConditionsObject only for "signup"
      }).catch((error) => {
        setPending(false);
        const errorCode = error.message;
        const errorMessage =
          errorMessages[errorCode] ||
          "An unexpected error occurred. Please try again.";
        // Show error alert message
        props.onFormAlert({
          type: "error",
          message: errorMessage,
        });
      });
    } else {
      // If submitHandlersByType[props.type] is not "signup", omit termsAndConditionsObject
      submitHandlersByType[props.type]({
        email,
        pass,
      }).catch((error) => {
        setPending(false);
        const errorCode = error.message;
        const errorMessage =
          errorMessages[errorCode] ||
          "An unexpected error occurred. Please try again.";
        // Show error alert message
        props.onFormAlert({
          type: "error",
          message: errorMessage,
        });
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {["signup", "signin", "forgotpass"].includes(props.type) && (
        <div className="mb-2">
          <input
            className="block w-full rounded-xl border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3371FF] sm:text-sm sm:leading-6"
            {...register("email", {
              required: "Please enter an email",
              validate: (value) => {
                if (props.type === "signup" && !isCompanyEmail(value)) {
                  return "Please enter a valid company email address.";
                }
                return true;
              },
            })}
            type="email"
            placeholder="Email"
            onChange={() => clearErrors("email")} // Clear email error on change
          />

          {errors.email && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.email.message}
            </p>
          )}
        </div>
      )}

      {["signup", "signin", "changepass"].includes(props.type) && (
        <div className="mb-2">
          <input
            className="block w-full rounded-xl border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3371FF] sm:text-sm sm:leading-6"
            {...register("pass", {
              required: "Please enter a password",
            })}
            type="password"
            placeholder="Password"
          />

          {errors.pass && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.pass.message}
            </p>
          )}
        </div>
      )}

      {["signup", "changepass"].includes(props.type) && (
        <div className="mb-2">
          <input
            className="block w-full rounded-xl border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3371FF] sm:text-sm sm:leading-6"
            {...register("confirmPass", {
              required: "Please enter your password again",
              validate: (value) => {
                if (value === getValues().pass) {
                  return true;
                } else {
                  return "This doesn't match your password";
                }
              },
            })}
            type="password"
            placeholder="Confirm Password"
          />

          {errors.confirmPass && (
            <p className="mt-1 text-sm text-left text-red-600">
              {errors.confirmPass.message}
            </p>
          )}
        </div>
      )}

      {["signup"].includes(props.type) && (
        <div className="mb-6 mt-7">
          <label className="flex items-start gap-4">
            <input
              type="checkbox"
              {...register("termsAndConditions", { required: true })}
              onChange={() => setTacAcknowledged(!tacAcknowledged)}
            />
            <span className="text-sm text-left mt-[-4px]">
              By checking this box, you are agreeing to our{" "}
              <Link to="/legal/terms-of-use" className="text-[#3371FF]">
                Terms of Use
              </Link>{" "}
              and{" "}
              <Link to="/legal/privacy-policy" className="text-[#3371FF]">
                Privacy Policy
              </Link>
              .
            </span>
          </label>
          {errors.termsAndConditions && (
            <p className="mt-1 text-sm text-left text-red-600">
              Please accept the Terms of Use and Privacy Policy
            </p>
          )}
        </div>
      )}

      <button
        className="w-full rounded-xl bg-[#3371FF] py-1.5 text-sm font-medium leading-6 text-white shadow-sm hover:bg-[#3371FF85] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#3371FF]"
        type="submit"
        disabled={pending || (props.type === "signup" && !tacAcknowledged)}
      >
        {pending ? "..." : props.buttonAction}
      </button>
    </form>
  );
}

export default AuthForm;
