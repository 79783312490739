import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "util";
import { useCompany } from "util";
import PrimaryActionButton from "components/Buttons/PrimaryActionButton";
import { ReactComponent as CircleQuestion } from "assets/icons/circle-question.svg";
import { makeApiRequest, apiRoutes } from "constants";

function UserInformation() {
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const { companyData } = useCompany();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function onError(e) {
    e.target.src =
      "https://hs-icons.s3.us-east-2.amazonaws.com/hs_logo_blue.png";
  }

  const handleSaveImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("image", file);
      const response = await makeApiRequest(
        "post",
        apiRoutes.profilePicture(auth.user.id),
        auth.user,
        companyData.companyId,
        formData
      );
      if (response.status === 201) {
        console.log("Profile picture changed");
      }
    } catch (error) {
      console.error(error, "error");
    }
  };

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);
    data.name = `${data.firstName} ${data.lastName}`;
    data.showOnboardingFlow = false;
    data.isVerified = "pending";

    return auth
      .updateProfile(data)
      .then(() => {
        console.log({
          type: "success",
          message: "Your profile has been updated",
        });
      })
      .catch((error) => {
        console.log({
          type: "error",
          message: error.message,
        });
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  return (
    <div className="flex h-[100vh]">
      <div className="flex items-center m-auto">
        <div className="w-[450px] p-[30px] bg-white block-shadow rounded-[30px]">
          <h2 className="font-semibold text-xl text-slate-700 tracking-tight mb-4">
            Welcome to Hiring Spree 🎉
          </h2>
          <p className="text-sm mb-4">
            We're thrilled to have you onboard! Candidates are going to love
            what you create. Once you've provided some information about
            yourself, we'll review your profile and do a quick verification to
            make sure everything looks right before setting you loose. We'll get
            in touch if we need more information.
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label className="text-[13px] font-medium tracking-tight text-slate-700">
                What's your name?
              </label>
              <div className="flex gap-4">
                <input
                  className="text-sm w-full mt-1 py-2 px-3 bg-slate-100 rounded-md"
                  {...register("firstName", {
                    required: "Please enter your first name",
                  })}
                  type="text"
                  placeholder="Mick"
                />
                {errors.firstName && (
                  <p className="mt-1 text-sm text-left text-red-600">
                    {errors.firstName.message}
                  </p>
                )}
                <input
                  className="text-sm w-full mt-1 py-2 px-3 bg-slate-100 rounded-md"
                  {...register("lastName", {
                    required: "Please enter your last name",
                  })}
                  type="text"
                  placeholder="Stallone"
                />
                {errors.lastName && (
                  <p className="mt-1 text-sm text-left text-red-600">
                    {errors.lastName.message}
                  </p>
                )}
              </div>
            </div>

            <div className="mt-3">
              <label className="text-[13px] font-medium tracking-tight text-slate-700">
                What do you do?
              </label>
              <input
                className="text-sm w-full mt-1 py-2 px-3 bg-slate-100 rounded-md"
                {...register("userTitle", {
                  required: "Please enter your job title",
                })}
                type="text"
                placeholder="i.e. Founder, Sourcer, Recruiter"
                defaultValue={auth.user ? auth.user.userTitle : ""}
              />
              {errors.jobTitle && (
                <p className="mt-1 text-sm text-left text-red-600">
                  {errors.userTitle.message}
                </p>
              )}
            </div>
            <div className="mt-3">
              <label className="text-[13px] font-medium tracking-tight text-slate-700">
                LinkedIn Profile
              </label>
              <input
                className="text-sm w-full mt-1 py-2 px-3 bg-slate-100 rounded-md"
                {...register("userLinkedIn", {
                  required: "LinkedIn Profile",
                })}
                type="url"
                placeholder="https://www.linkedin.com/in/thisismickstallone/"
                defaultValue={auth.user ? auth.user.userLinkedIn : ""}
              />
              {errors.linkedInUrl && (
                <p className="mt-1 text-sm text-left text-red-600">
                  {errors.userLinkedIn.message}
                </p>
              )}
            </div>
            <div className="mt-5 flex items-center gap-[20px]">
              {auth.user?.userImage ? (
                <img
                  className="h-[70px] w-[70px] rounded-full bg-white block-shadow"
                  src={auth.user?.userImage}
                  onError={(e) => onError(e)}
                  alt="logo"
                />
              ) : (
                <div className="h-[70px] w-[70px] rounded-full bg-white block-shadow flex items-center justify-center">
                  <CircleQuestion className="stroke-[#3371ff]" />
                </div>
              )}
              <div className="flex flex-col mr-2">
                <label
                  for="logo-upload"
                  className="text-sm cursor-pointer text-[#3371ff]"
                >
                  Upload Profile Picture
                </label>
                <input
                  id="logo-upload"
                  type="file"
                  className="hidden"
                  onChange={(e) => handleSaveImage(e.target.files[0])}
                  required
                />
              </div>
            </div>
            <div className="flex justify-end mt-[30px]">
              <PrimaryActionButton
                text={`${pending ? "..." : "Save Details"}`}
                type="submit"
                disabled={pending || !auth.user?.userImage}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UserInformation;
