import React from "react";
import Compensation from "pages/LandingPage/Utilities/Formatting/Compensation";
import Location from "pages/LandingPage/Utilities/Formatting/Location";

function EditorSection({ isLoaded, roleDetails }) {
  return (
    <div className="pt-2">
      <ul className="grid grid-cols-4">
        <li className="text-sm">
          <span className="font-semibold">Department: </span>
          <p>{roleDetails.department}</p>
        </li>
        <li className="text-sm">
          <span className="font-semibold">Location: </span>
          <p>
            <Location
              city={roleDetails.city}
              state={roleDetails.state}
              country={roleDetails.country}
              remote={roleDetails.isRemote}
              hybrid={roleDetails.isHybrid}
            />
          </p>
        </li>

        <li className="text-sm">
          <span className="font-semibold">Compensation: </span>
          <p>
            {!isLoaded ? (
              roleDetails?.compType === "hidden" ? (
                "Hidden"
              ) : (
                <Compensation
                  compType={roleDetails?.compType}
                  compLow={roleDetails?.compLow}
                  compHigh={roleDetails?.compHigh}
                />
              )
            ) : null}
          </p>
        </li>
        <li className="text-sm">
          <span className="font-semibold">CTA Url: </span>
          <p>
            <a href={roleDetails.ctaUrl} className="text-[#3371ff]">
              {roleDetails.ctaUrl}
            </a>
          </p>
        </li>
      </ul>
    </div>
  );
}

export default EditorSection;
