import React from "react";

export const createLocationString = (city, state, country, remote, hybrid) => {
  // Check for various combinations and construct the display accordingly
  if (remote) {
    return country ? `Remote, ${country}` : "Remote";
  }

  let locationString = "";
  if (city) {
    locationString += city;
  }

  if (state) {
    locationString += locationString.length ? `, ${state}` : state;
  }

  if (country) {
    locationString += locationString.length ? `, ${country}` : country;
  }

  if (hybrid) {
    return locationString ? `Hybrid, ${locationString}` : "Hybrid";
  }

  return locationString;
};

const Location = ({ city, state, country, remote, hybrid }) => {
  return <>{createLocationString(city, state, country, remote, hybrid)}</>;
};

export default Location;
