import React from "react";

function SecondaryActionButton({ text, action }) {
  return (
    <button
      type="button"
      className="bg-white text-slate-700 font-semibold border border-solid border-slate-300 rounded-[10px] text-sm px-6 py-3 cursor-pointer"
      onClick={action}
    >
      {text}
    </button>
  );
}

export default SecondaryActionButton;
