export const TextArea = ({ label, value, setValue, id, ...props }) => {
  return (
    <div>
      <label className="text-[13px] font-medium tracking-tight text-slate-700">
        {label}
      </label>
      <textarea
        className="text-sm w-full mt-1 py-2 px-3 bg-slate-100 rounded-md"
        value={value}
        onChange={setValue}
        id={id}
        {...props}
      />
    </div>
  );
};
