import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/hs_logo_black.png";

function BuiltUsingHiringSpree() {
  return (
    <div className="flex flex-col items-center gap-[10px] m-auto">
      <img
        src={logo}
        className="w-[35px] h-[35px] bg-white block-shadow rounded-full"
      />
      <p className="text-xs font-medium dark:text-slate-200 text-slate-700">
        Page built using{" "}
        <Link className="text-[#3371ff]" to="https://hiringspree.io">
          Hiring Spree
        </Link>
      </p>
    </div>
  );
}

export default BuiltUsingHiringSpree;
