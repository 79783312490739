// Modules
import React from "react";
import LinkWithAnalyticsNoText from "pages/LandingPage/Components/Links/LinkWithAnalyticsNoText";
import { ReactComponent as Facebook } from "assets/icons/social/facebook_icon.svg";
import { ReactComponent as Twitter } from "assets/icons/social/twitter_icon.svg";
import { ReactComponent as LinkedIn } from "assets/icons/social/linkedin_icon.svg";
import { ReactComponent as Crunchbase } from "assets/icons/social/crunchbase_icon.svg";
import { ReactComponent as GitHub } from "assets/icons/social/github_icon.svg";
import { ReactComponent as Youtube } from "assets/icons/social/youtube_icon.svg";
import { ReactComponent as Discord } from "assets/icons/social/discord_icon.svg";
import { ReactComponent as TikTok } from "assets/icons/social/tiktok_icon.svg";
import { ReactComponent as WhatsApp } from "assets/icons/social/whatsapp_icon.svg";
import { ReactComponent as SnapChat } from "assets/icons/social/snapchat_icon.svg";
import { ReactComponent as Instagram } from "assets/icons/social/instagram_icon.svg";
// import { ReactComponent as Repvue } from "assets/icons/social/repvue_icon.svg";
import { ReactComponent as Glassdoor } from "assets/icons/social/glassdoor_icon.svg";
import { ReactComponent as Medium } from "assets/icons/social/medium_icon.svg";
import { ReactComponent as Pinterest } from "assets/icons/social/pinterest_icon.svg";
import { ReactComponent as Telegram } from "assets/icons/social/telegram_icon.svg";
import { ReactComponent as Reddit } from "assets/icons/social/reddit_icon.svg";
import { ReactComponent as Vimeo } from "assets/icons/social/vimeo_icon.svg";
import { ReactComponent as Dribbble } from "assets/icons/social/dribbble_icon.svg";

const socialLinkData = [
  { platform: "twitter", icon: <Twitter />, name: "Twitter" },
  { platform: "linkedin", icon: <LinkedIn />, name: "LinkedIn" },
  { platform: "dribbble", icon: <Dribbble />, name: "Dribbble" },
  { platform: "crunchbase", icon: <Crunchbase />, name: "Crunchbase" },
  { platform: "github", icon: <GitHub />, name: "GitHub" },
  { platform: "youtube", icon: <Youtube />, name: "YouTube" },
  { platform: "instagram", icon: <Instagram />, name: "Instagram" },
  { platform: "facebook", icon: <Facebook />, name: "Facebook" },
  { platform: "discord", icon: <Discord />, name: "Discord" },
  { platform: "glassdoor", icon: <Glassdoor />, name: "Glassdoor" },
  { platform: "tiktok", icon: <TikTok />, name: "TikTok" },
  { platform: "whatsapp", icon: <WhatsApp />, name: "WhatsApp" },
  { platform: "snapchat", icon: <SnapChat />, name: "SnapChat" },
  { platform: "medium", icon: <Medium />, name: "Medium" },
  { platform: "pinterest", icon: <Pinterest />, name: "Pinterest" },
  { platform: "telegram", icon: <Telegram />, name: "Telegram" },
  { platform: "reddit", icon: <Reddit />, name: "Reddit" },
  { platform: "vimeo", icon: <Vimeo />, name: "Vimeo" },
];

function SocialLink({ platform, url }) {
  const linkInfo = socialLinkData.find((link) => link.platform === platform);

  if (!linkInfo) {
    return null;
  }

  return (
    <LinkWithAnalyticsNoText
      text={linkInfo.name}
      url={url}
      title="User Card - Social Links"
    >
      <div className="h-[20px] w-[20px] fill-[#3370ff80] dark:fill-[#3370ff] hover:fill-[#2566fda0] dark:hover:fill-[#4a80fe]">
        {linkInfo.icon}
      </div>
    </LinkWithAnalyticsNoText>
  );
}

function SocialLinks({ socialLinks }) {
  return (
    <div className="mt-4 grid justify-items-center grid-cols-5 gap-x-3 gap-y-3 max-w-[248px] m-auto">
      {socialLinks?.map((link) => (
        <SocialLink key={link.id} platform={link.platform} url={link.url} />
      ))}
    </div>
  );
}

export default SocialLinks;
