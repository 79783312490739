import { v4 as uuidv4 } from "uuid";
import { useState, useEffect } from "react";

const useSessionId = () => {
  const [sessionId, setSessionId] = useState("");
  const [isNewSession, setIsNewSession] = useState(null);

  useEffect(() => {
    const storedSessionId = sessionStorage.getItem("sessionId");
    if (!storedSessionId) {
      const newSessionId = `hss_${uuidv4()}`;
      sessionStorage.setItem("sessionId", newSessionId);
      setSessionId(newSessionId);
      setIsNewSession(true);
    } else {
      setSessionId(storedSessionId);
      setIsNewSession(false);
    }
  }, []);

  return { sessionId, isNewSession };
};

export default useSessionId;
