import { apiRoutes, makeApiRequest } from "constants/apiRoutes";

export const sendSessionData = async (
  roleId,
  companyId,
  sessionData,
  previewMode,
  visitorId,
  sessionId,
  role_title,
  userId
) => {
  const sessionActivityInit = {
    time_on_page: 0,
    activity: [],
    call_to_action_clicked: false,
  };

  const sessionDataObject = {
    userId,
    sessionId: sessionId,
    page: window.location.pathname,
    companyId,
    roleId,
    role_title,
    session_start: new Date().toISOString(),
    session_end: new Date().toISOString(),
    visitorId: visitorId,
    ...sessionData,
    ...sessionActivityInit,
  };

  if (!previewMode) {
    if (process.env.NODE_ENV === "development") {
      console.log(sessionDataObject);
    } else {
      try {
        let response = await makeApiRequest(
          "post",
          apiRoutes.createSession,
          undefined,
          undefined,
          sessionDataObject
        );
        console.log(response);
      } catch (error) {
        console.error("Error sending page view data:", error);
      }
    }
  } else {
    console.log("Viewing in Preview Mode. Session data is not tracked.");
  }
};
