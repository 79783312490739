import Modal from "../../../../components/Modal";
import SecondaryActionButton from "../../../../components/Buttons/SecondaryActionButton";
import PrimaryActionButton from "../../../../components/Buttons/PrimaryActionButton";
import React, { useState } from "react";
import { isValidUrl } from "util";
import { apiRoutes, makeApiRequest } from "../../../../constants";
import { useAuth } from "util";
import Spinner from "components/Spinner";

export const ImportRoleFromUrlModal = ({ closeModal, companyId }) => {
  const [showInvalidUrlMessage, setShowInvalidUrlMessage] = useState(false);
  const [showInvalidDomainMessage, setShowInvalidDomainMessage] =
    useState(false);
  const [isScrapingUrl, setIsScrapingUrl] = useState(false);
  const auth = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowInvalidDomainMessage(false);
    setShowInvalidUrlMessage(false);

    const { url } = event.target;

    const validateUrl = (url) => {
      const allowedDomains = [
        "boards.greenhouse.io",
        "jobs.lever.co",
        "jobs.ashbyhq.com",
      ];
      const isValid = allowedDomains.some((domain) => url.includes(domain));
      return isValid;
    };

    if (!validateUrl(url.value)) {
      setShowInvalidDomainMessage(true);
      return;
    }

    if (!isValidUrl(url.value)) {
      setShowInvalidUrlMessage(true);
      return;
    }

    try {
      setIsScrapingUrl(true);
      let data = {
        companyId: companyId,
        url: url.value,
      };

      const response = await makeApiRequest(
        "post",
        apiRoutes.scrapePageByUrl(companyId),
        auth.user,
        companyId,
        data
      );

      if (response.status === 201) {
        window.location.href = `${window.location.origin}/pages/${response.data._id}`;
      }
    } catch (error) {
      console.error(error);
      window.alert("There was an error scraping this role");
    } finally {
      setIsScrapingUrl(false);
    }
  };

  return (
    <Modal
      title="Create Page From Job Description"
      closeModal={closeModal}
      size="md"
    >
      <form
        onSubmit={(event) => {
          handleSubmit(event);
        }}
        className="flex flex-col mt-[20px] gap-[20px]"
      >
        <div className="grid grid-cols-1 ">
          <div className="mb-4 text-sm">
            <p>
              Create a page from your active{" "}
              <span className="font-semibold">Ashby</span>,{" "}
              <span className="font-semibold">Lever</span>, or{" "}
              <span className="font-semibold">Greenhouse</span> job postings.
            </p>
          </div>

          <div className="flex flex-col">
            <label className="text-[13px] font-medium tracking-tight text-slate-700">
              Job Url
            </label>
            <input
              className="text-sm w-full mt-1 py-2 px-3 bg-slate-100 rounded-md"
              type="text"
              name="url"
            />
            {showInvalidUrlMessage ? (
              <p className="mt-1 text-xs pl-1 text-red-500">
                Invalid url: use http/https protocol
              </p>
            ) : null}
            {showInvalidDomainMessage && (
              <p className="mt-1 text-xs pl-1 text-red-500">
                Invalid domain: URL must include boards.greenhouse.io,
                jobs.lever.co, or jobs.ashbyhq.com
              </p>
            )}
          </div>

          <div className="flex items-center mt-[15px] ml-auto gap-[10px]">
            {isScrapingUrl ? <Spinner size="25px" /> : null}
            <SecondaryActionButton text="Cancel" action={closeModal} />
            <PrimaryActionButton
              text="Create Page"
              buttonType="submit"
              disabled={isScrapingUrl}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};
