import dayjs from "dayjs";

// Utility function to count desktop and mobile users
export const countDeviceTypes = (sessions) => {
  let desktopCount = 0;
  let mobileCount = 0;

  sessions.forEach((session) => {
    if (session.deviceType === "desktop") {
      desktopCount++;
    } else if (session.deviceType === "mobile") {
      mobileCount++;
    }
  });

  return { desktopCount, mobileCount };
};

// Utility function to count unique sessions
export const countUniqueVisitors = (sessions) => {
  const uniqueVisitors = new Set(sessions.map((session) => session.visitorId));
  return uniqueVisitors.size;
};

// Utility function to calculate total time on page
export const calculateTotalTimeOnPage = (sessions) => {
  return sessions.reduce((total, session) => {
    return total + session.time_on_page;
  }, 0);
};

// Utility function to calculate average time on page
export const calculateAverageTimeOnPage = (sessions) => {
  const totalTime = calculateTotalTimeOnPage(sessions);
  const numberOfSessions = sessions.length;
  return numberOfSessions > 0 ? totalTime / numberOfSessions : 0;
};

export const secondsToHoursMinutesAndSeconds = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.round(seconds % 60);

  const hoursDisplay = hours > 0 ? `${hours}h ` : "";
  const minutesDisplay = minutes > 0 ? `${minutes}m ` : "";
  const secondsDisplay = remainingSeconds > 0 ? `${remainingSeconds}s` : "";

  return `${hoursDisplay}${minutesDisplay}${secondsDisplay}`.trim();
};

// Utility function to count activities and aggregate the number of clicks per link
// TODO - Restructure Object to include Title and Type
// TODO - Sort by Click Count
export const countActivities = (sessions) => {
  const activityCounts = {};

  sessions.forEach((session) => {
    if (session.activity && Array.isArray(session.activity)) {
      session.activity.forEach((activity) => {
        const link = activity.url; // Assumed field; adjust based on your data
        const title = activity.link_title; // Assumed field; adjust based on your data
        if (link && activity.activity === "link_click") {
          // Initialize entry if it doesn't exist
          if (!activityCounts[link]) {
            activityCounts[link] = { url: link, link_title: title, count: 0 };
          }
          // Increment the click count
          activityCounts[link].count += 1;
        }
      });
    }
  });

  // Convert the object to an array of objects and sort by count descending
  return Object.values(activityCounts).sort((a, b) => b.count - a.count);
};

// Utility function to convert session data to CSV format
export const convertToCSV = (sessions) => {
  if (sessions.length === 0) return "";

  // Define the headers based on your session object keys
  const headers = [
    "sessionId",
    "userId",
    "companyId",
    "roleId",
    "role_title",
    "session_start",
    "session_end",
    "visitorId",
    "userAgent",
    "referrer",
    "screenWidth",
    "screenHeight",
    "time_on_page",
    "call_to_action_clicked",
    "browser",
    "deviceType",
    "osName",
    "osVersion",
    "ip",
  ];

  // Create CSV content
  const rows = sessions.map((session) => {
    return headers
      .map((header) => {
        const value = session[header] || "";
        return typeof value === "object" && value !== null
          ? JSON.stringify(value)
          : value;
      })
      .join(",");
  });

  // Add headers to rows
  rows.unshift(headers.join(","));

  // Convert rows to CSV string
  return rows.join("\n");
};

/**
 * Converts a decimal value to a percentage with up to 1 decimal point.
 *
 * @param {number} decimal - The decimal value to convert.
 * @returns {string} - The formatted percentage value.
 */
export const decimalToPercentage = (decimal) => {
  if (isNaN(decimal) || decimal < 0 || decimal > 1) {
    throw new Error("Input must be a decimal between 0 and 1.");
  }

  // Convert decimal to percentage and format to 1 decimal point
  const percentage = (decimal * 100).toFixed(1);

  // Append percentage sign
  return `${percentage}%`;
};

// Utility function to aggregate session data by day
export const aggregateSessionsByDay = (sessions) => {
  const aggregatedData = {};

  sessions.forEach((session) => {
    const date = dayjs(session.session_start).format("YYYY-MM-DD"); // Format date to YYYY-MM-DD
    if (aggregatedData[date]) {
      aggregatedData[date]++;
    } else {
      aggregatedData[date] = 1;
    }
  });

  // Convert aggregated data to arrays for charting
  const labels = Object.keys(aggregatedData).sort();
  const data = labels.map((label) => aggregatedData[label]);

  return { labels, data };
};

// Optional: Aggregate sessions by hour
export const aggregateSessionsByHour = (sessions) => {
  const aggregatedData = {};

  sessions.forEach((session) => {
    const hour = dayjs(session.session_start).format("YYYY-MM-DD HH:00"); // Format date to YYYY-MM-DD HH:00
    if (aggregatedData[hour]) {
      aggregatedData[hour]++;
    } else {
      aggregatedData[hour] = 1;
    }
  });

  // Convert aggregated data to arrays for charting
  const labels = Object.keys(aggregatedData).sort();
  const data = labels.map((label) => aggregatedData[label]);

  return { labels, data };
};

/**
 * Generates an array of dates between two dates (inclusive).
 *
 * @param {string} startDate - The start date in YYYY-MM-DD format.
 * @param {string} endDate - The end date in YYYY-MM-DD format.
 * @returns {string[]} - An array of dates in YYYY-MM-DD format.
 */
const generateDateRange = (startDate, endDate) => {
  const dates = [];
  let currentDate = dayjs(startDate);

  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, "day")) {
    dates.push(currentDate.format("YYYY-MM-DD"));
    currentDate = currentDate.add(1, "day");
  }

  return dates;
};

/**
 * Fills missing dates in the timeseries data with zero values.
 *
 * @param {Object[]} data - The original data array with date and value properties.
 * @param {string} startDate - The start date in YYYY-MM-DD format.
 * @param {string} endDate - The end date in YYYY-MM-DD format.
 * @returns {Object[]} - The data array with missing dates filled with zero values.
 */
export const fillMissingDates = (data, startDate, endDate) => {
  const completeDateRange = generateDateRange(startDate, endDate);
  const dataMap = data.reduce((map, item) => {
    map[item.date] = item.value;
    return map;
  }, {});

  return completeDateRange.map((date) => ({
    date,
    value: dataMap[date] || 0,
  }));
};
