export function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
}

export function formatDateFromTimestamp(timestamp) {
  const date = new Date(timestamp);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString(undefined, options);
}

// formatNumber: A function to format numbers with thousands separators or specific decimal places.
export function formatNumberDecimal(number, decimalPlaces = 2) {
  return number.toFixed(decimalPlaces).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function splitParagraphTextIntoArray(text) {
  return text.split("\n").filter((line) => line.trim() !== "");
}

export function decimalToPercentage(decimalValue, decimalPlaces = 2) {
  if (isNaN(decimalValue) || decimalValue < 0 || decimalValue > 1) {
    throw new Error("Input must be a valid decimal between 0 and 1.");
  }

  const percentageValue = (decimalValue * 100).toFixed(decimalPlaces);
  return `${percentageValue}%`;
}

const CURRENCY_FORMATTER = new Intl.NumberFormat(undefined, {
  currency: "USD",
  style: "currency",
});
export function formatCurrency(number) {
  const formattedNumber = CURRENCY_FORMATTER.format(number);

  // Remove cents for whole numbers (e.g., 100.00)
  if (!Number.isInteger(number)) {
    return formattedNumber.replace(/\.00$/, "");
  }

  return formattedNumber;
}

const NUMBER_FORMATTER = new Intl.NumberFormat(undefined);
export function formatNumber(number) {
  return NUMBER_FORMATTER.format(number);
}

const COMPACT_NUMBER_FORMATTER = new Intl.NumberFormat(undefined, {
  notation: "compact",
});
export function formatCompactNumber(number) {
  return COMPACT_NUMBER_FORMATTER.format(number);
}

const DIVISIONS = [
  { amount: 60, name: "seconds" },
  { amount: 60, name: "minutes" },
  { amount: 24, name: "hours" },
  { amount: 7, name: "days" },
  { amount: 4.34524, name: "weeks" },
  { amount: 12, name: "months" },
  { amount: Number.POSITIVE_INFINITY, name: "years" },
];
const RELATIVE_DATE_FORMATTER = new Intl.RelativeTimeFormat(undefined, {
  numeric: "auto",
});
export function formatRelativeDate(toDate, fromDate = new Date()) {
  let duration = (toDate - fromDate) / 1000;
  for (let i = 0; i <= DIVISIONS.length; i++) {
    const division = DIVISIONS[i];
    if (Math.abs(duration) < division.amount) {
      return RELATIVE_DATE_FORMATTER.format(
        Math.round(duration),
        division.name
      );
    }
    duration /= division.amount;
  }
}

export function assembleUTMQueryString(utmParams) {
  const queryParams = [];

  for (const key in utmParams) {
    if (utmParams.hasOwnProperty(key) && utmParams[key] !== "") {
      queryParams.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(utmParams[key])}`
      );
    }
  }

  if (queryParams.length > 0) {
    return `?${queryParams.join("&")}`;
  } else {
    return "";
  }
}

export function UTMParameters(source, medium, campaign) {
  // Filter out empty values
  const utmParams = { source, medium, campaign };
  const filteredParams = Object.fromEntries(
    Object.entries(utmParams).filter(([key, value]) => value)
  );

  // Construct the UTM string
  const utmString = Object.entries(filteredParams)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  return `?${utmString}` || null;
}

// Check URL has protocol http: or https
export const isValidUrl = (string) => {
  try {
    const url = new URL(string);
    return url.protocol === "http:" || url.protocol === "https:";
  } catch (err) {
    return false;
  }
};

export function secondsToMinutesAndSeconds(seconds) {
  // Ensure that seconds is a valid number
  if (isNaN(seconds) || seconds < 0) {
    return "Invalid input";
  }

  // Calculate minutes and remaining seconds
  var minutes = Math.floor(seconds / 60);
  var remainingSeconds = seconds % 60;

  // Format the result with two decimal places for seconds
  var formattedMinutes = minutes.toFixed(0);
  var formattedSeconds = remainingSeconds.toFixed(0);

  return `${formattedMinutes}m ${formattedSeconds}s`;
}

// Email Validation
const freeEmailDomains = [
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "outlook.com",
  "live.com",
  "aol.com",
  "mail.com",
  "icloud.com",
  "protonmail.com",
  "zoho.com",
  "yandex.com",
  "gmx.com",
  "me.com",
  "sbcglobal.net",
  "comcast.net",
];

export function isCompanyEmail(email) {
  const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

  if (!emailPattern.test(email)) {
    return false;
  }

  const domain = email.split("@")[1];
  return !freeEmailDomains.includes(domain);
}
